<div class="container top-menu">
	<div class="row">

		<div class="col-md-4">
			<div class="search-bx">
				<!-- <form> -->
					<div class="search-box6" *ngIf="data?.categories">
						<select [(ngModel)]="category" (ngModelChange)="onChange($event)">
							<option value="" selected>Select Catagory</option>
							<option *ngFor="let cat of data.categories" [value]='cat.id'>{{cat.category}}</option>
						</select>
						<!-- <button class="ser-btn"  (click)="searchByCategory()"><i class="fa fa-search"></i></button> -->
					</div>
				<!-- </form> -->
			</div>
		</div>
		<div class="col-md-6">
			<div class="search-bx">
				<!-- <form> -->
					<!-- <div class="search-box4">
						<input type="text" class="search-input" [(ngModel)]="search" placeholder="Search Company Name">
						<button class="ser-btn" (click)="searchCompany()"><i class="fa fa-search"></i></button>
					</div> -->

					<div class="ng-autocomplete">
						<ng-autocomplete 
						  [data]="companyList"
						  [searchKeyword]="keyword"
						  (selected)='selectEvent($event)'
						  (inputChanged)='onChangeSearch($event)'
						  (inputFocused)='onFocused($event)'
						  [itemTemplate]="itemTemplate"
						  [notFoundTemplate]="notFoundTemplate"
						  placeholder="Search Company"
						  [(ngModel)]="search">                                 
						</ng-autocomplete>
						<button class="ser-btn" (click)="searchCompany()"><i class="fa fa-search"></i></button>

						<ng-template #itemTemplate let-item>
						<a [innerHTML]="item.name"></a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
						<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				<!-- </form> -->
			</div>
		</div>
	</div>
</div>
<main>
	<!-- Hero area start -->
	<section class="hero-area">
		<div class="row  banner-row">
			<div class="col-xl-6 col-lg-6" *ngFor="let exh of data.exhibitions; let i = index">
				<div id="demo-{{i}}" class="carousel slide" data-ride="carousel">
					<ul class="carousel-indicators">
						<li [attr.data-target]="'#demo-'+i" [attr.data-slide-to]="imgi" [ngClass]="{'active':imgi==0}" *ngFor="let exhimg of exh;let imgi = index"></li>
					</ul>
					<div class="carousel-inner">
						<div class="carousel-item" (click)="onClickExhibition(exhimg1.company_id, exhimg1.type)" [ngClass]="{'active':imgi1==0}" *ngFor="let exhimg1 of exh;let imgi1 = index">
							<img [src]="exhimg1.image" alt="Exhibition Image">
						</div>
					</div>
					<a class="carousel-control-prev" href="#demo-{{i}}" data-slide="prev">
						<span class="carousel-control-prev-icon"></span>
					</a>
					<a class="carousel-control-next" href="#demo-{{i}}" data-slide="next">
						<span class="carousel-control-next-icon"></span>
					</a>
				</div>
			</div>
		</div>
	</section>
	<!-- Hero area end -->
	<section class="design-archi bg-grey" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-3 col-lg-6">
					<div class="single-achi">
						<img src="assets/img/icon1.png">
						<div class="side-txt">
							<h5>Free Signup</h5>
							<p>Let's grow your Business</p>
						</div>
					</div>
				</div>
				<div class="col-xl-3  col-lg-6">
					<div class="single-achi">
						<img src="assets/img/icon2.png">
						<div class="side-txt">
							<h5>Become a Royal Member</h5>
							<p>Get more business & facility</p>
						</div>
					</div>
				</div>
				<div class="col-xl-3  col-lg-6">
					<div class="single-achi">
						<img src="assets/img/icon3.png">
						<div class="side-txt">
							<h5>Get Free Advertising</h5>
							<p>when you signup First time </p>
						</div>
					</div>
				</div>
				<div class="col-xl-3  col-lg-6">
					<div class="single-achi">
						<img src="assets/img/icon4.png">
						<div class="side-txt">
							<h5>We Support</h5>
							<p>24/7 amazing services</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- design area start -->
	<app-directory [list]="dir" [isCompactView]="true"></app-directory>
	<!-- design area end -->
	<!-- design area start -->
	<section class="design-area bg-grey" id="service" *ngIf="data?.newarrival">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Gujarat Tiles Info</h4>
						<h1>NEW ARRIVALS</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="design-item owl-carousel" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-container *ngFor="let slide of data?.newarrival">
								<ng-template carouselSlide [id]="slide.id">
									<div class="single-new" (click)="onClickExhibition(slide.company_id, slide.type)">
										<img [src]="slide.image" class="new-arrival-image" alt="new arrival image">
										<div class="row">
											<div class="col-xl-7 col-md-7 col-sm-7 col-xs-12">
												<h2>{{slide.name}}</h2>
											</div>
											<div class="col-xl-5 col-md-5 col-sm-5 col-xs-12">
												<p>{{slide.size}}</p>
											</div>
										</div>
									</div>
								</ng-template>
							</ng-container>
						</owl-carousel-o>

					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="design-area bg-grey" >


		
	<div class="container">
		<div class="row">
			<div class="col-xl-12 text-center">
				<div class="section-title ">
					<h4>Company Status</h4>
					<h1>RECENT STATUS</h1>
					<img src="assets/img/line.svg" width="120">
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-12">
		<owl-carousel-o [options]="customOptionsMessage" >
			<ng-container *ngFor="let messageList of dataList.data">
			<ng-template carouselSlide  >
				<img [src]="'https://www.gujarattilesinfo.com/backend/' + messageList.statuspic" alt="" (click)="onClickStatusMessage(messageList.id, messageList.type, messageList.name)" (error)="handleMissingImage($event)">

				<div style=" font-weight: bold;">{{messageList.name}}</div>
				<p style="text-transform: capitalize;">{{messageList.statusmessage}}</p>	
					
			
			</ng-template>  </ng-container>
		  </owl-carousel-o>
		  </div></div>
		  </div>
		</section>
	<!-- design area end -->
	<section class="design-area bg-grey seller-list-area" style="background: url(assets/img/bg.jpg);">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 text-center">
					<div class="section-title ">
						<h1>Latest Seller Post</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
					<a *ngFor="let post of sellerPostList" routerLink="/seller-posts-detail" [state]="{id: post.id}">
						<ul class="single-seller-post">
							<li>
								<img [src]="post.logo" class="seller-buyer-logo" alt="selle logo">
							</li>
							<li>
								<div class="author-det">
									<h4>{{post.name}}</h4>
									<h5>{{post.company_role}}</h5>
								</div>
							</li>
							<li>
								<div class="pro-det">
									<p>{{post.product}}</p>
									<p>{{post.grade}}</p>
								</div>
							</li>
							<li>
								<h3>{{post.rate}}</h3>
							</li>
						</ul>
					</a>
					<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<a routerLink="/seller-posts" class="view-btn view-new"> View More</a>
					</div>
				</div>
				<div class="col-xl-6 text-center">
					<div class="section-title buyer-top">
						<h1>Latest BUYER Post</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
					<div class="buyer-post">
						<a *ngFor="let post of buyerPostList" routerLink="/buyer-posts-detail" [state]="{id: post.id}">
							<ul class="single-seller-post">
								<li>
									<img [src]="post.logo" class="seller-buyer-logo" alt="buyer logo">
								</li>
								<li>
									<div class="author-det">
										<h4>{{post.name}}</h4>
										<h5>{{post.company_role}}</h5>
									</div>
								</li>
								<li>
									<div class="pro-det">
										<p>{{post.product}}</p>
										<p>{{post.grade}}</p>
									</div>
								</li>
								<li *ngIf="post.quantity">
									<h4>Quantity: {{post.quantity}}</h4>
								</li>
								<li *ngIf="!post.quantity">
									<h4>{{post.requirement_type}}</h4>
								</li>
							</ul>
						</a>

					</div>
					<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<a routerLink="/buyer-posts" href="javascript(void)" class="view-btn view-new"> View More</a>
					</div>
				</div>
			</div>


			

		</div>
	</section>


	
	<!--
	<section class="design-archi bg-grey" id="sellerformsection">
		<div class="container">
		<div class="row">
			<div class="col-xl-12 text-center">
			
				<app-seller-form  [tiles]="extraseller?.tiles" [sanitary]="extraseller?.sanitary" [various]="extraseller?.various"></app-seller-form>
			</div>
			</div>
			</div>
	</section>
-->

	<section class="bottom-area" style="background: url(assets/img/bg-back.png);">
		<div class="container ">



			<div class="row justify-content-xl-center ">
				<div class="col-xl-6 col-lg-6">
					<div class="bottom-content ">
						<h1>DOWNLOAD APP</h1>
						<p>Gujarat Tiles Info is ceramic industry directory. We provide community for Tiles Manufacturers, Tiles Dealers, Sanitary Manufacturers, Importer, Exporter, Raw Material Suppliers, Transporters, Ceramic industry related manufacturer all information at one place.						</p>
						<a href="https://play.google.com/store/apps/details?id=com.essence.gujarattilesinfo" style="margin-right:2px"><img src="assets/img/google.png" style="border:solid 1px white"></a>
						<a href="https://apps.apple.com/in/app/gujarat-tiles-info/id915485358"><img src="assets/img/app.png" style="border:solid 1px white"></a>
						
						<form style="visibility:hidden;">
							<div class="search-box">
								<input type="text" class="search-input" placeholder="Enter Phone No.">
								<input type="submit" name="" value="Get App">
							</div>
						</form>
					</div>
				</div>
				<div class="col-xl-6 col-lg-6 text-center">
					<img src="assets/img/mobile-app.png" style="border: 0px solid; border-radius: 15px;" class="appss">

				</div>
			</div>
		</div>
	</section>

	<section class="design-archi bg-grey" id="inquirysection">
		<div class="container">
		<div class="row">
			<div class="col-xl-12 text-center">
			<!--
	<button type="button" class="view-btn view-new"  data-toggle="modal" data-target="#myModal">&nbsp;&nbsp;Inquiry&nbsp;&nbsp;</button>
			-->
			</div>
			</div>
			</div>
	</section>

	
</main>