<main>
	<app-breadcrumb [pageName]="name+' Company List'"></app-breadcrumb>
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>{{name+' Company List'}}</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="search-bx buylist-form">
						<form>
							<div class="search-box5">
								<select (change)="onChangeProduct($event.target.value)">
									<option value="">Select Product</option>
									<option *ngFor="let p of product" [value]="p.id">{{p.sub_category}}</option>
								</select>
							</div>
							<div class="search-box3">
								<select (change)="onChangeSize($event.target.value)">
									<option value="">Select Size</option>
									<option *ngFor="let s of size" [value]="s.id">{{s.size}}</option>
								</select>
							</div>
							<div class="search-box3">
								<select (change)="onChangeZone($event.target.value)">
									<option value="">Select Zone</option>
									<option *ngFor="let z of zone" [value]="z.id">{{z.zone}}</option>
								</select>
								<button class="ser-btn" (click)="filterOption()"><i class="fa fa-search"></i></button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let pre of premium">
								<div class="single-buy">
									<div class="row align-center">
										<img src="assets/img/level1.png" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{pre.id}}/1/{{pre.name | slug}}" alt="Company Logo" [state]="{id: pre.id, type:1, cp_id: categoryId}" class="level1">
										<div class="col-xl-2 col-md-2" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{pre.id}}/1/{{pre.name | slug}}" [state]="{id: pre.id, type:1, cp_id: categoryId}">
											<div class="profile-img">
												<img [src]="pre.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{pre.id}}/1/{{pre.name | slug}}" [state]="{id: pre.id, type:1, cp_id: categoryId}">{{pre.name}}</h4>
												<div class="all-btn" *ngIf="is_buttons_enable">
													<a (click)="analytics(pre.id, 1)" href="tel:{{pre.contact}}"><button class="call-btn">Call</button> </a>
													<a (click)="analytics(pre.id, 2)" href="https://api.whatsapp.com/send?phone={{pre.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
													<a (click)="analytics(pre.id, 4)" href="mailto:{{pre.email}}"><button class="chat-btn">mail</button> </a>
													<a (click)="analytics(pre.id, 10)" href="{{pre.website}}" target="_blank"><button class="call-btn">Website</button> </a>
													<a (click)="analytics(pre.id, 3)" href="{{pre.loc_uri}}" target="_blank"><button class="loca-btn">Location</button> </a>
												</div>
												<div class="all-btn" *ngIf="!is_buttons_enable">
													<a><button class="call-btn" [attr.disabled]="true">Call</button> </a>
													<a><button class="whatsapp-btn" [attr.disabled]="true">Whatsapp</button> </a>
													<a><button class="chat-btn" [attr.disabled]="true">mail</button> </a>
													<a><button class="call-btn" [attr.disabled]="true">Website</button> </a>
													<a><button class="loca-btn" [attr.disabled]="true">Location</button> </a>
												</div>
												<h6 routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{pre.id}}/1/{{pre.name | slug}}" [state]="{id: pre.id, type:1, cp_id: categoryId}">{{pre.subcategory}} | {{pre.size}}</h6>
												<div class="address" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{pre.id}}/1/{{pre.name | slug}}" [state]="{id: pre.id, type:1, cp_id: categoryId}">
													<h4>
														<!-- <i class="fa fa-map-marker"></i> -->
														{{pre.zone}}
													</h4>
												</div>
											</div>
										</div>
										<div class="col-xl-2 col-md-3">
											<div class="buy-btn">
												<a>
													<button class="view-btn" (click)="Inquiry(pre.id)" [disabled]="!is_buttons_enable">Inquiry Now &nbsp;
														<i class="fa fa-phone"></i>
													</button>
												</a>
											</div>
										</div>
										<div class="col-xl-2 col-md-2" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{pre.id}}/1/{{pre.name | slug}}" [state]="{id: pre.id, type:1, cp_id: categoryId}">
											<div style="text-align: center;">
												<img [src]="pre.banner" alt="Company Banner">
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let stan of statndard">
								<div class="single-buy">
									<div class="row align-center">
										<img src="assets/img/standard.png" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{stan.id}}/1/{{stan.name | slug}}" class="level1" alt="Company Logo" [state]="{id: stan.id, type:1, cp_id: categoryId}">
										<div class="col-xl-2 col-md-2" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{stan.id}}/1/{{stan.name | slug}}" [state]="{id: stan.id, type:1, cp_id: categoryId}">
											<div class="profile-img">
												<img [src]="stan.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{stan.id}}/1/{{stan.name | slug}}" [state]="{id: stan.id, type:1, cp_id: categoryId}">{{stan.name}}</h4>
												<div class="all-btn" *ngIf="is_buttons_enable">
													<a (click)="analytics(stan.id, 1)" href="tel:{{stan.contact}}"><button class="call-btn">Call</button> </a>
													<a (click)="analytics(stan.id, 2)" href="https://api.whatsapp.com/send?phone={{stan.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
													<a (click)="analytics(stan.id, 4)" href="mailto:{{stan.email}}"><button class="chat-btn">mail</button> </a>
													<a (click)="analytics(stan.id, 10)" href="{{stan.website}}" target="_blank"><button class="call-btn">Website</button> </a>
													<a (click)="analytics(stan.id, 3)" href="{{stan.loc_uri}}" target="_blank"><button class="loca-btn">Location</button> </a>
												</div>
												<div class="all-btn" *ngIf="!is_buttons_enable">
													<a><button class="call-btn" [attr.disabled]="true">Call</button> </a>
													<a><button class="whatsapp-btn" [attr.disabled]="true">Whatsapp</button> </a>
													<a><button class="chat-btn" [attr.disabled]="true">mail</button> </a>
													<a><button class="call-btn" [attr.disabled]="true">Website</button> </a>
													<a><button class="loca-btn" [attr.disabled]="true">Location</button> </a>
												</div>
												<h6 routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{stan.id}}/1/{{stan.name | slug}}" [state]="{id: stan.id, type:1, cp_id: categoryId}">{{stan.subcategory}} | {{stan.size}}</h6>
												<div class="address" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{stan.id}}/1/{{stan.name | slug}}" [state]="{id: stan.id, type:1, cp_id: categoryId}">
													<h4>
														<!-- <i class="fa fa-map-marker"></i> -->
														{{stan.zone}}
													</h4>
												</div>
											</div>
										</div>
										<div class="col-xl-2 col-md-3">
											<div class="buy-btn">
												<a>
													<button class="view-btn" (click)="Inquiry(stan.id)" [disabled]="!is_buttons_enable">Inquiry Now &nbsp;
														<i class="fa fa-phone"></i>
													</button>
												</a>
											</div>
										</div>
										<div class="col-xl-2 col-md-2" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{stan.id}}/1/{{stan.name | slug}}" [state]="{id: stan.id, type:1, cp_id: categoryId}">
											<div style="text-align: center;">
												<img [src]="stan.banner" alt="Company Banner">
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let clas of classic">
								<div class="single-buy">
									<div class="row align-center">
										<img src="assets/img/classic.png" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{clas.id}}/1/{{clas.name | slug}}" class="level1" alt="Company Logo" [state]="{id: clas.id, type:1, cp_id: categoryId}">
										<div class="col-xl-2 col-md-2" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{clas.id}}/1/{{clas.name | slug}}" [state]="{id: clas.id, type:1, cp_id: categoryId}">
											<div class="profile-img">
												<img [src]="clas.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{clas.id}}/1/{{clas.name | slug}}" [state]="{id: clas.id, type:1, cp_id: categoryId}">{{clas.name}}</h4>
												<div class="all-btn" *ngIf="is_buttons_enable">
													<a (click)="analytics(clas.id, 1)" href="tel:{{clas.contact}}"><button class="call-btn">Call</button> </a>
													<a (click)="analytics(clas.id, 2)" href="https://api.whatsapp.com/send?phone={{clas.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
													<a (click)="analytics(clas.id, 4)" href="mailto:{{clas.email}}"><button class="chat-btn">mail</button> </a>
													<a (click)="analytics(clas.id, 10)" href="{{clas.website}}" target="_blank"><button class="call-btn">Website</button> </a>
													<a (click)="analytics(clas.id, 3)" href="{{clas.loc_uri}}" target="_blank"><button class="loca-btn">Location</button> </a>
												</div>
												<div class="all-btn" *ngIf="!is_buttons_enable">
													<a><button class="call-btn" [attr.disabled]="true">Call</button> </a>
													<a><button class="whatsapp-btn" [attr.disabled]="true">Whatsapp</button> </a>
													<a><button class="chat-btn" [attr.disabled]="true">mail</button> </a>
													<a><button class="call-btn" [attr.disabled]="true">Website</button> </a>
													<a><button class="loca-btn" [attr.disabled]="true">Location</button> </a>
												</div>
												<h6 routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{clas.id}}/1/{{clas.name | slug}}" [state]="{id: clas.id, type:1, cp_id: categoryId}">{{clas.subcategory}} | {{clas.size}}</h6>
												<div class="address" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{clas.id}}/1/{{clas.name | slug}}" [state]="{id: clas.id, type:1, cp_id: categoryId}">
													<h4>
														<!-- <i class="fa fa-map-marker"></i> -->
														{{clas.zone}}
													</h4>
												</div>
											</div>
										</div>
										<div class="col-xl-2 col-md-3">
											<div class="buy-btn">
												<a>
													<button class="view-btn" (click)="Inquiry(clas.id)" [disabled]="!is_buttons_enable">Inquiry Now &nbsp;
														<i class="fa fa-phone"></i>
													</button>
												</a>
											</div>
										</div>
										<div class="col-xl-2 col-md-2" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{clas.id}}/1/{{clas.name | slug}}" [state]="{id: clas.id, type:1, cp_id: categoryId}">
											<div style="text-align: center;">
												<img [src]="clas.banner" alt="Company Banner">
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-3 col-lg-3 col-md-6" *ngFor="let gen of general" routerLink="/manufacturers/{{tilescategoryid}}/{{tilescategoryname}}/{{gen.id}}/1/{{gen.name | slug}}" [state]="{id: gen.id, type:1, cp_id: categoryId}">
					<div class="sta-box">
						<div class="profile-img">
							<img [src]="gen.logo" alt="Company Logo">
						</div>
						<h6>{{gen.name}}</h6>
						<p>{{gen.subcategory}} | {{gen.size}}</p>
						<p>{{gen.zone}}</p>
						<div class="buy-btn">
							<a><button (click)="Inquiry(gen.id)" [disabled]="!is_buttons_enable" class="view-btn">Inquiry Now &nbsp;<i class="fa fa-phone"></i></button>
							</a>
						</div>
					</div>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new" style="cursor: pointer;" (click)="moreCompanies()"> View More</a>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>