<main>
    <app-breadcrumb [pageName]="'Identity Find'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Identity Find</h1>
                        <img src="assets/img/line.svg" width="120">
                    </div>
                </div>
            </div>

            <div class="row box-h mt-30">
                <div class="col-md-12 col-h text-center mt-5 mb-5 justify-content-center">
                    <form>
                        <input type="text" name="search" placeholder="Search for phone number" />
                        <input type="submit" name='' value='Search' class="mt-3" />
                    </form>
                </div>
                <div class="col-md-6 col-h mt-4">
                    <div class="details">
                        <h4>Details</h4>
                        <div style="overflow-x:auto;">
                            <table>
                                <tr>
                                    <td style="width: 50%;">Reference Name</td>
                                    <td>Savan Patel</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>9797979797</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>admin@admin.com</td>
                                </tr>
                                <tr>
                                    <td>Firm</td>
                                    <td>Prince Vitrified Pvt. LTD.</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-h mt-4">
                    <div class="details">
                        <h4>Details</h4>
                        <div style="overflow-x:auto;">
                            <table>
                                <tr>
                                    <td style="width: 50%;">Reference Name</td>
                                    <td>Savan Patel</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>9797979797</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>admin@admin.com</td>
                                </tr>
                                <tr>
                                    <td>Firm</td>
                                    <td>Prince Vitrified Pvt. LTD.</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-h mt-4">
                    <div class="details">
                        <h4>Details</h4>
                        <div style="overflow-x:auto;">
                            <table>
                                <tr>
                                    <td style="width: 50%;">Reference Name</td>
                                    <td>Savan Patel</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>9797979797</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>admin@admin.com</td>
                                </tr>
                                <tr>
                                    <td>Firm</td>
                                    <td>Prince Vitrified Pvt. LTD.</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-h mt-4">
                    <div class="details">
                        <h4>Details</h4>
                        <div style="overflow-x:auto;">
                            <table>
                                <tr>
                                    <td style="width: 50%;">Reference Name</td>
                                    <td>Savan Patel</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>9797979797</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>admin@admin.com</td>
                                </tr>
                                <tr>
                                    <td>Firm</td>
                                    <td>Prince Vitrified Pvt. LTD.</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->

</main>