import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { HomeService } from '../../../services/home.service';
import { UserService } from '../../../services/user.service';
import { SellerPostService } from '../../../services/seller-post.service';

declare let $: any;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	
	extraseller:any = [];
	data:any = [];
	dir:any = []; 
	dirmore:any = []; 
	sellerPostList: Array<any> = [];
	buyerPostList: Array<any> = [];
	category:any = '';
	search:any = '';
	keyword:string ='name';
	companyList: Array<any> = [];
	
	dataList: any = [];

	customOptionsMessage: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		navSpeed: 600,
		navText: ['&#8249', '&#8250;'],
		responsive: {
		  0: {
			items: 1 
		  },
		  400: {
			items: 2
		  },
		  760: {
			items: 3
		  },
		  1000: {
			items: 3
		  }
		},
		nav: true
	  }

	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		autoplay: true,
		autoplayTimeout: 2000,
		dots: false,
		navSpeed: 700,
		nav: true,
		navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 2
			},
			740: {
				items: 3
			},
			940: {
				items: 4
			}
		}
	}

	constructor(
		private homeService : HomeService,
		private userService : UserService, 
		private router : Router,
		private sellerPostService: SellerPostService
	) { }

	ngOnInit(): void {

			//this.homeService.getCompanyList().subscribe(response => {
				this.homeService.getCompanyListAll(-2).subscribe(response => {
					if(response.success){
						this.companyList = response.data;				
					}else{
						this.userService.errorMessage(response.message);
					}
				})

	
		this.homeService.getHome().subscribe(response => {
			if(response.success){
				this.data = response.data;
				this.sellerPostList = response.data.sellerposts;
				this.buyerPostList = response.data.buyerposts;
				this.dir = response.data.directories;
				//listdirectorymore
				//this.dirmore=response.data.listdirectorymore;
			}else{
				this.userService.errorMessage(response.message);
			}
		});
		
			
		//this.homeService.getCompanyList().subscribe(response => {
			this.homeService.getCompanyListAll(-2).subscribe(response => {
			if(response.success){
				this.companyList = response.data;				
			}else{
				this.userService.errorMessage(response.message);
			}
		})

		
		this.getStatusList()

/*

		let params = {};
		if(history.state.filter && history.state.product_type){
			params = { ...params, ...{filter : history.state.filter}};
			params = { ...params, ...{product_type : history.state.product_type}};
		}

		this.sellerPostService.getPostList(params).subscribe(resposnse => {
			if(resposnse.success){
				this.extraseller = resposnse.extra;
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		});

*/

	}
	onClickExhibition(cId, type):void{
		this.router.navigate(['company/details'],{state: {id: cId, type: type}})
	}


	onClickStatusMessage(id, type, name):void {
		this.router.navigate(['/company/' + id + '/' + type + '/' + name.replace(/\s+/g, '-').replace('&', '-').toLowerCase()])
	}
	
	/*
	searchByCategory():void{
		if(this.category){
			if(this.category == 0){
				this.router.navigate(['company/sanitary']);
			}else if(this.category == -1){
				this.router.navigate(['company/various']);
			}else{
				const category = this.data?.categories.find((c) => {
					return c.id == this.category;
				});
				this.router.navigate(['company/tiles'],{state: {id: this.category, name: category?.category}});
			}
		}
	}
	*/

	searchCompany():void{
		console.log("Search",this.search);
		if(this.search.name.trim()){
			this.router.navigate(['/search'],{state: {search: this.search.name}})
		}
	}

	 selectEvent(item) {
    // do something with selected item
  }


  onChange(val) {
	let subid=-2;
	//-2 mean blank
  if(this.category=="") {
	  subid-2;
  } else {
	  subid=this.category;
  }
	this.homeService.getCompanyListAll(subid).subscribe(response => {
		if(response.success){
			this.companyList = response.data;				
		}else{
			this.userService.errorMessage(response.message);
		}
	})
}

  onChangeSearch(val: string) {

	

    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
    // do something when input is focused
  }

  getStatusList() {
		
	this.homeService.statusList(10).subscribe(res => {
		if(res.success){
			this.dataList = res
		}else{
			
		}
	});
}

  public handleMissingImage(event: Event) {
	//(event.target as HTMLImageElement).style.display = 'none';
	(event.target as HTMLImageElement).src="assets/img/placeholdermsg.png"

  }

}
