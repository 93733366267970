<div class="details content-details">
    <h2>product and size </h2>
    <div style="overflow-x:auto;">
        <table>
            <tr *ngFor="let c of category">
                <td><p>{{c.category}}</p> <p class="tiles-product-size" *ngFor="let sc of c.subcategory">{{sc}}</p></td>
                <td><p *ngFor="let s of c.size">{{s}}</p></td>
            </tr>
        </table>
        <h6>Product Description</h6>
        <p>{{description}}</p>
    </div>
</div>