<div class="container">
	<div class="row mb-3" *ngIf="!notfound">
		<div class="col-sm-12">
			<div class="box_bb">
				<div class="img_bb text-center">
					<img [src]="blog.image" />
				</div>
				<div class="other_in_b">
					<h4 class="mt-3">{{blog.title}}</h4>
					<div class="excepp">
						<p [innerHTML]="blog.content" class="mb-0"></p>
					</div>
					
					<div class="excepp">{{blog.meta_keyword}}</div>
					
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-3 mt-5" *ngIf="notfound">
		<div class="col-sm-12 text-center">
			<h3 class="text-center">Blog not found</h3>
		</div>
	</div>
</div>