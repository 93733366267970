<main>
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row" *ngIf="!premium.length && !statndard.length && !classic.length && !general.length">
				<div class="col-xl-12 text-center">
					<h2>No Company Found</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let pre of premium">
								<div class="single-buy">
									<div class="row align-center">
										<img src="assets/img/level1.png" routerLink="/company/details" alt="Company Logo" [state]="{id: pre.id, type:pre.type}" class="level1">
										<div class="col-xl-2 col-md-2" routerLink="/company/details" [state]="{id: pre.id, type:pre.type}">
											<div class="profile-img">
												<img [src]="pre.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/company/details" [state]="{id: pre.id, type:pre.type}">{{pre.name}}</h4>
												<div class="all-btn" *ngIf="getAccess(pre.type, pre.is_indian)">
													<a href="tel:{{pre.contact}}"><button class="call-btn">Call</button> </a>
													<a href="https://api.whatsapp.com/send?phone={{pre.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
													<a href="mailto:{{pre.email}}"><button class="chat-btn">mail</button> </a>
													<a href="{{pre.website}}" target="_blank"><button class="call-btn">Website</button> </a>
													<a href="{{pre.loc_uri}}" target="_blank"><button class="loca-btn">Location</button> </a>
												</div>
												<div class="all-btn" *ngIf="!getAccess(pre.type, pre.is_indian)">
													<a><button class="call-btn" [attr.disabled]="true">Call</button> </a>
													<a><button class="whatsapp-btn" [attr.disabled]="true">Whatsapp</button> </a>
													<a><button class="chat-btn" [attr.disabled]="true">mail</button> </a>
													<a><button class="call-btn" [attr.disabled]="true">Website</button> </a>
													<a><button class="loca-btn" [attr.disabled]="true">Location</button> </a>
												</div>
												<div class="address" routerLink="/company/details" [state]="{id: pre.id, type:pre.type}">
													<h4>
														<i class="fa fa-map-marker"></i>
														{{pre.address}}
													</h4>
												</div>
											</div>
										</div>
										<div class="col-xl-2 col-md-3">
											<div class="buy-btn">
												<a>
													<button class="view-btn" (click)="Inquiry(pre.id, pre.type, pre.is_indian)" [disabled]="!getAccess(pre.type, pre.is_indian)">Inquiry Now &nbsp;
														<i class="fa fa-phone"></i>
													</button>
												</a>
											</div>
										</div>
										<div class="col-xl-2 col-md-2" routerLink="/company/details" [state]="{id: pre.id, type:pre.type}">
											<div style="text-align: center;">
												<img [src]="pre.banner" alt="Company Banner">
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let stan of statndard">
								<div class="single-buy">
									<div class="row align-center">
										<img src="assets/img/standard.png" routerLink="/company/details" class="level1" alt="Company Logo" [state]="{id: stan.id, type:stan.type}">
										<div class="col-xl-2 col-md-2" routerLink="/company/details" [state]="{id: stan.id, type:stan.type}">
											<div class="profile-img">
												<img [src]="stan.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/company/details" [state]="{id: stan.id, type:stan.type}">{{stan.name}}</h4>
												<div class="all-btn" *ngIf="getAccess(stan.type, stan.is_indian)">
													<a href="tel:{{stan.contact}}"><button class="call-btn">Call</button> </a>
													<a href="https://api.whatsapp.com/send?phone={{stan.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
													<a href="mailto:{{stan.email}}"><button class="chat-btn">mail</button> </a>
													<a href="{{stan.website}}" target="_blank"><button class="call-btn">Website</button> </a>
													<a href="{{stan.loc_uri}}" target="_blank"><button class="loca-btn">Location</button> </a>
												</div>
												<div class="all-btn" *ngIf="!getAccess(stan.type, stan.is_indian)">
													<a><button class="call-btn" [attr.disabled]="true">Call</button> </a>
													<a><button class="whatsapp-btn" [attr.disabled]="true">Whatsapp</button> </a>
													<a><button class="chat-btn" [attr.disabled]="true">mail</button> </a>
													<a><button class="call-btn" [attr.disabled]="true">Website</button> </a>
													<a><button class="loca-btn" [attr.disabled]="true">Location</button> </a>
												</div>
												<div class="address" routerLink="/company/details" [state]="{id: stan.id, type:stan.type}">
													<h4>
														<i class="fa fa-map-marker"></i>
														{{stan.address}}
													</h4>
												</div>
											</div>
										</div>
										<div class="col-xl-2 col-md-3">
											<div class="buy-btn">
												<a>
													<button class="view-btn" (click)="Inquiry(stan.id, stan.type, stan.is_indian)" [disabled]="!getAccess(stan.type, stan.is_indian)">Inquiry Now &nbsp;
														<i class="fa fa-phone"></i>
													</button>
												</a>
											</div>
										</div>
										<div class="col-xl-2 col-md-2" routerLink="/company/details" [state]="{id: stan.id, type: stan.type}">
											<div style="text-align: center;">
												<img [src]="stan.banner" alt="Company Banner">
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let clas of classic">
								<div class="single-buy">
									<div class="row align-center">
										<img src="assets/img/classic.png" routerLink="/company/details" class="level1" alt="Company Logo" [state]="{id: clas.id, type: clas.type}">
										<div class="col-xl-2 col-md-2" routerLink="/company/details" [state]="{id: clas.id, type: clas.type}">
											<div class="profile-img">
												<img [src]="clas.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/company/details" [state]="{id: clas.id, type: clas.type}">{{clas.name}}</h4>
												<div class="all-btn" *ngIf="getAccess(clas.type, clas.is_indian)">
													<a href="tel:{{clas.contact}}"><button class="call-btn">Call</button> </a>
													<a href="https://api.whatsapp.com/send?phone={{clas.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
													<a href="mailto:{{clas.email}}"><button class="chat-btn">mail</button> </a>
													<a href="{{clas.website}}" target="_blank"><button class="call-btn">Website</button> </a>
													<a href="{{clas.loc_uri}}" target="_blank"><button class="loca-btn">Location</button> </a>
												</div>
												<div class="all-btn" *ngIf="!getAccess(clas.type, clas.is_indian)">
													<a><button class="call-btn" [attr.disabled]="true">Call</button> </a>
													<a><button class="whatsapp-btn" [attr.disabled]="true">Whatsapp</button> </a>
													<a><button class="chat-btn" [attr.disabled]="true">mail</button> </a>
													<a><button class="call-btn" [attr.disabled]="true">Website</button> </a>
													<a><button class="loca-btn" [attr.disabled]="true">Location</button> </a>
												</div>
												<div class="address" routerLink="/company/details" [state]="{id: clas.id, type: clas.type}">
													<h4>
														<i class="fa fa-map-marker"></i>
														{{clas.address}}
													</h4>
												</div>
											</div>
										</div>
										<div class="col-xl-2 col-md-3">
											<div class="buy-btn">
												<a>
													<button class="view-btn" (click)="Inquiry(clas.id, clas.type, clas.is_indian)" [disabled]="!getAccess(clas.type, clas.is_indian)">Inquiry Now &nbsp;
														<i class="fa fa-phone"></i>
													</button>
												</a>
											</div>
										</div>
										<div class="col-xl-2 col-md-2" routerLink="/company/details" [state]="{id: clas.id, type: clas.type}">
											<div style="text-align: center;">
												<img [src]="clas.banner" alt="Company Banner">
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-3 col-lg-3 col-md-6" *ngFor="let gen of general">
					<div class="sta-box">
						<div class="profile-img" routerLink="/company/details" [state]="{id: gen.id, type: gen.type}">
							<img [src]="gen.logo" alt="Company Logo">
						</div>
						<h6 routerLink="/company/details" [state]="{id: gen.id, type: gen.type}">{{gen.name}}</h6>
						<p><i class="fa fa-map-marker gen-address" routerLink="/company/details" [state]="{id: gen.id, type: gen.type}"></i> {{gen.address}}</p>
						<div class="buy-btn">
							<a><button (click)="Inquiry(gen.id, gen.type, gen.is_indian)" [disabled]="!getAccess(gen.type, gen.is_indian)" class="view-btn">Inquiry Now &nbsp;<i class="fa fa-phone"></i></button>
							</a>
						</div>
					</div>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new" style="cursor: pointer;" (click)="moreCompanies()"> View More</a>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>