import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { UserService } from '../../../services/user.service';
import { ChatService } from '../../../services/chat.service';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
	nextPage:number = 0;
	totalPages:number = 0;

	notifications: any = [];
	constructor(private notificationService: NotificationService, private userService: UserService, private chatService: ChatService) { }

	ngOnInit() {
		this.userService.setSidebarCountListener({type:'notification', count:0, curruntUrl:"/notifications"});
		this.notificationService.get({}).subscribe(res => {
			if(res.success){
				this.nextPage = res.extra.next_page;
				this.totalPages = res.extra.total_pages;
				this.notifications = res.data;
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}

	markAllAsRead() {

	}
	more():void{
		let param = {next_page: this.nextPage};
		this.notificationService.get(param).subscribe(resposnse => {
			if(resposnse.success){
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				resposnse.data.forEach(element => {
					this.notifications.push(element)
				});
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		})
	}

}
