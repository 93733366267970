<main>
    <app-breadcrumb [pageName]="'Specificaton'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Specificaton</h1>
                        <img src="assets/img/line.svg" width="120">
                    </div>
                </div>
            </div>

            <div class="row box-h mt-30">
                <div class="details spects-table">
                    <div id="wallTiles">
                        <h2 class="techSpecTitle"><span>WALL TILES</span></h2>
                        <table class="tsTable">
                            <tbody>
                                <tr>
                                    <th>CHARACTERISTICS</th>
                                    <th>EUROSTANDARDS <br> EN-159/IS13753</th>
                                    <th>GUJARAT TILES VALUE</th>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="3">DIMENSION &amp; SURFACE QUALITY</td>
                                </tr>
                                <tr>
                                    <td>1. Dimension &amp; Surface Quality</td>
                                    <td>0.50%</td>
                                    <td>0.40%</td>
                                </tr>
                                <tr>
                                    <td>2. Deviation in Thickness</td>
                                    <td>0.6mm</td>
                                    <td>0.5mm</td>
                                </tr>
                                <tr>
                                    <td>3. Straightness of sides</td>
                                    <td>0.30%</td>
                                    <td>0.20%</td>
                                </tr>
                                <tr>
                                    <td>4. Rectangularity</td>
                                    <td>0.50%</td>
                                    <td>0.30%</td>
                                </tr>
                                <tr>
                                    <td>5. Surface Flatness</td>
                                    <td>0.50%</td>
                                    <td>0.50%</td>
                                </tr>
                                <tr>
                                    <td>5. Surface Quality</td>
                                    <td>Min.95%</td>
                                    <td>Min.96%</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="3">PHYSICAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>1. Water Absorption</td>
                                    <td>&gt; 10%</td>
                                    <td>12-15%</td>
                                </tr>
                                <tr>
                                    <td>2. Bending Strength</td>
                                    <td>&gt; 150 kg/cm²</td>
                                    <td>150-180 kg/cm²</td>
                                </tr>
                                <tr>
                                    <td>3. Scratch Hardness(mohs scale)</td>
                                    <td>Min 3</td>
                                    <td>Min 3</td>
                                </tr>
                                <tr>
                                    <td>4. Crazing Resistance</td>
                                    <td>1 cycle</td>
                                    <td>No Crazing Upto 4 cycle</td>
                                </tr>
                                <tr>
                                    <td>5. Relevent Group</td>
                                    <td>B III</td>
                                    <td>B III</td>
                                </tr>
                                <tr>
                                    <td>6. Type of Tiles</td>
                                    <td>nill</td>
                                    <td>Dust press Wall Tile</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="3">CHEMICAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>1. Staining Resistance</td>
                                    <td>Min class 2</td>
                                    <td rowspan="2">Resistant To all Acids and Allkalies &amp; Household Chemical,
                                        except Hydro Flouric Acid</td>
                                </tr>
                                <tr>
                                    <td>2. Household Chemical</td>
                                    <td>Min class B</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="3">THETMAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>1. Staining Resistance</td>
                                    <td>Resistant 10 cycle</td>
                                    <td>CONFORMS</td>
                                </tr>
                                <tr>
                                    <td>2. Thermal Expansion</td>
                                    <td>Max 9E-06</td>
                                    <td>CONFORMS</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-5">
                <div class="details spects-table">
                    <div id="soluSalt">
                        <h2 class="techSpecTitle"><span>SOLUBLE SALT TILES</span> - 60X60cm</h2>
                        <table class="tsTable">
                            <tbody>
                                <tr>
                                    <th rowspan="2">TECHNICAL PARAMETER</th>
                                    <th rowspan="2">INTERNATIONAL STD.ISO 13006/EN 176 GROUP BLA</th>
                                    <th colspan="3">METHOD OF TESTING</th>
                                    <th rowspan="2">GUJARAT TILES VALUE</th>
                                </tr>
                                <tr class="subHead">
                                    <th>ASTM</th>
                                    <th>ISO</th>
                                    <th>CEN</th>
                                </tr>
                                <tr>
                                    <td>Deviation in length</td>
                                    <td>± 0.6%</td>
                                    <td>C 499</td>
                                    <td>10545-2</td>
                                    <td>EN 98</td>
                                    <td>±0.3%</td>
                                </tr>
                                <tr>
                                    <td>Deviation in thickness</td>
                                    <td>± 5%</td>
                                    <td>C 499</td>
                                    <td>10545-2</td>
                                    <td>EN 98</td>
                                    <td>±3.0%</td>
                                </tr>
                                <tr>
                                    <td>Straightness of sides</td>
                                    <td>±0.5%</td>
                                    <td>-</td>
                                    <td>10545-2</td>
                                    <td>EN 98</td>
                                    <td>± 0.3%</td>
                                </tr>
                                <tr>
                                    <td>Rectangularity</td>
                                    <td>± 0.6%</td>
                                    <td>-</td>
                                    <td>10545-2</td>
                                    <td>EN 98</td>
                                    <td>±0.4%</td>
                                </tr>
                                <tr>
                                    <td>Surface Flatness</td>
                                    <td>± 0.5%</td>
                                    <td>C 485</td>
                                    <td>10545-2</td>
                                    <td>EN 98</td>
                                    <td>±0.4%</td>
                                </tr>
                                <tr>
                                    <td>Breking Stength</td>
                                    <td>1113 N</td>
                                    <td>C 648</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>&gt; 2600 N</td>
                                </tr>
                                <tr>
                                    <td>Water Absorption</td>
                                    <td>≤ 0.5%</td>
                                    <td>C 373</td>
                                    <td>10545-3</td>
                                    <td>EN 99</td>
                                    <td>≤ 0.05%</td>
                                </tr>
                                <tr>
                                    <td>Density(g/cc)</td>
                                    <td>&gt; 2</td>
                                    <td>-</td>
                                    <td>DIN51082</td>
                                    <td>-</td>
                                    <td>2.3</td>
                                </tr>
                                <tr>
                                    <td>Scratch Hardness(moh's)</td>
                                    <td>≥ 6</td>
                                    <td>C 849</td>
                                    <td>-</td>
                                    <td>EN 101</td>
                                    <td>&gt; 7</td>
                                </tr>
                                <tr>
                                    <td>Abrasion Resistance</td>
                                    <td>&lt; 204 N/mm³</td>
                                    <td>-</td>
                                    <td>10545-6</td>
                                    <td>EN 102</td>
                                    <td>&lt; 190 N/mm³</td>
                                </tr>
                                <tr>
                                    <td>Chemical Resistance(EXCEPT HF)</td>
                                    <td>No dmage</td>
                                    <td>C 650</td>
                                    <td>10545-13</td>
                                    <td>EN 106</td>
                                    <td>No damage</td>
                                </tr>
                                <tr>
                                    <td>colour Resistance to light</td>
                                    <td>No dmage</td>
                                    <td>DIN 51094</td>
                                    <td>10545-16</td>
                                    <td>-</td>
                                    <td>No damage</td>
                                </tr>
                                <tr>
                                    <td>Thermal shock Resistance</td>
                                    <td>No damge</td>
                                    <td>C 484</td>
                                    <td>10545-9</td>
                                    <td>EN 104</td>
                                    <td>No damage</td>
                                </tr>
                                <tr>
                                    <td>Skid Resistance</td>
                                    <td>&gt; 0.4</td>
                                    <td>C 1028</td>
                                    <td>DIN51130</td>
                                    <td>-</td>
                                    <td>&gt; 0.5</td>
                                </tr>
                                <tr>
                                    <td>Frost Resistance</td>
                                    <td>Frost proof </td>
                                    <td> </td>
                                    <td>10545-12</td>
                                    <td>-</td>
                                    <td>Frost proof</td>
                                </tr>
                                <tr>
                                    <td>Modulus of Rupture(N/mm<span class="font5">²)</span></td>
                                    <td>≥ 27</td>
                                    <td>C 648</td>
                                    <td>10545-4</td>
                                    <td>EN 100</td>
                                    <td>≥ 45</td>
                                </tr>
                                <tr>
                                    <td>Decorative Faults</td>
                                    <td>defect free</td>
                                    <td>-</td>
                                    <td>10545-2</td>
                                    <td>-</td>
                                    <td>Defect Free</td>
                                </tr>
                                <tr>
                                    <td>surface quality</td>
                                    <td>95% defect free</td>
                                    <td>-</td>
                                    <td>10545-2</td>
                                    <td>-</td>
                                    <td>&gt; 95%</td>
                                </tr>
                                <tr>
                                    <td>Impect Resistance</td>
                                    <td>Good</td>
                                    <td>-</td>
                                    <td> </td>
                                    <td>-</td>
                                    <td>Good</td>
                                </tr>
                                <tr>
                                    <td>Crazing Resistance</td>
                                    <td>No crazing</td>
                                    <td>-</td>
                                    <td>10545-11</td>
                                    <td>-</td>
                                    <td>No crazing</td>
                                </tr>
                                <tr>
                                    <td>Glossiness(reflection)</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>70-85</td>
                                </tr>
                                <tr>
                                    <td>Resistance to house hold chemical</td>
                                    <td>No visible effect</td>
                                    <td>-</td>
                                    <td>10545-13</td>
                                    <td>-</td>
                                    <td>No visible effect</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-5">
                <div class="details spects-table">
                    <div id="gvt">
                        <h2 class="techSpecTitle"><span>GLAZEDPORCELAIN TILES</span> - MATT (GVT) -
                            60X60cm,80X80cm,60X120cm,100X100cm</h2>
                        <table class="tsTable">
                            <tbody>
                                <tr>
                                    <th>CHARACTERISTIC</th>
                                    <th>STANDARD AS PER ISO-13006/EN14411 GROUP Bla</th>
                                    <th>GUJARAT TILES VALUE</th>
                                    <th>TEST METHOD</th>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">REGULATORY PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Deviation in length &amp; width</td>
                                    <td>± 0.5%</td>
                                    <td>±0.3%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Deviation in thickness</td>
                                    <td>± 5.0%</td>
                                    <td>± 4.0%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Straightness in side</td>
                                    <td>± 0.5%</td>
                                    <td>± 0.1%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Rectangularity</td>
                                    <td>± 0.5%</td>
                                    <td>± 0.1%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Surface flatness</td>
                                    <td>± 0.5%</td>
                                    <td>± 0.2%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Color difference</td>
                                    <td>Unlterned</td>
                                    <td>No change</td>
                                    <td>ISO-10545-16</td>
                                </tr>
                                <tr>
                                    <td>Glossiness</td>
                                    <td>As per mfg.</td>
                                    <td>Min 90%</td>
                                    <td>GLOSSOMETER</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">STRUCTURAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Water absorption</td>
                                    <td>&lt; 0.50%</td>
                                    <td>&lt; 0.05%</td>
                                    <td>ISO-10545-3</td>
                                </tr>
                                <tr>
                                    <td>Apparent density</td>
                                    <td>&gt; 2.0 g/cc</td>
                                    <td>&gt; 2.10 g/cc</td>
                                    <td>DIN 51082</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">MASSIVE MECHANICAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Modulus of repture</td>
                                    <td>Min. 35 N/mm2</td>
                                    <td>Min. 40 N/mm2</td>
                                    <td>ISO-10545-4</td>
                                </tr>
                                <tr>
                                    <td>Breaking strength</td>
                                    <td>Min. 1300 N</td>
                                    <td>Min. 2000 N</td>
                                    <td>ISO-10545-4</td>
                                </tr>
                                <tr>
                                    <td>Impact resistance</td>
                                    <td>As per mfg.</td>
                                    <td>Min. 0.55</td>
                                    <td>ISO-10545-5</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">SURFACE MECHANICAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Surface abrasion resistance</td>
                                    <td>As per mfg.</td>
                                    <td>Min. Class-3</td>
                                    <td>ISO-10545-7</td>
                                </tr>
                                <tr>
                                    <td>MOH's hardness</td>
                                    <td>As per mfg.</td>
                                    <td>Min. 6</td>
                                    <td>EN 101</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">THERMO HYGROMRTRIC PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Frost resistance</td>
                                    <td>No damage</td>
                                    <td>No damage</td>
                                    <td>ISO-10545-12</td>
                                </tr>
                                <tr>
                                    <td>Thermal shock resistance</td>
                                    <td>No damage</td>
                                    <td>No damage</td>
                                    <td>ISO-10545-9</td>
                                </tr>
                                <tr>
                                    <td>Moisture expansion</td>
                                    <td>Nill</td>
                                    <td>Nill</td>
                                    <td>ISO-10545-10</td>
                                </tr>
                                <tr>
                                    <td>Thermal expansion(COE)</td>
                                    <td>Max. 9.0 x 10⁻⁶</td>
                                    <td>Max. 6.5 x 10⁻⁶</td>
                                    <td>ISO-10545-8</td>
                                </tr>
                                <tr>
                                    <td>Crazing resistance</td>
                                    <td>As per mfg.</td>
                                    <td>Min. 10 Cycle</td>
                                    <td>ISO-10545-11</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">CHEMICAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Chemical resistance</td>
                                    <td>No damage</td>
                                    <td>No damage</td>
                                    <td>ISO-10545-13</td>
                                </tr>
                                <tr>
                                    <td>Stain resistance</td>
                                    <td>Resistant</td>
                                    <td>Resistant</td>
                                    <td>ISO-10545-14</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">SEFETY PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Slip resistance</td>
                                    <td>As per mfg.</td>
                                    <td>&gt; 0.40</td>
                                    <td>ISO-10545-17</td>
                                </tr>
                                <tr>
                                    <td>Fire resistance</td>
                                    <td>As per mfg.</td>
                                    <td>Fireproof</td>
                                    <td>N.A.</td>
                                </tr>
                                <tr>
                                    <td>Lead &amp; Cafmium given off by glazes tiles</td>
                                    <td>As per mfg.</td>
                                    <td>Does not yield Pb &amp; Cd</td>
                                    <td>ISO-10545-15</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-5">
                <div class="details spects-table">
                    <div id="pgvt">
                        <h2 class="techSpecTitle">GLAZED PORCELAIN TILES - POLISHED (PGVT) - 60X60CM, 60X120CM, 80X80CM,
                            100X100CM</h2>
                        <table class="tsTable">
                            <tbody>
                                <tr>
                                    <th>CHARACTERISTIC</th>
                                    <th>STANDARD AS PER ISO-13006/EN14411 GROUP Bla</th>
                                    <th>GUJARAT TILES VALUE</th>
                                    <th>TEST METHOD</th>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">REGULATORY PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Deviation in length &amp; width</td>
                                    <td>± 0.5%</td>
                                    <td>±0.3%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Deviation in thickness</td>
                                    <td>± 5.0%</td>
                                    <td>± 4.0%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Straightness in side</td>
                                    <td>± 0.5%</td>
                                    <td>± 0.1%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Rectangularity</td>
                                    <td>± 0.5%</td>
                                    <td>± 0.1%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Surface flatness</td>
                                    <td>± 0.5%</td>
                                    <td>± 0.2%</td>
                                    <td>ISO-10545-2</td>
                                </tr>
                                <tr>
                                    <td>Color difference</td>
                                    <td>Unlterned</td>
                                    <td>No change</td>
                                    <td>ISO-10545-16</td>
                                </tr>
                                <tr>
                                    <td>Glossiness</td>
                                    <td>As per mfg.</td>
                                    <td>Min 90%</td>
                                    <td>GLOSSOMETER</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">STRUCTURAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Water absorption</td>
                                    <td>&lt; 0.50%</td>
                                    <td>&lt; 0.05%</td>
                                    <td>ISO-10545-3</td>
                                </tr>
                                <tr>
                                    <td>Apparent density</td>
                                    <td>&gt; 2.0 g/cc</td>
                                    <td>&gt; 2.10 g/cc</td>
                                    <td>DIN 51082</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">MASSIVE MECHANICAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Modulus of repture</td>
                                    <td>Min. 35 N/mm2</td>
                                    <td>Min. 40 N/mm2</td>
                                    <td>ISO-10545-4</td>
                                </tr>
                                <tr>
                                    <td>Breaking strength</td>
                                    <td>Min. 1300 N</td>
                                    <td>Min. 2000 N</td>
                                    <td>ISO-10545-4</td>
                                </tr>
                                <tr>
                                    <td>Impact resistance</td>
                                    <td>As per mfg.</td>
                                    <td>Min. 0.55</td>
                                    <td>ISO-10545-5</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">SURFACE MECHANICAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Surface abrasion resistance</td>
                                    <td>As per mfg.</td>
                                    <td>Min. Class-3</td>
                                    <td>ISO-10545-7</td>
                                </tr>
                                <tr>
                                    <td>MOH's hardness</td>
                                    <td>As per mfg.</td>
                                    <td>Min. 6</td>
                                    <td>EN 101</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">THERMO HYGROMRTRIC PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Frost resistance</td>
                                    <td>No damage</td>
                                    <td>No damage</td>
                                    <td>ISO-10545-12</td>
                                </tr>
                                <tr>
                                    <td>Thermal shock resistance</td>
                                    <td>No damage</td>
                                    <td>No damage</td>
                                    <td>ISO-10545-9</td>
                                </tr>
                                <tr>
                                    <td>Moisture expansion</td>
                                    <td>Nill</td>
                                    <td>Nill</td>
                                    <td>ISO-10545-10</td>
                                </tr>
                                <tr>
                                    <td>Thermal expansion(COE)</td>
                                    <td>Max. 9.0 x 10⁻⁶</td>
                                    <td>Max. 6.5 x 10⁻⁶</td>
                                    <td>ISO-10545-8</td>
                                </tr>
                                <tr>
                                    <td>Crazing resistance</td>
                                    <td>As per mfg.</td>
                                    <td>Min. 10 Cycle</td>
                                    <td>ISO-10545-11</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">CHEMICAL PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Chemical resistance</td>
                                    <td>No damage</td>
                                    <td>No damage</td>
                                    <td>ISO-10545-13</td>
                                </tr>
                                <tr>
                                    <td>Stain resistance</td>
                                    <td>Resistant</td>
                                    <td>Resistant</td>
                                    <td>ISO-10545-14</td>
                                </tr>
                                <tr class="subHead">
                                    <td colspan="4">SEFETY PROPERTIES</td>
                                </tr>
                                <tr>
                                    <td>Slip resistance</td>
                                    <td>As per mfg.</td>
                                    <td>&gt; 0.40</td>
                                    <td>ISO-10545-17</td>
                                </tr>
                                <tr>
                                    <td>Fire resistance</td>
                                    <td>As per mfg.</td>
                                    <td>Fireproof</td>
                                    <td>N.A.</td>
                                </tr>
                                <tr>
                                    <td>Lead &amp; Cafmium given off by glazes tiles</td>
                                    <td>As per mfg.</td>
                                    <td>Does not yield Pb &amp; Cd</td>
                                    <td>ISO-10545-15</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-5">
                <div class="details spects-table">
                    <div id="doubleCharge">
                        <h2 class="techSpecTitle">Packaging Details</h2>
                        <table class="tsTable">
                            <tbody>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Products</th>
                                    <th>Nominal Tile Size</th>
                                    <th>Tile Per Box</th>
                                    <th>Coverage Area(approx)</th>
                                    <th>wt./ box (approx)</th>
                                    <th>THICKNESS IN MM</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Vitrified Tiles</td>
                                    <td>600x1200mm</td>
                                    <td>2 pcs.</td>
                                    <td>1.44 sq. mtr.</td>
                                    <td>38 kg.</td>
                                    <td>11.5mm</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Vitrified Tiles</td>
                                    <td>600x600mm</td>
                                    <td>4 pcs</td>
                                    <td>1.44 sq. mtr.</td>
                                    <td>31 kg.</td>
                                    <td>9.5mm</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Vitrified Tiles</td>
                                    <td>800x800mm</td>
                                    <td>2 pcs.</td>
                                    <td>1.28 sq. mtr.</td>
                                    <td>33 kg.</td>
                                    <td>11.5 mm</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Vitrified Tiles</td>
                                    <td>1000x1000mm</td>
                                    <td>2 pcs.</td>
                                    <td>2 sq. mtr.</td>
                                    <td>52 kg.</td>
                                    <td>11.5mm</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-5">
                <div class="details spects-table">
                    <div id="doubleCharge">
                        <h2 class="techSpecTitle"><span >DOUBLE CHARGE TILES</span> - 60X60CM</h2>
                        <h2 class="techSpecTitle"><span>DOUBLE CHARGE TILES</span> - 80X80CM</h2>
                        <table class="tsTable">
                            <tbody>
                                <tr>
                                    <th>TECHNICAL PARAMETER</th>
                                    <th>INTERNATIONAL STD.ISO 13006/EN 176 GROUP BLA</th>
                                    <th>GUJARAT TILES VALUE</th>
                                    <th>METHOD OF TESTING</th>
                                </tr>
                                <tr>
                                    <td>Deviation in length</td>
                                    <td>± 0.6%</td>
                                    <td>±0.3%</td>
                                    <td>EN 98</td>
                                </tr>
                                <tr>
                                    <td>Deviation in thickness</td>
                                    <td>± 5%</td>
                                    <td>±3.0%</td>
                                    <td>EN 98</td>
                                </tr>
                                <tr>
                                    <td>Straightness of sides</td>
                                    <td>±0.5%</td>
                                    <td>±0.3%</td>
                                    <td>EN 98</td>
                                </tr>
                                <tr>
                                    <td>Rectangularity</td>
                                    <td>±0.6%</td>
                                    <td>±0.4%</td>
                                    <td>EN 98</td>
                                </tr>
                                <tr>
                                    <td>Surface Flatness</td>
                                    <td>±0.5%</td>
                                    <td>±0.4%</td>
                                    <td>EN 98</td>
                                </tr>
                                <tr>
                                    <td>Breking Stength</td>
                                    <td>1113 N</td>
                                    <td>&gt; 2600 N</td>
                                    <td>C 648</td>
                                </tr>
                                <tr>
                                    <td>Density(g/cc)</td>
                                    <td>&gt; 2</td>
                                    <td>2.4</td>
                                    <td>DIN51082</td>
                                </tr>
                                <tr>
                                    <td>Scratch Hardness(moh's)</td>
                                    <td>≥ 6</td>
                                    <td>&gt; 7</td>
                                    <td>EN 101</td>
                                </tr>
                                <tr>
                                    <td>Abrasion Resistance</td>
                                    <td>&lt; 204 N/mm<span class="font5">³</span></td>
                                    <td>&lt; 143 N/mm<span class="font5">³</span></td>
                                    <td>EN 102</td>
                                </tr>
                                <tr>
                                    <td>Chemical Resistance(EXCEPT HF)</td>
                                    <td>No dmage</td>
                                    <td>No damage</td>
                                    <td>EN 106</td>
                                </tr>
                                <tr>
                                    <td>colour Resistance to light</td>
                                    <td>No dmage</td>
                                    <td>No damage</td>
                                    <td>DIN51091</td>
                                </tr>
                                <tr>
                                    <td>Thermal Expansion</td>
                                    <td>&lt; 9x10<span class="font5">⁻⁶</span></td>
                                    <td>&lt; 6x10⁻⁶</td>
                                    <td>EN 106</td>
                                </tr>
                                <tr>
                                    <td>Moisture Expansion</td>
                                    <td>Nil</td>
                                    <td>Nil</td>
                                    <td>ISO 10545 10</td>
                                </tr>
                                <tr>
                                    <td>Thermal shock Resistance</td>
                                    <td>No damge</td>
                                    <td>No damage</td>
                                    <td>EN 104</td>
                                </tr>
                                <tr>
                                    <td>Skid Resistance</td>
                                    <td>&gt;0.4</td>
                                    <td>&gt; 0.6</td>
                                    <td>ASTM C-1028</td>
                                </tr>
                                <tr>
                                    <td>Flexural Strength(N/mm<span class="font5">²)</span></td>
                                    <td>≥ 27</td>
                                    <td>≥ 45</td>
                                    <td>EN 100</td>
                                </tr>
                                <tr>
                                    <td>Glossiness</td>
                                    <td>-</td>
                                    <td>Min 92% Reflection</td>
                                    <td>Glossmeter</td>
                                </tr>
                                <tr>
                                    <td>Resistance to house hold chemical</td>
                                    <td>No visible effect</td>
                                    <td>No visible effect</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->

</main>