<div class="details content-details">
    <h2>Products </h2>
    <div style="overflow-x:auto;">
        <table>
            <tr *ngFor="let prod of products">
                <td>{{prod.category}}</td>
                <td>
                    <span *ngFor="let sc of prod.subcategory">{{sc}}<br></span>
                </td>
            </tr>
        </table>
        <h6>Product Description</h6>
        <p>{{description}}</p>
    </div>
</div>