<main>
	<app-breadcrumb [pageName]="'JOB LIST VIEW'"></app-breadcrumb>
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>JOB LIST VIEW</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
				</div>
			</div>

			<div class="tab">
				<button class="tablinks" [ngClass]="{'active' : first}" (click)="onTabChange('company')">Company Job
					List</button>
				<button class="tablinks" [ngClass]="{'active' : second}" (click)="onTabChange('candidate')">Candidate
					List</button>
				<button class="tablinks" [ngClass]="{'active' : third}" (click)="onTabChange('postform')">Add
					Post</button>
			</div>

			<div id="company" class="tabcontent" style="display: block;" *ngIf="currentTab == 'company'">
				<div class="row justify-content-center">
					<div class="col-xl-10">
						<ul class="single-job" *ngFor="let job of jobList">
							<li>
								<img alt="Job Image" class="job-image-job-junction" [src]="job.image">
							</li>
							<li class="all-data">
								<div class="author-det">
									<h4>{{job.job_title}}</h4>
									<h5>{{job.education}}</h5>
									<h6>{{job.experience}} Experience</h6>
									<p>Mobile: {{job.mobile}} &nbsp;&nbsp;&nbsp; City: {{job.city}}</p>
									<p>Company.: {{job.company_name}}</p>
									<p>Desc.: {{job.description}}</p>
								</div>
							</li>
							<li class="jobsesc-job-junction">
								<div class="author-det">
									<h5>Description</h5>
									<p>{{job.description}}</p>
								</div>
							</li>
							<li>
								<a (click)="showContact(job.mobile)"><button class="view-btn view-new">Apply Now <i
											class="fa fa-hand-pointer-o"></i></button></a>
							</li>
						</ul>
					</div>
					<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="jobNextPage < jobTotalpage">
						<a class="view-btn view-new" style="cursor: pointer;" (click)="getJobs()"> View More</a>
					</div>
				</div>
			</div>
			<div id="candidate" class="tabcontent" style="display: block;" *ngIf="currentTab == 'candidate'">
				<div class="row justify-content-center">
					<div class="col-xl-10">
						<ul class="single-job" *ngFor="let candidate of candidateList">
							<li>
								<img alt="" class="job-image-job-junction" [src]="candidate.image">
							</li>
							<li class="all-data">
								<div class="author-det">
									<h4>{{candidate.name}}</h4>
									<h6>{{candidate.experience}} Experience</h6>
									<p>{{candidate.education}}</p>
									<p>{{candidate.dob}}</p>
								</div>
							</li>
							<li class="jobsesc-job-junction">

							</li>
							<li>
								<a href="{{candidate.resume}}" target="_blank" download
									class="view-btn view-new">Resume</a><br><br>
								<a href="tel:{{candidate.mobile}}" class="view-btn view-new">{{candidate.mobile}}</a>
							</li>
						</ul>
					</div>
					<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center"
						*ngIf="candidateNextPage < candidateTotalpage">
						<a class="view-btn view-new" style="cursor: pointer;" (click)="getCandidate()"> View More</a>
					</div>
				</div>
			</div>
			<div id="candidate" class="tabcontent" style="display: block;" *ngIf="currentTab == 'postform'">
				<div class="row justify-content-center">
					<div class="col-md-10">
						<div class="form-box">
							<div class="row">
								<div class="col-md-6 pr-3">
									&nbsp;&nbsp;<input type="radio" name="type" value="1" (change)="typeChange('1')"
										checked> &nbsp;&nbsp;Company Job
								</div>
								<div class="col-md-6">
									<input type="radio" name="type" value="2" (change)="typeChange('2')">&nbsp;&nbsp;
									Candidate
								</div>
							</div>
							<form [formGroup]="companyForm" (ngSubmit)="onSubmitCompany()" *ngIf="formType == 1">
								<div class="row">
									<div class="col-md-12 mt-3">
										<label for="image" class="label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company logo</label>
										<input type="file" name="image" id="image" class="mt-0 file-input" accept="image/*" #image (change)="onSelectImage(image.files)" />
										<label
											[ngClass]="{'is-invalid':companyForm.get('image').touched && companyForm.get('image').invalid}"
											class="invalid-feedback font-medium">Please select image</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="company_name" formControlName="company_name"
											placeholder="Company Name">
										<label
											[ngClass]="{'is-invalid':companyForm.get('company_name').touched && companyForm.get('company_name').invalid}"
											class="invalid-feedback font-medium">Please enter company name</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="city" formControlName="city" placeholder="City">
										<label
											[ngClass]="{'is-invalid':companyForm.get('city').touched && companyForm.get('city').invalid}"
											class="invalid-feedback font-medium">Please enter city name</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="mobile" formControlName="mobile" placeholder="Cell no">
										<label
											[ngClass]="{'is-invalid':companyForm.get('mobile').touched && companyForm.get('mobile').invalid}"
											class="invalid-feedback font-medium">Please enter cell no.</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="job_title" formControlName="job_title"
											placeholder="Job Title">
										<label
											[ngClass]="{'is-invalid':companyForm.get('job_title').touched && companyForm.get('job_title').invalid}"
											class="invalid-feedback font-medium">Please enter job title</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="education" formControlName="education"
											placeholder="Education">
										<label
											[ngClass]="{'is-invalid':companyForm.get('education').touched && companyForm.get('education').invalid}"
											class="invalid-feedback font-medium">Please enter education</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="experience" formControlName="experience"
											placeholder="Experience">
										<label
											[ngClass]="{'is-invalid':companyForm.get('experience').touched && companyForm.get('experience').invalid}"
											class="invalid-feedback font-medium">Please enter experience</label>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
										<textarea type="text" name="description" formControlName="description"
											placeholder="Description"></textarea>
										<label
											[ngClass]="{'is-invalid':companyForm.get('description').touched && companyForm.get('description').invalid}"
											class="invalid-feedback font-medium">Please enter description</label>
									</div>
								</div>
								<div style="text-align: center;">
									<input type="submit" name="" value="Submit">
								</div>
							</form>
							<form [formGroup]="candidateForm" (ngSubmit)="onSubmitCandidate()" *ngIf="formType == 2">
								<div class="row">
									<div class="col-md-12 mt-3">
										<label for="image" class="label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Image</label>
										<input type="file" name="image" id="image" class="mt-0 file-input" accept="image/*" #image (change)="onSelectImage(image.files)" />
										<label
											[ngClass]="{'is-invalid':candidateForm.get('image').touched && candidateForm.get('image').invalid}"
											class="invalid-feedback font-medium">Please select image</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="name" formControlName="name"
											placeholder="Full Name">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('name').touched && candidateForm.get('name').invalid}"
											class="invalid-feedback font-medium">Please enter fullname</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="city" formControlName="city" placeholder="City">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('city').touched && candidateForm.get('city').invalid}"
											class="invalid-feedback font-medium">Please enter city name</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="mobile" formControlName="mobile" placeholder="Cell no">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('mobile').touched && candidateForm.get('mobile').invalid}"
											class="invalid-feedback font-medium">Please enter cell no.</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="date" name="dob" formControlName="dob"
											placeholder="Date of birth">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('dob').touched && candidateForm.get('dob').invalid}"
											class="invalid-feedback font-medium">Please select date of birth</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="education" formControlName="education"
											placeholder="Education">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('education').touched && candidateForm.get('education').invalid}"
											class="invalid-feedback font-medium">Please enter education</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="experience" formControlName="experience"
											placeholder="Experience">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('experience').touched && candidateForm.get('experience').invalid}"
											class="invalid-feedback font-medium">Please enter experience</label>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 mt-3">
										<label for="image" class="label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resume</label>
										<input type="file" name="image" id="image" class="mt-0 file-input" #resume (change)="onSelectResume(resume.files)" />
										<label
											[ngClass]="{'is-invalid':candidateForm.get('resume').touched && candidateForm.get('resume').invalid}"
											class="invalid-feedback font-medium">Please select resume</label>
									</div>
								</div>
								<div style="text-align: center;">
									<input type="submit" name="" value="Submit">
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>