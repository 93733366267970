import { Component, OnInit } from '@angular/core';
import { InformativePagesService } from '../../services/informative-pages.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-term-condition',
	templateUrl: './term-condition.component.html',
	styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {
	data: any = '';
	constructor(
		private pageService: InformativePagesService,
		private userService: UserService,
	) { }

	ngOnInit(): void {
		this.pageService.term().subscribe(res => {
			if (res.success) {
				this.data = res.data;
			} else {
				this.userService.errorMessage(res.message);
			}
		})
	}
}
