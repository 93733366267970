<header class="header-area" id="header-sticky">
   <div class="menu-area">
      <div class="container">
         <div class="row ">
            <div class="col-xl-1 col-lg-1">
               <div class="menu_container">
                  <div class="btn_container" (click)="showSidebar()">
                     <label *ngIf="newNotification" class="notification-dot-header"></label>
                     <span></span>
                     <span></span>
                     <span></span>
                  </div>
                  <div class="over_lay"></div>
                  <app-sidebar></app-sidebar>
               </div>
            </div>
            <div class="col-xl-9 col-lg-9  d-none d-xl-block d-lg-block">
               <div class="smart-main-menu">
                  <nav id="mobile-menu">
                     <ul id="menu" style="margin-bottom: 0;">
                        <li><a routerLink="home">Home</a></li>
                        <li><a routerLink="directory">Directory</a></li>
                        <li><a routerLink="dealer">Dealer List</a></li>
                        <li><a routerLink="traders">Trader List</a></li>
                        <li><a routerLink="seller-posts">Seller Post List</a></li>
                        <li><a routerLink="buyer-posts">Buyer Post List</a></li>
                        <li class="nav-item dropdown tool-dropdown desktop-menu">
                           <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Tools
                           </a>
                           <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                              <a class="dropdown-item" routerLink="specification">Specification</a>
                              <a class="dropdown-item" routerLink="packing-details">Packing Details</a>
                              <a class="dropdown-item" routerLink="tiles-calculator">Tiles Calculator</a>
                              <a class="dropdown-item" href="#">Making Pricelist</a>
                           </div>
                        </li>
                        <li class="d-xl-none d-lg-none">
                           <a href="#" >Tools</a>
                           <ul>
                              <li><a routerLink="specification">Specification</a></li>
                              <li><a routerLink="packing-details">Packing Details</a></li>
                              <li><a routerLink="tiles-calculator">Tiles Calculator</a></li>
                              <li><a routerLink="#">Making Pricelist</a></li>
                           </ul>
                        </li>
                     </ul>
                  </nav>
               </div>
            </div>
            <div class="col-xl-1 col-lg-1 d-xl-none d-lg-none">
               <div class="responsive-menu"></div>
            </div>
            <div class="col-xl-2 col-lg-2 ">
               <div class="top-right" *ngIf="!user?.id">
                  <a routerLink="login">SIGN IN</a>
                  <a routerLink="register">JOIN FREE</a>
               </div>
               <div class="top-right-loggedin" *ngIf="user?.id">
                  <img src="{{user?.profile}}" alt="user" class="user-image-header">
                  <a>{{user?.name}}</a>
                  <ul id="submenu">
                     <li><a routerLink="profile">My Profile</a></li>
                     <li><a>Settings </a></li>
                     <li><a routerLink="/help-support"> Help &amp; Support </a></li>
                     <li (click)="logout()" ><a> Logout </a></li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</header>