import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-tiles-product-size',
	templateUrl: './tiles-product-size.component.html',
	styleUrls: ['./tiles-product-size.component.css']
})
export class TilesProductSizeComponent implements OnInit {

	constructor() { }
	@Input('category') category = [];
	@Input('description') description = '';

	ngOnInit(): void {
		// console.log(this.category)
		// console.log(this.description)
	}

}
