import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError, Observable, Subject } from 'rxjs';
import { apiConstants } from '../constants/api-constants';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APIResponse } from '../Modal/apiresponse';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private authStatusListener = new Subject<boolean>();
	public sidebarCountListener = new Subject<boolean>();
	public accessRoleListener = new Subject<boolean>();

	constructor(private toastr: ToastrService,  private http: HttpClient) { }

	loginMobile(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.loginMobile, form).pipe(catchError(this.handleError));
	}

	login(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.login, form).pipe(catchError(this.handleError));
	}
	getRegisterFields(): Observable<APIResponse> {
		return this.http.get(apiConstants.registerFields).pipe(catchError(this.handleError));
	}
	register(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.register, form).pipe(catchError(this.handleError));
	}
	verifyOtp(form:any):Observable<APIResponse>{
		return this.http.post(apiConstants.verifyOtp, form).pipe(catchError(this.handleError));
	}
	getProfile(form:any):Observable<APIResponse>{
		return this.http.post(apiConstants.profile, form).pipe(catchError(this.handleError));
	}
	saveProfile(form:any):Observable<APIResponse>{
		return this.http.post(apiConstants.profileSave, form).pipe(catchError(this.handleError));
	}
	accessRole(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.getAccessRole, form).pipe(catchError(this.handleError));
	}
	newsletter(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.newsletter, form).pipe(catchError(this.handleError));
	}
	support(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.support, form).pipe(catchError(this.handleError));
	}
	feedback(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.feedback, form).pipe(catchError(this.handleError));
	}
	digitalCardGenerate(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.digitalCard, form).pipe(catchError(this.handleError));
	}

	/* Forgot Password*/
	forgot_password(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.forgot_password, form).pipe(catchError(this.handleError));
	}
	verify_otp(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.verify_forgot_password_otp, form).pipe(catchError(this.handleError));
	}
	reset_password(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.reset_password, form).pipe(catchError(this.handleError));
	}
	
	logout(): void {
		localStorage.removeItem('id');
		localStorage.removeItem('token');
		localStorage.removeItem('profile');
		localStorage.removeItem('name');
		localStorage.removeItem('company_id');
		localStorage.removeItem('company_type');
		localStorage.removeItem('company_name');
		localStorage.removeItem('company_city');
		localStorage.removeItem('designation');
		localStorage.removeItem('access_role');
		localStorage.removeItem('is_access_role_available');
	}


	/*  Set user id for forgot password*/
	setUserId(userId) {
		localStorage.setItem('userId',userId.toString());
	}

	getUserId() {
		return localStorage.getItem('userId');
	}

	removeUserId() {
		localStorage.removeItem('userId');
	}

	getAccessRole(): any {
		return {access_role : localStorage.getItem('access_role'), is_access_role_available : localStorage.getItem('is_access_role_available')}
	}
	getToken(): string {
		return localStorage.getItem('token');
	}
	getUser(): any {
		return {
			id			: localStorage.getItem('id'),
			name 		: localStorage.getItem('name'),
			profile 	: localStorage.getItem('profile'),
			company_id 	: localStorage.getItem('company_id'),
			company_type: localStorage.getItem('company_type'),
			company_name: localStorage.getItem('company_name'),
			company_city: localStorage.getItem('company_city'),
			company_contact: localStorage.getItem('company_contact'),
			designation : localStorage.getItem('designation'),
			digital_card : localStorage.getItem('digital_card'),
		};
	}
	getId(): string {
		return localStorage.getItem('id');
	}
	setRegister(): void {
		localStorage.setItem('is_registered','true');
	}
	setId(id:number): void {
		localStorage.setItem('id',id.toString());
	}
	setAuthData(
		id:number,
		token:string,
		name:string,
		image:string,
		company_id, 
		company_type,
		designation,
		cp_name, 
		cp_city, 
		cp_contact,
		is_access_role_available,
		access_role,
		digital_card
	): void {
		localStorage.setItem('id',id.toString());
		localStorage.setItem('token',token);
		localStorage.setItem('name',name);
		localStorage.setItem('profile', image);
		localStorage.setItem('company_id', company_id);
		localStorage.setItem('company_type', company_type);
		localStorage.setItem('company_name', cp_name);
		localStorage.setItem('company_city', cp_city);
		localStorage.setItem('company_contact', cp_contact);
		localStorage.setItem('designation', designation);
		localStorage.setItem('is_access_role_available', is_access_role_available);
		localStorage.setItem('access_role', JSON.stringify(access_role));
		localStorage.setItem('digital_card', digital_card);
	}
	getFcmToken() {
		return localStorage.getItem('fcm_token');
	}
	getAuthStatusListener() {
		return this.authStatusListener.asObservable();
	}
	setAuthStatusListener() {
		this.authStatusListener.next(true);
	}
	setAuthLogoutListener() {
		this.authStatusListener.next(false);
	}
	setaccessRoleListener() {
		this.accessRoleListener.next(true);
	}
	setSidebarCountListener(data) {
		this.sidebarCountListener.next(data);
	}

	
	sucessMessage(message: string): void {
		this.toastr.success(message, 'Success!');
	}
	errorMessage(message: string): void {
		this.toastr.error(message, 'Error!');
	}
	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		return throwError('Something bad happened; please try again later.');
	}
}
