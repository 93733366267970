<div class="get-quote-sec" style="background: url(assets/img/banner-get.png);">
	<h3>GET BEST OFFER</h3>
	<form [formGroup]="inquiryForm" (ngSubmit)="onSubmit()">
		<div class="radio-main">
			<label class="radio-con">Best Rate
				<input type="radio" formControlName="type" name="type" value="1">
				<span class="checkmark"></span>
			</label>
			<label class="radio-con"> Best Quality
				<input type="radio" formControlName="type" name="type" value="2">
				<span class="checkmark"></span>
			</label>
			<label class="radio-con">Average Rate Quality
				<input type="radio" formControlName="type" name="type" value="3">
				<span class="checkmark"></span>
			</label>
		</div>
		<br>

		<label class="label-heading">Requirement :-</label>
		<br>

		<div class="radio-main">
			<label class="radio-con">Tiles
				<input type="radio" name="requirement" formControlName="requirement" value='1' (change)="onRequirementChange($event)">
				<span class="checkmark"></span>
			</label>
			<label class="radio-con">Sanitary
				<input type="radio" name="requirement" formControlName="requirement" value='2' (change)="onRequirementChange($event)">
				<span class="checkmark"></span>
			</label>
			<label class="radio-con"> Various Products
				<input type="radio" name="requirement" formControlName="requirement" value='3' (change)="onRequirementChange($event)">
				<span class="checkmark"></span>
			</label>
		</div>
		<br>


		<div class="row">
			<div class="col-xl-6 col-md-6 col-xs-12">
				<label class="label-heading">Products :-</label>
				<br>
				<div class="select-box">
					<select *ngIf="inquiryForm.value.requirement == 1" (change)="onChangeProduct($event)" formControlName="product">
						<option value="">Select Product</option>
						<option *ngFor="let p of tiles" [value]="p.id">{{p.category}}</option>
					</select>
					
					<select *ngIf="inquiryForm.value.requirement == 2" formControlName="product">
						<option value="">Select Product</option>
						<option *ngFor="let p of sanitary" [value]="p.id">{{p.category}}</option>
					</select>
					
					<select *ngIf="inquiryForm.value.requirement == 3" formControlName="product">
						<option value="">Select Product</option>
						<option *ngFor="let p of various" [value]="p.id">{{p.category}}</option>
					</select>
					<label [ngClass]="{'is-invalid':inquiryForm.get('product').touched && inquiryForm.get('product').invalid}" class="invalid-feedback font-medium">Please select product</label>
				</div>
			</div>
			<div class="col-xl-6 col-md-6 col-xs-12" >
				<label class="label-heading" *ngIf="inquiryForm.value.requirement == 1">Size :-</label>
				<br>
				<div class="select-box" *ngIf="inquiryForm.value.requirement == 1">
					<select formControlName="size">
						<option value="">Select Size</option>
						<option *ngFor="let s of sizes" [value]="s.id">{{s.size}}</option>
					</select>
					<label [ngClass]="{'is-invalid':inquiryForm.get('size').touched && inquiryForm.get('size').invalid}" class="invalid-feedback font-medium">Please select size</label>
				</div>
			</div>
			<div class="col-xl-4 col-md-4 col-xs-12">
				<div class="input-form">
					<input type="text" class="search-input" formControlName="firm_name" placeholder="Enter Firm Name">
					<label [ngClass]="{'is-invalid':inquiryForm.get('firm_name').touched && inquiryForm.get('firm_name').invalid}" class="invalid-feedback font-medium">Please enter firm name</label>
				</div>
			</div>
			<div class="col-xl-4 col-md-4 col-xs-12">
				<div class="input-form">
					<input type="text" class="search-input" formControlName="city" placeholder="City">
					<label [ngClass]="{'is-invalid':inquiryForm.get('city').touched && inquiryForm.get('city').invalid}" class="invalid-feedback font-medium">Please enter city</label>
				</div>
			</div>
			<div class="col-xl-4 col-md-4 col-xs-12">
				<div class="input-form">
					<input type="text" class="search-input" formControlName="cell" placeholder="Cell No.">
					<label [ngClass]="{'is-invalid':inquiryForm.get('city').touched && inquiryForm.get('city').invalid}" class="invalid-feedback font-medium">Please enter cell</label>
				</div>
			</div>
			<div class="col-xl-12 col-md-12 col-xs-12">
				<div style="text-align: center;">
					<input type="submit" class="view-btn" name="" value="Submit">
				</div>
			</div>
		</div>
	</form>
</div>