import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DefaulterService } from '../../services/defaulter.service';
import { UserService } from '../../services/user.service';
import { FormValidatorServiceService } from '../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
	selector: 'app-defaulter-form',
	templateUrl: './defaulter-form.component.html',
	styleUrls: ['./defaulter-form.component.css']
})
export class DefaulterFormComponent implements OnInit {
	form:FormGroup;
	@ViewChild('image') image: HTMLElement;
	fileTypes: Array<string> = ['jpg', 'jpeg', 'png'];
	constructor(
		private defaulterService : DefaulterService,
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder,
		private router : Router,
	) { }

	ngOnInit(): void {
		let user = this.userService.getUser();
		this.form = this.formBuilder.group({
			user_id	: [user.id],
			company_id	: [user.company_id],
			company_name : ['', [Validators.required]],
			address : ['', [Validators.required]],
			city : ['', [Validators.required]],
			district : ['', [Validators.required]],
			state : ['', [Validators.required]],
			country : ['', [Validators.required]],
			cell : ['', [Validators.required]],
			cell_alt : [''],
			gst : ['', [Validators.required]],
			amount_due : ['', [Validators.required]],
			built_date : ['', [Validators.required]],
			image : ['', [Validators.required]],
			desc : [''],
		});
	}
	onSelectImage(files){
		if (files.length === 0) {
		  return;
		}
		const extension = files[0].name.split('.').pop().toLowerCase();
		const isSuccess = this.fileTypes.indexOf(extension) > -1;
		if (isSuccess) {
		  	var reader = new FileReader();
		  	reader.onload = (event:any) => {
				this.form.patchValue({'image': files[0]});
		  	}
		  	reader.readAsDataURL(files[0]);
		} 
	}
	onSubmit():void{
		if (this.form.valid) {
			const form = new FormData();
			for (let value of Object.keys(this.form.value)) {
				form.append(value, this.form.value[value]);
			}
			this.defaulterService.save(form).subscribe(res => {
				if(res.success){
					this.userService.sucessMessage(res.message);
					this.router.navigate(['/defaulter'])
				}else{
					this.userService.errorMessage(res.message);
				}
			});
			
		}else {
			this.formValidationService.validateAllFormFields(this.form);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
}
