import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from './../../../services/user.service';
import { ChatService } from './../../../services/chat.service';

declare let $: any;

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
	private authStatusSubs: Subscription;
	constructor(private router: Router, private userService : UserService, private chatService: ChatService) { }
	user:any = {};

	newNotification:boolean = false;

	ngOnInit(): void {
		this.userService.sidebarCountListener.subscribe((response) => {
			let res:any = response;
			if(res.type == "inquiry" && res.curruntUrl != '/inbox' && res.count != 0){
				console.log('in',res)
				this.playSound();
			}
			if(res.type == "chat" && res.curruntUrl != '/chat' && res.count != 0){
				this.playSound();
			}
			if(res.type == "notification" && res.curruntUrl != '/notifications' && res.count != 0){
				this.playSound();
			}
		});
		this.user = this.userService.getUser();
		this.authStatusSubs = this.userService.getAuthStatusListener()
			.subscribe((response) => {
				if (response) {
					this.user = this.userService.getUser();
				} else {
					this.user = {};
				}
			});
	}
	playSound():void{
		let audio = new Audio();
		audio.src = "../../../../assets/notification.mp3";
		audio.load();
		audio.play();
		this.newNotification = true;
	}
	showSidebar() {
		this.newNotification = false;
		$(".over_lay").css({
			width: "100%"
		});
		$(".list_items_container").css({
			left: "0px"
		});
	}

	handleClick(action: string) {
		this.router.navigate([action], { queryParams: { isLogin: action == 'login' ? 1 : 0 } })
	}
	logout():void{
		this.user = {};
		this.userService.logout();
		this.chatService.setLogoutListener();
		this.router.navigate(['/login']);
	}

}
