<main>
    <app-breadcrumb [pageName]="'Defaulter Details'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Defaulter Details</h1>
                        <img src="assets/img/line.svg" width="120">
                    </div>
                </div>
            </div>

            <div class="row box-h mt-30">
                <div class="col-md-12 col-h text-center mt-5 mb-5 justify-content-center">
                    <img class="img-responsive" [src]="data?.image" alt="Defaulter Image">
                </div>
                <div class="col-md-12 col-h">
                    <div class="details">
                        <h4>Details</h4>
                        <div style="overflow-x:auto;">
                            <table>
                                <tr>
                                    <td style="width: 50%;">Company Name</td>
                                    <td>{{data?.company_name}}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{{data?.address}}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>{{data?.city}}</td>
                                </tr>
                                <tr>
                                    <td>District</td>
                                    <td>{{data?.district}}</td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>{{data?.state}}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>{{data?.country}}</td>
                                </tr>
                                <tr>
                                    <td>Cell No.</td>
                                    <td>{{data?.cell}}</td>
                                </tr>
                                <tr *ngIf="data?.cell_alt">
                                    <td>Other Cell No.</td>
                                    <td>{{data?.cell_alt}}</td>
                                </tr>
                                <tr>
                                    <td>GST No.</td>
                                    <td>{{data?.gst}}</td>
                                </tr>
                                <tr>
                                    <td>Amount Due</td>
                                    <td>{{data?.amount_due}}</td>
                                </tr>
                                <tr>
                                    <td>Bill Date</td>
                                    <td>{{data?.built_date}}</td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td>{{data?.desc}}</td>
                                </tr>
                                <tr *ngIf="data?.user_name">
                                    <td>Add by</td>
                                    <td>{{data?.user_name}}</td>
                                </tr>
                                <tr>
                                    <td>Added Company Name</td>
                                    <td>{{data?.cp_name}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->

</main>