<main>
  <app-breadcrumb [pageName]="'Directory'" *ngIf="!isCompactView"></app-breadcrumb>
  <!-- design area start -->
  <section class="design-area directory-section" id="service">
     <div class="container">
        <div class="row">
           <div class="col-xl-12 text-center">
              <div class="section-title ">
                  <h4 *ngIf="isCompactView">Gujarat Tiles Info</h4>
                 <h1>DIRECTORY</h1>
                 <img src="assets/img/line.svg" width="120">
              </div>
           </div>
        </div>
        <div class="row">
        
            <ng-container  *ngFor="let l of list">

           <div   *ngIf="l.category!='Supplier' && l.category!='Various MFG' && l.category!='Service Provider' && l.category!='Cab' && l.category!='Transporter' && l.category!='Hotel'"  class="col-xl-3 col-md-4 col-sm-4 col-xs-12">
              <a (click)="onClickDirectyory(l.id, l.type,l.category)" >
                 <div class="single-dir">
                    <img [src]="l.image" alt="Directory Image" class="directory-image">
                    <h2>{{l.category}}</h2>
                 </div>
              </a>
           </div>
         </ng-container>

 
           <div class="col-xl-3 col-md-4 col-sm-4 col-xs-12" *ngFor="let l of listdirectorymore">
            <a href="/other-manufacturer/{{l.name.replace(' ','-') | lowercase}}/{{l.id}}/">
               <div class="single-dir">
                  <img  [src]="l.image" alt="Directory Image" class="directory-image">
                  <h2>{{l.name}}</h2>
               </div>
            </a>
         </div>

         <ng-container  *ngFor="let l of list">

            <div   *ngIf="l.category=='Supplier'  ||  l.category=='Service Provider' || l.category=='Cab' || l.category=='Transporter' || l.category=='Hotel'" class="col-xl-3 col-md-4 col-sm-4 col-xs-12">
               <a (click)="onClickDirectyory(l.id, l.type,l.category)" >
                  <div class="single-dir">
                     <img [src]="l.image" alt="Directory Image" class="directory-image">
                     <h2>{{l.category}}</h2>
                  </div>
               </a>
            </div>
          </ng-container>


        </div>
     </div>
  </section>
</main>
