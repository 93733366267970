<main>
	<app-breadcrumb [pageName]="'DEFAULTER'"></app-breadcrumb>
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>DEFAULTER</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-xl-8 col-md-12 mb-3 text-right">
					<button routerLink="/defaulter/add" type="button" class="defaulter-add-btn">Add Defaulter</button>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-xl-8  col-md-12">
					<div class="single-buy" (click)="onClickDefaulter(def.id)" *ngFor="let def of defaulters">
						<div class="row align-center" style="width: 100%;">
							<div class="col-xl-3 col-md-3">
								<div style="text-align: center;">
									<img [src]="def.image">
								</div>
							</div>
							<div class="col-xl-3 col-md-3">
								<div class="tiles-info">
									<h5><b>{{def.company_name}}</b></h5>
									<h5>{{def.cell}}</h5>
								</div>
							</div>
							<div class="col-xl-3  col-md-3">
								<div class="users-info">
									<p>{{def.cell}}</p>
									<p>{{def.city}} | {{def.district}}</p>
									<p>{{def.built_date}}</p>
								</div>
							</div>
							<div class="col-xl-3  col-md-3">
								<div class="users-info">
									<p>GST No.</p>
									<p><b>{{def.gst}}</b></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new" style="cursor: pointer;" (click)="more()"> View More</a>
				</div>
			</div>

		</div>
	</section>
	<!-- design area end -->
</main>