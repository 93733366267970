import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { TilesManufactureService } from "../../services/tiles-manufacture.service";
import { SanitaryService } from "../../services/sanitary.service";
import { VariousService } from "../../services/various.service";
import { SupplierService } from "../../services/supplier.service";
import { ServiceProviderService } from "../../services/service-provider.service";
import { CabService } from "../../services/cab.service";
import { TransporterService } from "../../services/transporter.service";
import { HotelService } from "../../services/hotel.service";
import { TraderService } from "../../services/trader.service";
import { DealerService } from "../../services/dealer.service";
import { FollowService } from "../../services/follow.service";


import { ViewChild } from '@angular/core';

import { HomeService } from '../../services/home.service';

import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
//import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
	selector: "app-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
	
	statusForm: FormGroup;
	comapanyId: number = 0;
	type: number = 0;
	data: any = [];
	dataList: any = [];
	myMessageList: any = [];
	user;
	
	fileTypes: Array<string> = ['jpg', 'jpeg', 'png'];

	/*

	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		navSpeed: 600,
		navText: ['&#8249', '&#8250;'],
		responsive: {
		  0: {
			items: 1 
		  },
		  400: {
			items: 2
		  },
		  760: {
			items: 3
		  },
		  1000: {
			items: 3
		  }
		},
		nav: true
	  }
*/
	@ViewChild('statuspic') statuspic: HTMLElement;

	constructor(
		private router: Router,
		private userService: UserService,
		private tilesManufactureService: TilesManufactureService,
		private sanitaryManufactureService: SanitaryService,
		private variousManufactureService: VariousService,
		private supplierService: SupplierService,
		private serviceProviderService: ServiceProviderService,
		private cabService: CabService,
		private transporterService: TransporterService,
		private hotelService: HotelService,
		private traderService: TraderService,
		private dealerService: DealerService,
		private followService: FollowService,
		private homeService : HomeService,
		private formBuilder: FormBuilder,
		
	) { }

	ngOnInit(): void {

	

		this.user = this.userService.getUser()

		this.comapanyId = this.user.company_id;
		this.type = this.user.company_type;

		if(this.type == 1){
			this.tilesManufactureService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 2){
			this.sanitaryManufactureService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 3){
			this.variousManufactureService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 4){
			this.traderService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 5){
			this.dealerService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 6){
			this.transporterService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 7){
			this.supplierService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 8){
			this.hotelService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 9){
			this.serviceProviderService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else if(this.type == 10){
			this.cabService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
				this.response(response);
			})
		}else{
			this.router.navigate(['/']);
		}

		this.statusForm = this.formBuilder.group({
			id: [this.comapanyId.toString(), [Validators.required]],
			statusmessage: ['', [Validators.required]],
			statuspic: [''],
			statuspicImg: [''],
		});
     
	//	this.getStatusList()
		this.getStatusListById()
	}



		//Dynamic Company Photots End
		onSelectImage(files) {
			if (files.length === 0) {
				return;
			}
			const extension = files[0].name.split('.').pop().toLowerCase();
			const isSuccess = this.fileTypes.indexOf(extension) > -1;
			if (isSuccess) {
				this.statusForm.patchValue({ 'statuspic': files[0] });
				var reader = new FileReader();
				reader.onload = (event: any) => {
					this.statusForm.patchValue({ 'statuspicImg': event.target.result })
				}
				reader.readAsDataURL(files[0]);
			}
		}


	updateMessagestatus() {
		
		const form = new FormData();
		form.append(`id`,  this.comapanyId.toString());
		form.append(`statusmessage`, this.statusForm.value.statusmessage);



		form.append(`statuspic`, this.statusForm.value.statuspic);
	if(this.statusForm.value.statusmessage.length>0) {
		this.homeService.updateStatus(form).subscribe(res => {
			
			if(res.success){
				alert(res.message)
			}else{
				alert(res.message);
			}
		});
	} else {
     alert("Please add your status")
	}

	}

	getStatusList() {
		
		this.homeService.statusList(10).subscribe(res => {
			if(res.success){
			//	console.log(res)
				this.dataList = res
				//console.log(this.dataList )
			}else{
				
			}
		});
	}


	getStatusListById() {
		
		this.homeService.statusListById(this.comapanyId).subscribe(res => {
			if(res.success){
				
				this.myMessageList = res
				
			//	console.log(this.myMessageList )
			}else{
				
			}
		});
	}

	public handleMissingImage(event: Event) {
		//(event.target as HTMLImageElement).style.display = 'none';
		(event.target as HTMLImageElement).src="assets/img/placeholdermsg.png"
	//	assets/img/placeholdermsg.png

	  }

	/*
	updatestatus() {
		
		const form = new FormData();
		let r = (Math.random() + 1).toString(36).substring(7);
		form.append(`id`,  this.comapanyId.toString());
		form.append(`statusmessage`, "This is it" + r);
		this.homeService.updateStatus(form).subscribe(res => {
			if(res.success){
				alert(res.message)
			}else{
				alert(res.message);
			}
		});
	}
	*/

	followUnfollow(status):void{
		
		this.followService.followUnfolllowCompany({company_id : this.comapanyId, status: status}).subscribe(response => {
			if(response.success){
				this.userService.sucessMessage(response.message)
				this.ngOnInit();
			}else{
				this.userService.errorMessage(response.message)
			}
		})
	}

	response(response):void{
		if (response.success) {
			this.data = response.data;	
		} else {
			this.userService.errorMessage(response.message)
		}
	}
	edit(){
		this.router.navigate(['profile/edit'],{state: {editCid: this.comapanyId, type: this.type}})
	}
}
