import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-service-provider-service',
	templateUrl: './service-provider-service.component.html',
	styleUrls: ['./service-provider-service.component.css']
})
export class ServiceProviderServiceComponent implements OnInit {
	@Input('services') services = [];
	@Input('description') description = '';
	constructor() { }

	ngOnInit(): void {
	}

}
