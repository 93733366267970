<main>
	<app-breadcrumb [pageName]="'Notifications'"></app-breadcrumb>
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12  text-right">
					<a href="javascript:void(0)" class="view-btn view-new" (click)="markAllAsRead()">Mark All Read</a>
				</div>
				<div class="col-xl-12 notifi-data">
					<ul class="single-notification" *ngFor="let notification of notifications">
						<ng-container *ngIf="notification.c_id != 0 && (notification.notification_type == 'buyer_post' || notification.notification_type == 'seller_post')" >
							<li routerLink="/buyer-posts-detail" [state]="{id: notification.c_id}" *ngIf="notification.notification_type == 'buyer_post'">
								<div class="not-det">
									<p><b>{{notification.title}}</b></p>
									<p>{{notification.text}}</p>
									<p>{{notification.date}}</p>
								</div>
							</li>
							<li routerLink="/seller-posts-detail" [state]="{id: notification.c_id}" *ngIf="notification.notification_type == 'seller_post'">
								<div class="not-det">
									<p><b>{{notification.title}}</b></p>
									<p>{{notification.text}}</p>
									<p>{{notification.date}}</p>
								</div>
							</li>
						</ng-container>
						<ng-container *ngIf="notification.c_id != 0 && notification.notification_type != 'buyer_post' && notification.notification_type != 'seller_post'" >
							<a style="color: #000;" routerLink="/company/details" [state]="{id: notification.c_id, type:notification.c_type}" class="noti-wraper">
								<li *ngIf="notification.image != ''">
									<img [src]="notification.image">
								</li>
								<li>
									<div class="not-det">
										<p><b>{{notification.title}}</b></p>
										<p>{{notification.text}}</p>
										<p><b *ngIf="notification.notification_type  == 'single_company'">{{notification.c_name}}</b></p>
										<p *ngIf="notification.link != ''"><a href="{{notification.link}}" target="_blank">{{notification.link}}</a></p>
										<p>{{notification.date}}</p>
									</div>
								</li>
								<a routerLink="/company/details" [state]="{id: notification.c_id, type:notification.c_type}" class="view-btn view-new">View profile</a>
							</a>
						</ng-container>
						<ng-container *ngIf="notification.c_id == 0  && notification.notification_type != 'buyer_post' && notification.notification_type != 'seller_post'">
							<li *ngIf="notification.image != ''">
								<img [src]="notification.image">
							</li>
							<li>
								<div class="not-det">
									<p><b>{{notification.title}}</b></p>
									<p>{{notification.text}}</p>
									<p *ngIf="notification.link != ''"><a href="{{notification.link}}" target="_blank">{{notification.link}}</a></p>
									<p>{{notification.date}}</p>
								</div>
							</li>
						</ng-container>
					</ul>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new" style="cursor: pointer;" (click)="more()"> View More</a>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>