<main>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10 col-md-10 col-sm-10 col-xs-12">
                    <div class="form-box">
                        <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <input type="text" formControlName="companyName" name="" placeholder="Company Name">
                                    <div *ngIf="companyForm.get('companyName').touched && companyForm.get('companyName').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="companyForm.get('companyName').errors && companyForm.get('companyName').hasError('required')">Please enter company name</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <label for="company" class="label mt-3">Company Logo (300 x 300)</label>
                                    <input type="file" name="companyLogo" id="companyLogo"
                                            class="file-input mt-0" accept="image/*" #companyLogo
                                            (change)="onSelectImage(companyLogo.files,'companyLogo')" />
                                    <div *ngIf="companyForm.get('companyLogo').touched && companyForm.get('companyLogo').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="companyForm.get('companyLogo').errors && companyForm.get('companyLogo').hasError('required')">Please enter company name</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <img [src]="companyForm.value.companyLogoImage"
                                        class="img-thumbnail companyLogo"
                                        [ngClass]="{'hide':companyForm.value.companyLogoImage == ''}">
                                </div>                               
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <label for="company" class="label mt-3">Company Banner (300 x 600)</label>
                                    <input type="file" name="companyBanner" id="companyBanner"
                                            class="file-input mt-0" accept="image/*" #companyBanner
                                            (change)="onSelectImage(companyBanner.files,'companyBanner')" />
                                    <div *ngIf="companyForm.get('companyBanner').touched && companyForm.get('companyBanner').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="companyForm.get('companyBanner').errors && companyForm.get('companyBanner').hasError('required')">Please enter company name</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <img [src]="companyForm.value.companyBannerImage"
                                        class="img-thumbnail companyBanner"
                                        [ngClass]="{'hide':companyForm.value.companyBannerImage == ''}">
                                </div>                               
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="country" class="label mt-3">Country</label>
                                        <select id="country" class="mt-0" formControlName="country" (change)="onChangeCountry($event)">
                                            <option value="" disabled hidden>Select country</option>
                                            <option *ngFor="let country of countries" [value]="country.id">{{country.country}}</option>
                                        </select>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('country').touched && companyForm.get('country').invalid}"
                                            class="invalid-feedback font-medium">Please select country</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="state" class="label mt-3">State</label>
                                        <select class="mt-0" id="state" formControlName="state" (change)="onChangeState($event)">
                                            <option value="" disabled hidden>Select state</option>
                                            <option *ngFor="let state of states" [value]="state.id">{{state.state}}</option>
                                        </select>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('state').touched && companyForm.get('state').invalid}"
                                            class="invalid-feedback font-medium">Please select state</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="district" class="label ">District</label>
                                        <select class="mt-0" id="district"  formControlName="district" (change)="onChangeDistrict($event)">
                                            <option value="" disabled hidden>Select district</option>
                                            <option *ngFor="let district of districts" [value]="district.id">{{district.district}}</option>
                                        </select>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('district').touched && companyForm.get('district').invalid}"
                                            class="invalid-feedback font-medium">Please select district</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="zone" class="label ">Zone</label>
                                        <select class="mt-0" fullWidth id="zone" formControlName="zone">
                                            <option value="" disabled hidden>Select state</option>
                                            <option *ngFor="let zone of zones" [value]="zone.id">{{zone.zone}}</option>
                                        </select>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('zone').touched && companyForm.get('zone').invalid}"
                                            class="invalid-feedback font-medium">Please select zone</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="city" class="label ">City</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="city" placeholder="City"
                                            formControlName="city">
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('city').touched && companyForm.get('city').invalid}"
                                            class="invalid-feedback font-medium">Please enter city</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="address" class="label ">Address</label>
                                        <textarea rows="2" class="mt-0" nbInput fullWidth id="address" placeholder="Address"
                                            formControlName="address"></textarea>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('address').touched && companyForm.get('address').invalid}"
                                            class="invalid-feedback font-medium">Please enter Address</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="contact_no" class="label mt-0">General Contact Number</label>
                                        <input type="text" nbInput fullWidth class="mt-0" id="contact_no"
                                            placeholder="General Contact Number" formControlName="c_contact_no">
                                        <div
                                            *ngIf="companyForm.get('c_contact_no').touched && companyForm.get('c_contact_no').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_contact_no').errors && companyForm.get('c_contact_no').hasError('required')">Please
                                                enter contact number</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_contact_no').errors && companyForm.get('c_contact_no').hasError('pattern')">Invalid
                                                contact number</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="whatsapp_no" class="label mt-0">Whatsapp Number</label>
                                        <input type="text" nbInput fullWidth class="mt-0" id="whatsapp_no"
                                            placeholder="Whatsapp Number" formControlName="c_wp_no">
                                        <div
                                            *ngIf="companyForm.get('c_wp_no').touched && companyForm.get('c_wp_no').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_wp_no').errors && companyForm.get('c_wp_no').hasError('required')">Please
                                                enter whtsapp number</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_wp_no').errors && companyForm.get('c_wp_no').hasError('pattern')">Invalid
                                                whatsapp number</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="general_email" class="label">General Email</label>
                                        <input type="text" nbInput fullWidth class="mt-0" id="general_email"
                                            placeholder="General Email" formControlName="c_email">
                                        <div
                                            *ngIf="companyForm.get('c_email').touched && companyForm.get('c_email').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_email').errors && companyForm.get('c_email').hasError('required')">Please
                                                enter email</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_email').errors && companyForm.get('c_email').hasError('email')">Invalid
                                                email</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="website" class="label">Website</label>
                                        <input type="text" nbInput fullWidth class="mt-0" id="website" placeholder="Website"
                                            formControlName="c_website">
                                        <div
                                            *ngIf="companyForm.get('c_website').touched && companyForm.get('c_website').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_website').errors && companyForm.get('c_website').hasError('required')">Please
                                                enter website</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_website').errors && companyForm.get('c_website').hasError('pattern')">Invalid
                                                website url</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="gstin" class="label">GSTIN</label>
                                        <input type="text" nbInput fullWidth class="mt-0" id="gstin" placeholder="GSTIN"
                                            formControlName="c_gstin">
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_gstin').touched && companyForm.get('c_gstin').invalid}"
                                            class="invalid-feedback font-medium">Please enter GSTIN</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="established_year" class="label">Established Year</label>
                                        <select selected="" fullWidth class="mt-0" id="established_year"
                                            placeholder="Select year" formControlName="c_establised_year">
                                            <option value="" disabled hidden>Select year</option>
                                            <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
                                        </select>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_establised_year').touched && companyForm.get('c_establised_year').invalid}"
                                            class="invalid-feedback font-medium">Please select year</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Facebook" class="label">Facebook</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="Facebook"
                                            placeholder="Facebook" formControlName="c_facebook">
                                        <div
                                            *ngIf="companyForm.get('c_facebook').touched && companyForm.get('c_facebook').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_facebook').errors && companyForm.get('c_facebook').hasError('required')">Please
                                                enter url</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_facebook').errors && companyForm.get('c_facebook').hasError('pattern')">Please
                                                enter valid url</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Twitter" class="label">Twitter</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="Twitter" placeholder="Twitter"
                                            formControlName="c_twitter">
                                        <div
                                            *ngIf="companyForm.get('c_twitter').touched && companyForm.get('c_twitter').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_twitter').errors && companyForm.get('c_twitter').hasError('required')">Please
                                                enter url</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_twitter').errors && companyForm.get('c_twitter').hasError('pattern')">Please
                                                enter valid url</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Instagram" class="label">Instagram</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="Instagram"
                                            placeholder="Instagram" formControlName="c_instagram">
                                        <div
                                            *ngIf="companyForm.get('c_instagram').touched && companyForm.get('c_instagram').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_instagram').errors && companyForm.get('c_instagram').hasError('required')">Please
                                                enter url</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_instagram').errors && companyForm.get('c_instagram').hasError('pattern')">Please
                                                enter valid url</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="youtube" class="label">Youtube</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="youtube" placeholder="Youtube"
                                            formControlName="c_youtube">
                                        <div
                                            *ngIf="companyForm.get('c_youtube').touched && companyForm.get('c_youtube').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_youtube').errors && companyForm.get('c_youtube').hasError('required')">Please
                                                enter url</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_youtube').errors && companyForm.get('c_youtube').hasError('pattern')">Please
                                                enter valid url</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="latitude" class="label">Latitude</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="latitude"
                                            placeholder="Latitude" formControlName="c_latitude">
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_latitude').touched && companyForm.get('c_latitude').invalid}"
                                            class="invalid-feedback font-medium">Please enter latitude</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="longitude" class="label">Longitude</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="longitude"
                                            placeholder="Longitude" formControlName="c_longitude">
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_longitude').touched && companyForm.get('c_longitude').invalid}"
                                            class="invalid-feedback font-medium">Please enter longitude</label>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="data?.type == 1 || data?.type == 2 || data?.type == 3 || data?.type == 9">
                                    <div class="form-group">
                                        <label for="brand" class="label">Brand</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="brand" placeholder="Brand"
                                            formControlName="c_brand">
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_brand').touched && companyForm.get('c_brand').invalid}"
                                            class="invalid-feedback font-medium">Please enter brand</label>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="data?.type == 4 || data?.type == 5 || data?.type == 7">
                                    <div class="form-group">
                                        <label for="c_role" class="label">Role</label>
                                        <select id="c_role" formControlName="c_role" class="mt-0">
                                            <option value="" disabled hidden>Select role</option>
                                            <option value="Wholesaler">Wholesaler </option>
                                            <option value="Retailer">Retailer </option>
                                            <option value="Exporter">Exporter </option>
                                            <option value="Importer">Importer </option>
                                            <option value="Stockist">Stockist </option>
                                            <option value="Manufactures">Manufactures </option>
                                            <option value="Others">Others </option>
                                        </select>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_role').touched && companyForm.get('c_role').invalid}"
                                            class="invalid-feedback font-medium">Please select role</label>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="data?.type != 6 && data?.type != 8 && data?.type != 10">
                                    <div class="form-group">
                                        <label for="goc" class="label" *ngIf="data?.type == 4 || data?.type == 5 || data?.type == 7 || data?.type == 9">Deal of Companies</label>
                                        <label for="goc" class="label" *ngIf="data?.type != 4 && data?.type != 5 && data?.type != 7 && data?.type != 9">Group Of Company</label>
                                        <ng-multiselect-dropdown
                                            name="c_groupofcompany"
                                            [placeholder]="'Select companies'"
                                            [data]="comapnies"
                                            formControlName="c_groupofcompany"
                                            [settings]="dropdownSettings"
                                            [(ngModel)]="companyForm.value.c_groupofcompany"
                                        >
                                        </ng-multiselect-dropdown>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_groupofcompany').touched && companyForm.get('c_groupofcompany').invalid}"
                                            class="invalid-feedback font-medium">Please select companies</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="about" class="label">About</label>
                                        <ckeditor formControlName="c_about" [editor]="Editor"></ckeditor>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_about').touched && companyForm.get('c_about').invalid}"
                                            class="invalid-feedback font-medium">Please enter about</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" *ngIf="data?.type != 6 && data?.type != 8 && data?.type != 10">
                                    <div class="form-group">
                                        <label for="catalouge_pdf " class="label">Catalouge</label>
                                        <input type="text" class="mt-0" nbInput fullWidth id="catalouge_pdf"
                                            placeholder="Catalouge ( URL )" formControlName="c_catelog_pdf">
                                        <div
                                            *ngIf="companyForm.get('c_catelog_pdf').touched && companyForm.get('c_catelog_pdf').invalid">
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_catelog_pdf').errors && companyForm.get('c_catelog_pdf').hasError('required')">Please
                                                enter catalog url</label>
                                            <label class="invalid-feedback font-medium is-invalid"
                                                *ngIf="companyForm.get('c_catelog_pdf').errors && companyForm.get('c_catelog_pdf').hasError('pattern')">Please
                                                enter valid url</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="c_business_card" class="label">Business Card</label>
                                        <input type="file" name="c_business_card" id="c_business_card"
                                            class="file-input" accept="image/*" #businessCard class="mt-0"
                                            (change)="onSelectImage(businessCard.files,'c_business_card')" />
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_business_card').touched && companyForm.get('c_business_card').invalid}"
                                            class="invalid-feedback font-medium">Please select image</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <img [src]="companyForm.value.c_business_cardImage"
                                        class="img-thumbnail c_business_card"
                                        [ngClass]="{'hide':companyForm.value.c_business_cardImage == ''}">
                                </div>
                                <div class="col-md-12">
                                    <p class="mt-3"><b>Company Photos</b></p>
                                </div>
                            </div>
                            <div class="row align-items-center" formArrayName="c_photos" *ngFor="let c_photo of c_photos.controls; let i=index">
                                <ng-container [formGroupName]="i">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="company" class="label">Image</label>
                                            <input type="file" name="companyImage" id="companyImage"
                                                class="file-input mt-0" accept="image/*" #companyImage
                                                (change)="onSelectCompanyImage(companyImage.files,i)"/>
                                            <label
                                                [ngClass]="{'is-invalid':c_photos.controls[i].get('image').touched && c_photos.controls[i].get('image').invalid}"
                                                class="invalid-feedback font-medium">Please select image</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="title" class="label">Title</label>
                                            <input type="text" nbInput fullWidth id="title" class="mt-0"
                                                placeholder="Enter title" formControlName="title">
                                            <label
                                                [ngClass]="{'is-invalid':c_photos.controls[i].get('title').touched && c_photos.controls[i].get('title').invalid}"
                                                class="invalid-feedback font-medium">Please enter tilte</label>
                                        </div>
                                    </div>
                                    <div class="col-md-1 text-right pt-4">
                                        <button *ngIf="!isView" type="button" class="btn btn-success" nbButton status="success"
                                            (click)="addCompanyPhotos()"
                                            [hidden]="!(c_photos.length==(i+1))">+</button>
                                        <button *ngIf="!isView" type="button" class="btn btn-danger" nbButton status="danger"
                                            (click)="removeCompanyPhotos(i)"
                                            [hidden]="c_photos.length==(i+1)">-</button>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row" *ngIf="data?.type != 6 && data?.type != 8 && data?.type != 10">
                                <div class="col-md-12">
                                    <p class="mt-3"><b>Product Gallery</b></p>
                                </div>
                            </div>
                            <div *ngIf="data?.type != 6 && data?.type != 8 && data?.type != 10" class="row align-items-center">
                                <div class="col-md-12">
                                    <div class="row align-items-center" formArrayName="productGallary" *ngFor="let pg of productGallary.controls; let i=index">
                                        <ng-container [formGroupName]="i">
                                            <div class="col-md-5">
                                                <div class="form-group">
                                                    <label for="galleryImage-{{i}}" class="label">Image</label>
                                                    <input type="file" name="galleryImage" id="galleryImage-{{i}}"
                                                        class="file-input mt-0" accept="image/*" #galleryImage
                                                        (change)="onSelectGallaryImage(galleryImage.files,i)" />
                                                    <label
                                                        [ngClass]="{'is-invalid':productGallary.controls[i].get('image').touched && productGallary.controls[i].get('image').invalid}"
                                                        class="invalid-feedback font-medium">Please select image</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="pgtitle-{{i}}" class="label">Title</label>
                                                    <input type="text" nbInput fullWidth id="pgtitle-{{i}}" class="mt-0"
                                                        placeholder="Enter title" formControlName="title">
                                                    <label
                                                        [ngClass]="{'is-invalid':productGallary.controls[i].get('title').touched && productGallary.controls[i].get('title').invalid}"
                                                        class="invalid-feedback font-medium">Please enter tilte</label>
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-right pt-4">
                                                <button *ngIf="!isView" type="button" class="btn btn-success" nbButton status="success"
                                                    (click)="addProductGallary()"
                                                    [hidden]="!(productGallary.length==(i+1))">+</button>
                                                <button *ngIf="!isView" type="button" class="btn btn-danger" nbButton status="danger"
                                                    (click)="removeProductGallary(i)"
                                                    [hidden]="productGallary.length==(i+1)">-</button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="c_product_description" *ngIf="data?.type != 6 && data?.type != 8 && data?.type != 9" class="label">Product Description</label>
                                        <label for="c_product_description" *ngIf="data?.type == 6 || data?.type == 8 || data?.type == 9" class="label">Service Description</label>
                                        <textarea rows="3" nbInput fullWidth id="c_product_description"
                                            placeholder="Product Description"
                                            formControlName="c_product_description"></textarea>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('c_product_description').touched && companyForm.get('c_product_description').invalid}"
                                            class="invalid-feedback font-medium">Please enter product
                                            description</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 1">
                                <div class="col-md-12">
                                    <div class="row" formArrayName="tiles_categories" *ngFor="let c of tiles_categories.controls; let i=index" >
                                        <ng-container [formGroupName]="i">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="tiles-category-{{i}}" class="label">Category</label>
                                                    <select id="tiles-category-{{i}}" formControlName="category" (change)="onTilesChangeCategory($event, i)" class="mt-0">
                                                        <option value="" disabled hidden>Select category</option>
                                                        <option *ngFor="let category of tiles_category" [value]="category.id">{{category.category}}</option>
                                                    </select>
                                                    <label [ngClass]="{'is-invalid':tiles_categories.controls[i].get('category').touched && tiles_categories.controls[i].get('category').invalid}"
                                                        class="invalid-feedback font-medium">Please select category</label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="tiles-sub-category-{{i}}" class="label">Sub Categoty</label>
                                                    <select id="tiles-sub-category-{{i}}" formControlName="subcategory" (change)="onTilesChangeSubCategory($event, i)" class="mt-0">
                                                        <option value="" disabled hidden>Select sub category</option>
                                                        <option *ngFor="let sub of tiles_categories.controls[i].value.subcategoryList" [value]="sub.id">{{sub.sub_category}}</option>
                                                    </select>
                                                    <label [ngClass]="{'is-invalid':tiles_categories.controls[i].get('subcategory').touched && tiles_categories.controls[i].get('subcategory').invalid}" class="invalid-feedback font-medium">Please select sub category</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="tiles-size-{{i}}" class="label">Size</label>
                                                    <ng-select [items]="tiles_categories.controls[i].value.sizeList"
                                                                bindLabel="size"
                                                                placeholder="Select size"
                                                                appendTo="body"
                                                                [multiple]="true"
                                                                [ngModelOptions]="{standalone: true}"
                                                                formControlName="size">
                                                    </ng-select>
                                                    <label [ngClass]="{'is-invalid':tiles_categories.controls[i].get('size').touched && tiles_categories.controls[i].get('size').invalid}" class="invalid-feedback font-medium">Please select size</label>
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-right pt-4">
                                                <br>
                                                <button type="button" class="btn btn-success" status="success"
                                                    (click)="addTilesCategories()"
                                                    [hidden]="!(tiles_categories.length==(i+1))">+</button>
                                                <button type="button" class="btn btn-danger" status="danger"
                                                    (click)="removeTilesCategories(i)"
                                                    [hidden]="tiles_categories.length==(i+1)">-</button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 2">
                                <div class="col-md-6">
                                    <label for="m-category" class="label">Main Product</label>
                                    <div class="row" formArrayName="sanitary_main_categories" *ngFor="let p of sanitary_main_categories.controls; let i=index">
                                        <ng-container [formGroupName]="i">
                                            <div class="col-md-10">
                                                <div class="form-group">
                                                    <select placeholder="Select product" id="m-category-{{i}}" formControlName="category">
                                                        <option value="" disabled hidden>Select product</option>
                                                        <option *ngFor="let category of sanitary_category" [value]="category.id">{{category.category}}</option>
                                                    </select>
                                                    <label
                                                        [ngClass]="{'is-invalid':sanitary_main_categories.controls[i].get('category').touched && sanitary_main_categories.controls[i].get('category').invalid}"
                                                        class="invalid-feedback font-medium">Please select product</label>
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-center pt-4">
                                                <button type="button" nbButton status="success"
                                                    (click)="addSanitaryMainProduct()"
                                                    class="btn btn-success"
                                                    [hidden]="!(sanitary_main_categories.length==(i+1))">+</button>
                                                <button type="button" nbButton status="danger"
                                                    (click)="removeSanitaryMainProduct(i)"
                                                    class="btn btn-danger"
                                                    [hidden]="sanitary_main_categories.length==(i+1)">-</button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="m-category" class="label">Sub Product</label>
                                    <div class="row" formArrayName="sanitary_sub_categories" *ngFor="let p of sanitary_sub_categories.controls; let i=index">
                                        <ng-container [formGroupName]="i">
                                            <div class="col-md-10">
                                                <div class="form-group">
                                                    <select placeholder="Select product" fullWidth id="s-category-{{i}}" formControlName="category">
                                                        <option value="" disabled hidden>Select product</option>
                                                        <option *ngFor="let category of sanitary_category" [value]="category.id">{{category.category}}</option>
                                                    </select>
                                                    <label
                                                        [ngClass]="{'is-invalid':sanitary_sub_categories.controls[i].get('category').touched && sanitary_sub_categories.controls[i].get('category').invalid}"
                                                        class="invalid-feedback font-medium">Please select product</label>
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-center pt-4">
                                                <button type="button" nbButton status="success"
                                                    (click)="addSanitarySubProduct()"
                                                    class="btn btn-success"
                                                    [hidden]="!(sanitary_sub_categories.length==(i+1))">+</button>
                                                <button type="button" nbButton status="danger"
                                                    (click)="removeSanitarySubProduct(i)"
                                                    class="btn btn-danger"
                                                    [hidden]="sanitary_sub_categories.length==(i+1)">-</button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 3">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="various_categories" class="label">Primary Products</label>
                                        <ng-container *ngIf="various_category.length">
                                            <ng-select [items]="various_category"
                                                        bindLabel="category"
                                                        placeholder="Select product"
                                                        appendTo="body"
                                                        [multiple]="true"
                                                        [ngModelOptions]="{standalone: true}"
                                                        formControlName="various_categories">
                                            </ng-select>
                                        </ng-container>
                                        <label
                                            [ngClass]="{'is-invalid':companyForm.get('various_categories').touched && companyForm.get('various_categories').invalid}"
                                            class="invalid-feedback font-medium">Please select products</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 4 || data?.type == 5">
                                <div class="col-md-12">
                                    <div class="row" formArrayName="trader_dealer_categories" *ngFor="let c of trader_dealer_categories.controls; let i=index">
                                        <ng-container [formGroupName]="i">
                                            <div class="col-md-5">
                                                <div class="form-group">
                                                    <label for="category-{{i}}" class="label">Category</label>
                                                    <select id="category-{{i}}" class="mt-0" formControlName="category" (change)="onChangeTraderDealerCategory($event, i)">
                                                        <option value="" disabled hidden>Select category</option>
                                                        <option *ngFor="let category of trader_dealer_category" [value]="category.id">{{category.category}}</option>
                                                    </select>
                                                    <label [ngClass]="{'is-invalid':trader_dealer_categories.controls[i].get('category').touched && trader_dealer_categories.controls[i].get('category').invalid}" class="invalid-feedback font-medium">Please select category</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="sub-category-{{i}}" class="label">Sub Category</label>
                                                    <ng-select [items]="trader_dealer_categories.controls[i].value.subcategoryList"
                                                            bindLabel="sub_category"
                                                            placeholder="Select subcategory"
                                                            appendTo="body"
                                                            [multiple]="true"
                                                            [ngModelOptions]="{standalone: true}"
                                                            formControlName="subcategory">
                                                    </ng-select>
                                                    <label [ngClass]="{'is-invalid':trader_dealer_categories.controls[i].get('subcategory').touched && trader_dealer_categories.controls[i].get('subcategory').invalid}"
                                                        class="invalid-feedback font-medium">Please select sub
                                                        category</label>
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-right pt-3">
                                                <br>
                                                <button *ngIf="!isView" type="button" nbButton status="success"
                                                    (click)="addTraderDealerCategories()"
                                                    class="btn btn-success"
                                                    [hidden]="!(trader_dealer_categories.length==(i+1))">+</button>
                                                <button *ngIf="!isView" type="button" nbButton status="danger"
                                                     class="btn btn-danger"
                                                    (click)="removeTraderDealerCategories(i)"
                                                    [hidden]="trader_dealer_categories.length==(i+1)">-</button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 6">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="transporter_service" class="c_service">Service Type</label>
                                        <select class="mt-0" (change)="onChangeTransporterServiceType($event)" formControlName="transporter_service" id="transporter_service">
                                            <option value="" disabled hidden>Select type</option>
                                            <option *ngFor="let service of transporter_service" [value]="service.id">{{service.category}}</option>
                                        </select>
                                        <label [ngClass]="{'is-invalid':companyForm.get('transporter_service').touched && companyForm.get('transporter_service').invalid}"
                                            class="invalid-feedback font-medium">Please select type</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="transporter_area" class="transporter_area">Service Area</label>
                                        <ng-select [items]="transporter_area"
                                                bindLabel="name"
                                                placeholder="Select area"
                                                appendTo="body"
                                                [multiple]="true"
                                                [ngModelOptions]="{standalone: true}"
                                                formControlName="transporter_area">
                                        </ng-select>
                                        <label [ngClass]="{'is-invalid':companyForm.get('transporter_area').touched && companyForm.get('transporter_area').invalid}"
                                            class="invalid-feedback font-medium">Please select area</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 7">
                                <div class="col-md-12">
                                    <div class="row" formArrayName="supplier_categories" *ngFor="let c of supplier_categories.controls; let i=index">
										<ng-container [formGroupName]="i">
											<div class="col-md-5">
												<div class="form-group">
													<label for="category-{{i}}" class="label">Category</label>
													<select class="mt-0" (change)="onChangeSupplierCategory($event,i)" placeholder="Select category"  id="-supplier-category-{{i}}" formControlName="category" >
                                                        <option value="" disabled hidden>Select category</option>
														<option *ngFor='let cat of supplier_category' [value]="cat.id">{{cat.category}}</option>
													</select>
													<label [ngClass]="{'is-invalid':supplier_categories.controls[i].get('category').touched && supplier_categories.controls[i].get('category').invalid}" class="invalid-feedback font-medium">Please select category</label>
												</div>
											</div>
											<div class="col-md-5">
												<div class="form-group">
													<label for="supplier-sub-category-{{i}}" class="label">Sub Categoty</label>
													<ng-select [items]="supplier_categories.controls[i].value.subcategoryList"
                                                            bindLabel="sub_category"
                                                            placeholder="Select sub category"
                                                            appendTo="body"
                                                            [multiple]="true"
                                                            formControlName="subcategory">
                                                    </ng-select>
													<label [ngClass]="{'is-invalid':supplier_categories.controls[i].get('category').touched && supplier_categories.controls[i].get('category').invalid}" class="invalid-feedback font-medium">Please select sub category</label>
												</div>
											</div>
											<div class="col-md-2 text-right pt-4">
												<br>
												<button type="button" status="success"
													(click)="addSupplierCategories()"
                                                    class="btn btn-success"
													[hidden]="!(supplier_categories.length==(i+1))">+</button>
												<button type="button" status="danger"
													(click)="removeSupplierCategories(i)"
                                                    class="btn btn-danger"
													[hidden]="supplier_categories.length==(i+1)">-</button>
											</div>
										</ng-container>
									</div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 8">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <ng-container *ngIf="hotel_amenities.length">
                                            <label for="amenities" class="label">Amenities</label>
                                            <ng-select [items]="hotel_amenities"
                                                    bindLabel="amenitie"
                                                    placeholder="Select amenitie(s)"
                                                    appendTo="body"
                                                    [multiple]="true"
                                                    formControlName="hotel_amenities">
                                            </ng-select>
                                        </ng-container>
                                        <label [ngClass]="{'is-invalid':companyForm.get('hotel_amenities').touched && companyForm.get('hotel_amenities').invalid}" class="invalid-feedback font-medium">Please select amenities</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <p class="mt-3"><b>Rooms</b></p>
                                </div>
                                <div class="col-md-12">
                                    <div class="row align-items-center" formArrayName="hotel_rooms" *ngFor="let room of hotel_rooms.controls; let i=index">
										<ng-container [formGroupName]="i">
											<div class="col-md-7">
												<div class="form-group">
													<label for="room_name" class="label">Name</label>
													<input type="text" id="room_name" class="mt-0" placeholder="Enter room name" formControlName="room_name">
													<label [ngClass]="{'is-invalid':hotel_rooms.controls[i].get('room_name').touched && hotel_rooms.controls[i].get('room_name').invalid}" class="invalid-feedback font-medium">Please enter room name</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label for="price" class="label">Price</label>
													<input type="number" class="mt-0" step="0.00" id="price" placeholder="Enter room price" formControlName="price">
													<div *ngIf="hotel_rooms.controls[i].get('price').touched && hotel_rooms.controls[i].get('price').invalid">
														<label class="invalid-feedback font-medium is-invalid"  *ngIf="hotel_rooms.controls[i].get('price').errors && hotel_rooms.controls[i].get('price').hasError('required')">Please enter price</label>
														<label class="invalid-feedback font-medium is-invalid"  *ngIf="hotel_rooms.controls[i].get('price').errors && hotel_rooms.controls[i].get('price').hasError('pattern')">Invalid price</label>
													</div>
												</div>
											</div>
											<div class="col-md-1 text-right pt-4">
												<button type="button" status="success"
													(click)="addHotelRoom()"
                                                    class="btn btn-success"
													[hidden]="!(hotel_rooms.length==(i+1))">+</button>
												<button type="button" status="danger"
													(click)="removeHotelRoom(i)"
                                                    class="btn btn-danger"
													[hidden]="hotel_rooms.length==(i+1)">-</button>
											</div>
										</ng-container>
									</div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 9">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <ng-container *ngIf="serviceprovider_service.length">
                                            <label for="c_services" class="label">Services</label>
                                            <ng-select [items]="serviceprovider_service"
                                                    bindLabel="service"
                                                    placeholder="Select Services(s)"
                                                    [multiple]="true"
                                                    formControlName="serviceprovider_service">
                                            </ng-select>
                                        </ng-container>

                                        <label [ngClass]="{'is-invalid':companyForm.get('serviceprovider_service').touched && companyForm.get('serviceprovider_service').invalid}" class="invalid-feedback font-medium">Please select service</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data?.type == 10">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <ng-container *ngIf="cab_services_list.length">
                                            <label for="cab_service" class="label">Services</label>
                                            <ng-select [items]="cab_services_list"
                                                    bindLabel="service"
                                                    placeholder="Select Services(s)"
                                                    appendTo="body"
                                                    [multiple]="true"
                                                    formControlName="cab_service">
                                            </ng-select>
                                        </ng-container>
                                        <!-- <label for="cab_service" class="label">Services</label>
                                        <select multiple class="mt-0" id="cab_service" formControlName="cab_service">
                                            <option value="" disabled hidden>Select Services</option>
                                            <option *ngFor="let service of cab_services" [value]="service.id">{{service.service}}</option>
                                        </select> -->
                                        <label [ngClass]="{'is-invalid':companyForm.get('cab_service').touched && companyForm.get('cab_service').invalid}" class="invalid-feedback font-medium">Please select service</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <ng-container *ngIf="cab_cars_list.length">
                                            <label for="cab_cars" class="label">Cars</label>
                                            <ng-select [items]="cab_cars_list"
                                                    bindLabel="car"
                                                    placeholder="Select car(s)"
                                                    appendTo="body"
                                                    [multiple]="true"
                                                    formControlName="cab_cars">
                                            </ng-select>
                                        </ng-container>
                                        <!-- <label for="cab_cars" class="label">Cars</label>
                                        <select multiple class="mt-0" fullWidth id="cab_cars" formControlName="cab_cars">
                                            <option value="" disabled hidden>Select Cars</option>
                                            <option *ngFor="let cab of cab_cars" [value]="cab.id">{{cab.car}}</option>
                                        </select> -->
                                        <label [ngClass]="{'is-invalid':companyForm.get('cab_cars').touched && companyForm.get('cab_cars').invalid}" class="invalid-feedback font-medium">Please select car</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <br>
                                    <input type="submit" name="" value="Save">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>