import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-reference',
  templateUrl: './find-reference.component.html',
  styleUrls: ['./find-reference.component.css']
})
export class FindReferenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
