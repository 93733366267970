import { FormValidatorServiceService } from './../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

	otpIndex1:string = '';
	otpIndex2:string = '';
	otpIndex3:string = '';
	otpIndex4:string = '';

	constructor(
		private router: Router,
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder
	) {}
	
	ngOnInit(): void {
	}

	onClickverifyOtp():void{
		let otp = this.otpIndex1.toString()+this.otpIndex2.toString()+this.otpIndex3.toString()+this.otpIndex4.toString();
		if(otp.length == 4){
			let formData = new FormData();
			formData.append('otp', otp);
			formData.append('id', this.userService.getUserId());
			this.userService.verify_otp(formData).subscribe(response => {
				if(response.success){
					this.userService.sucessMessage(response.message);
					this.router.navigate(['/reset-password'])
				} else {
					this.userService.errorMessage(response.message);
				}
			});
		}else{
			this.userService.errorMessage('Invalid OTP');
		}
	}
}
