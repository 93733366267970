import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { FormValidatorServiceService } from '../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

//import { HomeService } from '../../../services/home.service';
//import { UserService } from '../../../services/user.service';
import { SellerPostService } from '../../../services/seller-post.service';


@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	
	sellerPostList: Array<any> = [];
	buyerPostList: Array<any> = [];
	extraseller:any = [];
	data:any = [];
	dir:any = []; 

	form:FormGroup;
	constructor(
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder,
		private sellerPostService: SellerPostService
	) { }

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			email : ['', [Validators.required, Validators.email]],
		});



	

		let params = {};
		if(history.state.filter && history.state.product_type){
			params = { ...params, ...{filter : history.state.filter}};
			params = { ...params, ...{product_type : history.state.product_type}};
		}

		this.sellerPostService.getPostList(params).subscribe(resposnse => {
			if(resposnse.success){
		
				this.extraseller = resposnse.extra;
			}else{
				
				this.userService.errorMessage(resposnse.message);
			}
		});


	}


	
	onSubmit():void{
		if (this.form.valid) {
			this.userService.newsletter(this.form.value).subscribe(response => {
				if(response.success){
					this.userService.sucessMessage(response.message) 
					this.form.reset();
				}else{
					this.userService.errorMessage(response.message)
				}
			})
		}else {
			this.formValidationService.validateAllFormFields(this.form);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	
}
