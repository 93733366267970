import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { TilesManufactureService } from '../../../services/tiles-manufacture.service';
import { SanitaryService } from '../../../services/sanitary.service';
import { VariousService } from '../../../services/various.service';
import { SupplierService } from '../../../services/supplier.service';
import { ServiceProviderService } from '../../../services/service-provider.service';
import { CabService } from '../../../services/cab.service';
import { TransporterService } from '../../../services/transporter.service';
import { HotelService } from '../../../services/hotel.service';
import { TraderService } from '../../../services/trader.service';
import { DealerService } from '../../../services/dealer.service';
import { FollowService } from '../../../services/follow.service';
import { InquiryService } from '../../../services/inquiry.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { ActivatedRoute } from '@angular/router';


import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-company-details',
	templateUrl: './company-details.component.html',
	styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {

	comapanyId: number = 0;
	type: number = 0;
	data: any = [];
	is_buttons_enable = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private tilesManufactureService: TilesManufactureService,
		private sanitaryManufactureService: SanitaryService,
		private variousManufactureService: VariousService,
		private supplierService: SupplierService,
		private serviceProviderService: ServiceProviderService,
		private cabService: CabService,
		private transporterService: TransporterService,
		private hotelService: HotelService,
		private traderService: TraderService,
		private dealerService: DealerService,
		private followService: FollowService,
		private inquiryService: InquiryService,
		private analyticsService: AnalyticsService,
		private titleService:Title,
		private metaService: Meta,
	) { }

	ngOnInit(): void {


		
		let params: any = this.route.snapshot.params;
		//if(params.id) 
		//console.log(history.state);
		this.route.queryParams.subscribe(params => {
			//console.log(params,"DEEEMOOMOEMOEME");
	      	if(params.id && params.type) {
	      		history.state.id  = params.id;
	      		history.state.type = params.type;		 
	      	}
	      }
	    );

		//added to handle external parameters
          if(params.id){
			history.state.id  = params.id;
			history.state.type = params.type;		 
		  }

		if(history.state.id && history.state.type){
			this.comapanyId = history.state.id;
			this.type = history.state.type;
			//console.log(history.state);
			let temp_access_role = this.userService.getAccessRole();
			if(temp_access_role?.is_access_role_available == '1'){
				temp_access_role = JSON.parse(temp_access_role.access_role);
				if(this.type == 1){
					this.is_buttons_enable = temp_access_role?.tiles_manucature ? true : false;
				}else if(this.type == 2){
					this.is_buttons_enable = temp_access_role?.sanitary_manucature ? true : false;
				}else if(this.type == 3){
					this.is_buttons_enable = temp_access_role?.various_manucature ? true : false;
				}else if(this.type == 4){
					this.is_buttons_enable = temp_access_role?.trader ? true : false;
				}else if(this.type == 5){
					this.is_buttons_enable = temp_access_role?.dealer_india ? true : false;
				}else if(this.type == 6){
					this.is_buttons_enable = temp_access_role?.transporter ? true : false;
				}else if(this.type == 7){
					this.is_buttons_enable = temp_access_role?.supplier ? true : false;
				}else if(this.type == 8){
					this.is_buttons_enable = temp_access_role?.hotel ? true : false;
				}else if(this.type == 9){
					this.is_buttons_enable = temp_access_role?.service_provider ? true : false;
				}else if(this.type == 10){
					this.is_buttons_enable = temp_access_role?.cab ? true : false;
				}
			}
			


			if(this.type == 1){
				this.tilesManufactureService.getDetails({ comapny_id: this.comapanyId, category_id: history?.state.cp_id }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 2){
				this.sanitaryManufactureService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 3){
				this.variousManufactureService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 4){
				this.traderService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 5){
				this.dealerService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 6){
				this.transporterService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 7){
				this.supplierService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 8){
				this.hotelService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 9){
				this.serviceProviderService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else if(this.type == 10){
				this.cabService.getDetails({ comapny_id: this.comapanyId }).subscribe(response => {
					this.response(response);
				})
			}else{
				this.router.navigate(['/']);
			}
			this.analytics(9)
		}else{
			this.router.navigate(['/']);
		}
		if(history.state.inquiry_id){
			this.inquiryService.readInquiry({inquiry_id:history.state.inquiry_id}).subscribe();
		}
	}
	followUnfollow(status):void{
		
		this.followService.followUnfolllowCompany({company_id : this.comapanyId, status: status}).subscribe(response => {
			if(response.success){
				this.userService.sucessMessage(response.message)
				this.ngOnInit();
			}else{
				this.userService.errorMessage(response.message)
			}
		})
	}
	response(response):void{
	//	console.log(response);
		if (response.success) {
			this.data = response.data;	
          //   console.log(response.data);
			 this.setTitle(this.data.name);

			setTimeout(() => {  
				
			//	this.metaService.addTags([{name: 'description', content: this.data.product_description}]);
			this.metaService.removeTag('name = "description"'); 
				this.metaService.addTags([{name: 'description', content: this.data.product_description}]);


				this.setTitle(this.data.name);
			  }, 1000);  
			  
			if(this.type == 5){
				let temp_access_role = this.userService.getAccessRole();
				if(temp_access_role?.is_access_role_available == '1'){
					temp_access_role = JSON.parse(temp_access_role.access_role);
					if(this.data.is_indian){
						this.is_buttons_enable = temp_access_role?.dealer_india ? true : false;
					}else{
						this.is_buttons_enable = temp_access_role?.dealer_other ? true : false;
					}
					
				}
			}
		} else {
			this.userService.errorMessage(response.message)
		}
	}

	public setTitle(newTitle: string) {
		this.titleService.setTitle(newTitle);
	  }

	Inquiry():void{
		let user = this.userService.getUser();
		let company_id = user.company_id ? user.company_id : 0; 
		this.inquiryService.save({from_company_id: company_id, to_company_id: this.comapanyId}).subscribe(res => {
			if(res.success){
				this.userService.sucessMessage(res.message);
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}
	analytics(type):void{
		this.analyticsService.saveCount({id: this.comapanyId, type : type}).subscribe();
	}

}
