import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError, Observable, Subject } from 'rxjs';
import { apiConstants } from '../constants/api-constants';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APIResponse } from '../Modal/apiresponse';
import { catchError } from 'rxjs/operators';
import { HttpParams } from "@angular/common/http";


@Injectable({
	providedIn: 'root'
})
export class HomeService {
	constructor(private http: HttpClient) { }

	getHome(): Observable<APIResponse> {
		return this.http.get(apiConstants.home).pipe(catchError(this.handleError));
	}

	getCompanyList(): Observable<APIResponse> {
		return this.http.get(apiConstants.getCompanyList).pipe(catchError(this.handleError));
	}
	
	
	getCompanyListAll(id:number): Observable<APIResponse> {
		const opts = { params: new HttpParams({fromString: "id=" + id}) };

		return this.http.get(apiConstants.getCompanyListall,opts).pipe(catchError(this.handleError));
	}


	updateStatus(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.updateStatus, form).pipe(catchError(this.handleError));
	}

	statusList(limit:number): Observable<APIResponse> {
		
		const opts = { params: new HttpParams({fromString: "limit=" + limit}) };
		return this.http.get(apiConstants.statusList,opts).pipe(catchError(this.handleError));
	}
	statusListById(id:number): Observable<APIResponse> {
		
		const opts = { params: new HttpParams({fromString: "id=" + id}) };
		return this.http.get(apiConstants.statusListById,opts).pipe(catchError(this.handleError));
	}


	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		return throwError('Something bad happened; please try again later.');
	}
}
