import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UserService } from '../../../services/user.service';
import { HotelService } from '../../../services/hotel.service';
import { InquiryService } from '../../../services/inquiry.service';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
	selector: 'app-hotel',
	templateUrl: './hotel.component.html',
	styleUrls: ['./hotel.component.css']
})
export class HotelComponent implements OnInit {
	premium: any = [];
	statndard: any = [];
	classic: any = [];
	general: any = [];

	price: any = [];
	zones: any = [];

	selectedprice: any = '';
	selectedZone: any = '';

	totalPages: number = 0;
	nextPage: number = 0;
	is_buttons_enable:boolean = false;
	hotelIdList = [];

	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		autoplay: true,
		autoplayTimeout: 2000,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		autoplaySpeed: 1500,
		navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 1
			},
			740: {
				items: 1
			},
			940: {
				items: 1
			}
		},
		nav: true
	}
	constructor(
		private router: Router,
		private hotelService: HotelService,
		private userService: UserService,
		private inquiryService: InquiryService,
		private analyticsService: AnalyticsService,
	) { }

	ngOnInit(): void {
		this.setAccessRole();
		this.userService.accessRoleListener.subscribe((response) => {
			this.setAccessRole();
		});
		this.hotelService.getList({list:[]}).subscribe(response => {
			this.repsonse(response);
		})
	}
	setAccessRole():void{
		let temp_access_role = this.userService.getAccessRole();
		if(temp_access_role?.is_access_role_available == '1'){
			temp_access_role = JSON.parse(temp_access_role.access_role);
			this.is_buttons_enable = temp_access_role?.hotel ? true : false;
		}
	}
	repsonse(response, flag: boolean = true): void {
		if (response.success) {
			this.premium = response.data.premium;
			this.statndard = response.data.statndard;
			this.classic = response.data.classic;
			this.general = response.data.general;
			if (flag) {
				this.price = response.extra.pricing;
				this.zones = response.extra.zones;
			}
			this.totalPages = response.extra.total_pages;
			this.nextPage = response.extra.next_page;
		} else {
			this.userService.errorMessage(response.message);
		}
	}
	onChangeService(id): void {
		this.selectedprice = id;
	}
	onChangeZone(id): void {
		this.selectedZone = id;
	}
	
	filterOption(): void {
		let param = {};
		if (this.selectedprice != '') {
			param = { ...param, ...{ price_id: this.selectedprice } }
		}
		if (this.selectedZone != '') {
			param = { ...param, ...{ zone_id: this.selectedZone } }
		}
		param = { ...param, ...{ list: [] } }
		this.hotelService.getList(param).subscribe(response => {
			this.repsonse(response, false);
		})
	}
	moreCompanies(): void {
		let param = { next_page: this.nextPage };
		if (this.selectedprice != '') {
			param = { ...param, ...{ price_id: this.selectedprice } }
		}
		if (this.selectedZone != '') {
			param = { ...param, ...{ zone_id: this.selectedZone } }
		}
		this.hotelIdList = [];
		this.general.forEach(element=>{
			this.hotelIdList.push(element.id);
		});
		param = { ...param, ...{ list: this.hotelIdList } }
		this.hotelService.getMore(param).subscribe(response => {
			if (response.success) {
				this.totalPages = response.extra.total_pages;
				this.nextPage = response.extra.next_page;
				response.data.forEach(element => {
					this.general.push(element)
				});
			} else {
				this.userService.errorMessage(response.message);
			}
		})
	}
	Inquiry(id):void{
		if(this.is_buttons_enable){
			let user = this.userService.getUser();
			let company_id = user.company_id ? user.company_id : 0; 
			this.inquiryService.save({from_company_id: company_id, to_company_id: id}).subscribe(res => {
				if(res.success){
					this.userService.sucessMessage(res.message);
				}else{
					this.userService.errorMessage(res.message);
				}
			})
		}
	}
	analytics(id, type):void{
		this.analyticsService.saveCount({id: id, type : type}).subscribe();
	}
}
