<div class="container top-menu">
	<div class="row top-row">
		<div class="col-md-2 logo-center">
			<a routerLink="home"> <img src="assets/img/logo.png"></a>
		</div>
		<div class="col-md-4 col-lg-2"></div>
		<div class="col-md-6 col-lg-8">
			<div class="top-btn">
				<a href="javascript:void(0)" routerLink="seller-post" class="sel-btn"> SELLER POST <i
						class="fa fa-edit"></i></a>
				<a href="javascript:void(0)" routerLink="buyer-post" class="sel-btn"> Buyer POST <i
						class="fa fa-edit"></i></a>
				<a href="javascript:void(0)" class="contact-btn"> <i class="fa fa-phone"></i> +91 909 908 907 1</a>
			</div>
		</div>
	</div>
</div>