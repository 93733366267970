import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormValidatorServiceService } from './../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UserService } from './../../../services/user.service';
//import { timingSafeEqual } from 'crypto';

@Component({
	selector: 'app-signup-form',
	templateUrl: './signup-form.component.html',
	styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent implements OnInit {
	loginForm: FormGroup;
	loginFormMobile: FormGroup;
	registerForm: FormGroup;
	isLogin: boolean = false;
	isMobile: boolean = false;
	countries:any = [];
	states:any = [];
	districts:any = [];
	roles:any = [];

	verifyOtp:boolean = false; 

	otpIndex1:string = '';
	otpIndex2:string = '';
	otpIndex3:string = '';
	otpIndex4:string = '';

	constructor(
		private router: Router,
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder
	) {}
	
	ngOnInit(): void {
		const url: string = this.router.url;
		this.isLogin = url.includes('login') ? true : false;

		if(!this.isLogin){
			this.userService.getRegisterFields().subscribe(response => {
				if(response.success){
					this.roles = response.data.roles;
					this.countries = response.data.countries;
				}else{
					this.userService.errorMessage(response.message);
				}
			});
		}

		this.loginForm = this.formBuilder.group({
			email		: ['', [Validators.required]],
			password 	: ['', [Validators.required]],
		});

		this.loginFormMobile = this.formBuilder.group({
			mobile		: ['', [Validators.required]],
			password 	: ['', [Validators.required]],
		});

		this.registerForm = this.formBuilder.group({
			role		: ['', [Validators.required]],
			firm_name 	: ['', [Validators.required]],
			whats_app 	: ['', [Validators.required]],
			mobile 		: ['', [Validators.required]],
			email 		: ['', [Validators.required]],
			country 	: ['', [Validators.required]],
			state 		: ['', [Validators.required]],
			district 	: ['', [Validators.required]],
			city 		: ['', [Validators.required]],
		});
	}
	onChangeCountry(id):void{
		const country = this.countries.find((c) => {
			return c.id == id;
		});
		this.states = country.state;
		this.districts = [];
	}
	onChangeState(id):void{
		const state = this.states.find((c) => {
			return c.id == id;
		});
		this.districts = state.districts;
	}
	closeOtpWindow():void{
		this.verifyOtp = false;
	}
	onClickverifyOtp():void{
		let otp = this.otpIndex1.toString()+this.otpIndex2.toString()+this.otpIndex3.toString()+this.otpIndex4.toString();
		if(otp.length == 4){
			let formData = new FormData();
			formData.append('id', this.userService.getId());
			formData.append('otp', otp);
			this.userService.verifyOtp(formData).subscribe(response => {
				if(response.success){
					this.userService.setRegister();
					this.verifyOtp = false;
					this.userService.sucessMessage(response.message);
					this.router.navigate(['/'])
				}else{
					this.userService.errorMessage(response.message);
				}
			});
		}else{
			this.userService.errorMessage('Invalid OTP');
		}
	}

	onChangeToMobile(): void {
     this.isMobile=true;
	}

	onChangeToEmail(): void {
     this.isMobile=false;
	}


	onSubmit(): void {
		console.log("submit trigger")
		if (this.loginForm.valid) {
			let fcmToken = this.userService.getFcmToken();
			let logindata = this.loginForm.value;
			logindata = { ...logindata, ...{ fcm_token_web: fcmToken } }
			this.userService.login(logindata).subscribe(response => {
				if(response.success){
					this.userService.setAuthData(
							response.data.id,
							response.data.token,
							response.data.name,
							response.data.profile,
							response.data.company_id,
							response.data.company_type,
							response.data.designation,
							response.data.company_name,
							response.data.company_city,
							response.data.company_contact,
							response.data.is_access_role_available,
							response.data.access_role,
							response.data.digital_card ? response.data.digital_card : ''
					);
					this.userService.setAuthStatusListener();
					this.router.navigate(['/'])
				}else{
					this.userService.errorMessage(response.message);
				}
			});
		}
		else {
			this.formValidationService.validateAllFormFields(this.loginForm);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}


	
	onSubmitMobile(): void {
		if (this.loginFormMobile.valid) {
			let fcmToken = this.userService.getFcmToken();
			let logindata = this.loginFormMobile.value;
			logindata = { ...logindata, ...{ fcm_token_web: fcmToken } }
			this.userService.loginMobile(logindata).subscribe(response => {
				if(response.success){
					this.userService.setAuthData(
							response.data.id,
							response.data.token,
							response.data.name,
							response.data.profile,
							response.data.company_id,
							response.data.company_type,
							response.data.designation,
							response.data.company_name,
							response.data.company_city,
							response.data.company_contact,
							response.data.is_access_role_available,
							response.data.access_role,
							response.data.digital_card ? response.data.digital_card : ''
					);
					this.userService.setAuthStatusListener();
					this.router.navigate(['/'])
				}else{
					this.userService.errorMessage(response.message);
				}
			});
		}
		else {
			this.formValidationService.validateAllFormFields(this.loginFormMobile);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}


	onSubmitRegister():void{
		if (this.registerForm.valid) {
			this.userService.register(this.registerForm.value).subscribe(response => {
				if(response.success){
					this.verifyOtp = true;
					this.userService.setId(response.data.id);
					this.userService.sucessMessage(response.message);
				}else{
					this.userService.errorMessage(response.message);
				}
			});
		}
		else {
			this.formValidationService.validateAllFormFields(this.registerForm);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
}
