<main>
    <app-breadcrumb [pageName]="'Packing Details'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Packing Details</h1>
                        <img src="assets/img/line.svg" width="120">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-2 pr-3">
                    &nbsp;&nbsp;<input type="radio" name="type" value="1" (change)="typeChnage('1')" checked> &nbsp;&nbsp;Domestic 
                </div>
                <div class="col-md-2">
                    <input type="radio" name="type" value="2" (change)="typeChnage('2')">&nbsp;&nbsp; Export
                </div>
            </div>
            <div class="row box-h mt-30" *ngIf="type == 1">
                <div class="col-md-12">
                    <div class="details spects-table">
                        <div id="wallTiles">
                            <h2 class="techSpecTitle"><span>Ceramic Wall tiles packing Details</span></h2>
                            <table class="tsTable">
                                <tbody>
                                    <tr class="subHead">
                                        <th>Size (in inch)</th>
                                        <th>Size (in mm)</th>
                                        <th>Area coverage per box (sq.ft)</th>
                                        <th>Packing per box</th>
                                        <th>Thickness mm(approx)</th>
                                        <th>Weight per box</th>
                                    </tr>
                                    <tr>
                                        <td>12x24</td>
                                        <td>300 x 600</td>
                                        <td>10</td>
                                        <td>5 pcs</td>
                                        <td>9</td>
                                        <td>14</td>
                                    </tr>
                                    <tr>
                                        <td>12x18</td>
                                        <td>300 x 450</td>
                                        <td>9</td>
                                        <td>6 pcs</td>
                                        <td>8</td>
                                        <td>11</td>
                                    </tr>
                                    <tr>
                                        <td>12x12</td>
                                        <td>300 x 300</td>
                                        <td>8</td>
                                        <td>9 pcs</td>
                                        <td>8</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td>10x15</td>
                                        <td>250 x 375</td>
                                        <td >8.33</td>
                                        <td>8 pcs</td>
                                        <td>6.8</td>
                                        <td>8.5</td>
                                    </tr>
                                    <tr>
                                        <td>8 x 12</td>
                                        <td>200 x 300</td>
                                        <td>8</td>
                                        <td>12pcs</td>
                                        <td>6</td>
                                        <td>7</td>
                                    </tr>
                                    <tr>
                                        <td>8 X 24</td>
                                        <td>200 x 600</td>
                                        <td>8</td>
                                        <td>6 PCS</td>
                                        <td>9</td>
                                        <td>10.3</td>
                                    </tr>
                                    <tr>
                                        <td>10 x 30</td>
                                        <td>250 x 750</td>
                                        <td>10.4</td>
                                        <td>5 PCS</td>
                                        <td>9</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td>12 x36</td>
                                        <td>300 x 900</td>
                                        <td>12</td>
                                        <td>4 PCS</td>
                                        <td>11</td>
                                        <td>19</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-30" *ngIf="type == 1">
                <div class="col-md-12">
                    <div class="details spects-table">
                        <div id="wallTiles">
                            <h2 class="techSpecTitle"><span>Ceramic Floor tiles packing Details</span></h2>
                            <table class="tsTable">
                                <tbody>
                                    <tr class="subHead">
                                        <th>Size (in inch)</th>
                                        <th>Size (in mm)</th>
                                        <th>Area coverage per box (sq.ft)</th>
                                        <th>Packing per box</th>
                                        <th>Thickness mm(approx)</th>
                                        <th>Weight per box</th>
                                    </tr>
                                    <tr>
                                        <td >12 x 12</td>
                                        <td >300x 300</td>
                                        <td>10</td>
                                        <td>10 pcs</td>
                                        <td>7</td>
                                        <td>12.3</td>
                                    </tr>
                                    <tr>
                                        <td >16 x16</td>
                                        <td >400 x 400</td>
                                        <td>8.4</td>
                                        <td>5 pcs</td>
                                        <td>8</td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td >24 x 24</td>
                                        <td >600 x600</td>
                                        <td>15.5</td>
                                        <td >4 pcs</td>
                                        <td>9</td>
                                        <td>28</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-30" *ngIf="type == 1">
                <div class="col-md-12">
                    <div class="details spects-table">
                        <div id="wallTiles">
                            <h2 class="techSpecTitle"><span>Double charge Vitrified tiles packing Details</span></h2>
                            <table class="tsTable">
                                <tbody>
                                    <tr class="subHead">
                                        <th>Size (in inch)</th>
                                        <th>Size (in mm)</th>
                                        <th>Area coverage per box (sq.ft)</th>
                                        <th>Packing per box</th>
                                        <th>Thickness mm(approx)</th>
                                        <th>Weight per box</th>
                                    </tr>
                                    <tr>
                                        <td>24 x 24</td>
                                        <td>600 x 600</td>
                                        <td>15.5</td>
                                        <td>4 pcs</td>
                                        <td>9</td>
                                        <td>27</td>
                                    </tr>
                                    <tr>
                                        <td>32 x32</td>
                                        <td>800 x 800</td>
                                        <td>20.67</td>
                                        <td>3 PCS</td>
                                        <td>9.8</td>
                                        <td>40</td>
                                    </tr>
                                    <tr>
                                        <td>24 x48</td>
                                        <td>600 x 1200</td>
                                        <td>15.5</td>
                                        <td>2 pcs</td>
                                        <td>8.5</td>
                                        <td>30</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-30" *ngIf="type == 1">
                <div class="col-md-12">
                    <div class="details spects-table">
                        <div id="wallTiles">
                            <h2 class="techSpecTitle"><span>Soluble Nano Vitrified tiles packing Details</span></h2>
                            <table class="tsTable">
                                <tbody>
                                    <tr class="subHead">
                                        <th>Size (in inch)</th>
                                        <th>Size (in mm)</th>
                                        <th>Area coverage per box (sq.ft)</th>
                                        <th>Packing per box</th>
                                        <th>Thickness mm(approx)</th>
                                        <th>Weight per box</th>
                                    </tr>
                                    <tr>
                                        <td>24 x24</td>
                                        <td>600 x 600</td>
                                        <td>15.5</td>
                                        <td>4 pcs</td>
                                        <td>9</td>
                                        <td>25</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-30" *ngIf="type == 1">
                <div class="col-md-12">
                    <div class="details spects-table">
                        <div id="wallTiles">
                            <h2 class="techSpecTitle"><span>Glaze Porcelain Vitrified tiles packing Details</span></h2>
                            <table class="tsTable">
                                <tbody>
                                    <tr class="subHead">
                                        <th>Size (in inch)</th>
                                        <th>Size (in mm)</th>
                                        <th>Area coverage per box (sq.ft)</th>
                                        <th>Packing per box</th>
                                        <th>Thickness mm(approx)</th>
                                        <th>Weight per box</th>
                                    </tr>
                                    <tr>
                                        <td>24 x24</td>
                                        <td>600 x 600</td>
                                        <td>15.5</td>
                                        <td>4 pcs</td>
                                        <td>9</td>
                                        <td>28</td>
                                    </tr>
                                    <tr>
                                        <td>24 x48</td>
                                        <td>600 x 1200</td>
                                        <td>15.5</td>
                                        <td>2 PCS</td>
                                        <td>9</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <td>32 x32</td>
                                        <td>800 x 800</td>
                                        <td>20.67</td>
                                        <td>3 PCS</td>
                                        <td>9</td>
                                        <td>42</td>
                                    </tr>
                                    <tr>
                                        <td>32 x64</td>
                                        <td>800 x1600</td>
                                        <td>28.44</td>
                                        <td>2 PCS</td>
                                        <td>9</td>
                                        <td>53</td>
                                    </tr>
                                    <tr>
                                        <td>48 x 96</td>
                                        <td>1200 x 2400</td>
                                        <td>32</td>
                                        <td>1 PCS</td>
                                        <td>9</td>
                                        <td>61</td>
                                    </tr>
                                    <tr>
                                        <td>36 x 72</td>
                                        <td>900 x1800</td>
                                        <td>36</td>
                                        <td>2 PCS</td>
                                        <td>9</td>
                                        <td>65</td>
                                    </tr>
                                    <tr>
                                        <td>48 x 48</td>
                                        <td>1200 x1200</td>
                                        <td>32</td>
                                        <td>2 PCS</td>
                                        <td>9</td>
                                        <td>61</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-30" *ngIf="type == 1">
                <div class="col-md-12">
                    <div class="details spects-table">
                        <div id="wallTiles">
                            <h2 class="techSpecTitle"><span>Outdoor Parking tiles packing Details</span></h2>
                            <table class="tsTable">
                                <tbody>
                                    <tr class="subHead">
                                        <th>Size (in inch)</th>
                                        <th>Size (in mm)</th>
                                        <th>Area coverage per box (sq.ft)</th>
                                        <th>Packing per box</th>
                                        <th>Thickness mm(approx)</th>
                                        <th>Weight per box</th>
                                    </tr>
                                    <tr>
                                        <td>12 x12</td>
                                        <td>300  x300</td>
                                        <td>8</td>
                                        <td>8 PCS</td>
                                        <td>8</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td>16 x16</td>
                                        <td>400 x 400</td>
                                        <td>8.4</td>
                                        <td>5 PCS</td>
                                        <td>12</td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td>24 x24</td>
                                        <td>600 x 600</td>
                                        <td>7.25</td>
                                        <td>2 PCS</td>
                                        <td>20</td>
                                        <td>33</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-30" *ngIf="type == 1">
                <div class="col-md-12">
                    <div class="details spects-table">
                        <div id="wallTiles">
                            <h2 class="techSpecTitle"><span>Full Body Vitrified tiles packing Details</span></h2>
                            <table class="tsTable">
                                <tbody>
                                    <tr class="subHead">
                                        <th>Size (in inch)</th>
                                        <th>Size (in mm)</th>
                                        <th>Area coverage per box (sq.ft)</th>
                                        <th>Packing per box</th>
                                        <th>Thickness mm(approx)</th>
                                        <th>Weight per box</th>
                                    </tr>
                                    <tr>
                                        <td>24 x24</td>
                                        <td>600 x 600</td>
                                        <td>15.5</td>
                                        <td>4 PCS</td>
                                        <td>9.5</td>
                                        <td>28</td>
                                    </tr>
                                    <tr>
                                        <td>24 x 48</td>
                                        <td>600 x 1200</td>
                                        <td>15.5</td>
                                        <td>2 PCS</td>
                                        <td>12</td>
                                        <td>32</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row box-h mt-30" *ngIf="type == 1">
                <div class="col-md-12">
                    <div class="details spects-table">
                        <div id="wallTiles">
                            <h2 class="techSpecTitle"><span>Strip tiles packing Details</span></h2>
                            <table class="tsTable">
                                <tbody>
                                    <tr class="subHead">
                                        <th>Size (in inch)</th>
                                        <th>Size (in mm)</th>
                                        <th>Area coverage per box (sq.ft)</th>
                                        <th>Packing per box</th>
                                        <th>Thickness mm(approx)</th>
                                        <th>Weight per box</th>
                                    </tr>
                                    <tr>
                                        <td>8 x 48</td>
                                        <td>200 x 1200</td>
                                        <td>13.33</td>
                                        <td>5 PCS</td>
                                        <td>9</td>
                                        <td>28</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->

</main>