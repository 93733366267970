import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  message:any = '';
  name:any = '';
  mobile:any = '';
  subject:any = '';
  user:any;
  companyid:any =-1;

constructor(
  private userService : UserService,
) { }

  ngOnInit(): void {
		this.user = this.userService.getUser();
  }

  submit():void{
     
    if(this.user.company_id) { 
      this.companyid=this.user.company_id;

    } else{
      this.companyid=-1;
    }

		if(this.message != ''){
   
			this.userService.feedback({query: this.message, companyid: this.companyid, name: this.name, mobile: this.mobile,subject:this.subject}).subscribe(res =>{
				if(res.success){
					this.message = '';
					this.name = '';
					this.subject = '';
					this.mobile = '';
					this.companyid = -1;
					this.userService.sucessMessage(res.message)
				}else{
					this.userService.errorMessage(res.message);
				}
			})
		} else {
      alert("Please fill all the required fields");
    }
	}

}
