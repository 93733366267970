import { Component, OnInit } from '@angular/core';
import { InformativePagesService } from '../../services/informative-pages.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
	data:any = '';
	constructor(
		private pageService : InformativePagesService,
		private userService : UserService,
	) { }

	ngOnInit(): void {
		this.pageService.privacy().subscribe(res => {
			if(res.success){
				this.data = res.data; 
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}
}
