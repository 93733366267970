import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root'
})
export class AccessRoleGuard implements CanActivate {
	constructor(private userService: UserService, private router: Router) { }
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
			const token = this.userService.getToken();
			let temp_access = this.userService.getAccessRole();
			let role:any = {};
			if(token){
				if(temp_access?.is_access_role_available == '1'){
					role = JSON.parse(temp_access.access_role);
					if('/defaulter' == state.url || '/defaulter-details' == state.url){
						if(role?.defaulter.view){
							return true;
						}
					}else if('/defaulter/add' == state.url){
						if(role?.defaulter.add){
							return true;
						}
					}else if('/specification' == state.url || '/packing-details' == state.url || '/tiles-calculator' == state.url){
						if(role?.tools.view){
							return true;
						}
					}else if('/jobs/list' == state.url){
						if(role?.job_junction.view){
							return true;
						}
					}else if('/identity-find' == state.url){
						if(role?.identity_find.view){
							return true;
						}
					}else if('/reference-find' == state.url){
						if(role?.search_contact.view){
							return true;
						}
					}else if('/seller-posts' == state.url || '/seller-posts-detail' == state.url){
						if(role?.seller_post.view){
							return true;
						}
					}else if('/seller-post' == state.url){
						if(role?.seller_post.add){
							return true;
						}
					}else if('/seller-post/edit' == state.url){
						if(role?.seller_post.edit){
							return true;
						}
				//	}else if('/buyer-posts' == state.url || '/buyer-posts-detail' == state.url){
				}else if('/buyer-posts' == state.url || (state.url.indexOf('/buyer-posts-detail') > -1)){
						if(role?.buyer_post.view){
							return true;
						}
					}else if('/buyer-post' == state.url){
						if(role?.buyer_post.add){
							return true;
						}
					}else if('/buyer-post/edit' == state.url){
						if(role?.buyer_post.edit){
							return true;
						}
					}
				}
			}else{
				this.router.navigate(['/login']);
				return false;
			}
			this.userService.errorMessage("You don't have permission to use this feature!")	
			this.router.navigate(['/']);
			return false;
		}  

}
