<div class="details content-details">
    <h2>Deal Of Product</h2>
    <h5>Category</h5>
    <div style="overflow-x:auto;">
        <table>
            <tr *ngFor="let c of category">
                <td>{{c}}</td>
            </tr>
        </table>
    </div>
</div>
<div class="details content-details">
    <h5>Sub Category</h5>
    <div style="overflow-x:auto;">
        <table>
            <tr *ngFor="let sc of sub_category">
                <td>{{sc}}</td>
            </tr>
        </table>
        <h6>Product Description</h6>
        <p>{{description}}</p>
    </div>
</div>