import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-supplier-category',
	templateUrl: './supplier-category.component.html',
	styleUrls: ['./supplier-category.component.css']
})
export class SupplierCategoryComponent implements OnInit {
	@Input('products') products = [];
	@Input('description') description = '';
	constructor() { }

	ngOnInit(): void {
	}

}
