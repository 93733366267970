<style type="text/css">
    .digital-cards .theme-bg{
        background: #548761;
    }
    .digital-cards .theme-color{
        color: #548761;
    }
    .digital-cards .page-container{
        background: #fff;
        margin: 0 auto;
        padding: 2px;
    }
    .digital-cards .header{
        background: #353b37;
        height: 150px;
        padding: 0;
    }
    .digital-cards .company-section{
        height: 115px;
        width: 100%;
    }
    .digital-cards .company-name{
        font-weight: 500;
        font-size: 20px;
        color: #fff;
    }
    .digital-cards .profile{
        background: #fff;
        text-align: center;
        width: 130px;
        height: 130px;
        border-radius: 100px;
        margin: 0 auto;
        margin-top: -58px;
        border: 5px solid #353b37;
        position: absolute;
        left: 0;
        right: 0;
        top: 140px;
    }
    .digital-cards .contact-info{
        border-radius: 7px;
        padding: 5px;
        color:#fff; 
    }
    .digital-cards .contact-info span{
        margin-left: 10px;
    }
    .digital-cards .contact-info span i{
        margin-right: 5px;
    }
    .digital-cards .address-section{
        background: #353b37;
        padding: 0px 15px;
    }
    .digital-cards .address-section-inner{
        background: #fff;
        padding: 7px;
    }
</style>
<main>
    <app-breadcrumb [pageName]="'Digital Visiting Card'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Digital Visiting Card</h1>
                        <img src="assets/img/line.svg" width="120">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center digital-cards" *ngIf="!cardUrl">
                <div class="col-xl-4  col-md-12 mt-5" *ngFor="let color of colors">
                    <div class="form-box" style="padding: 10px;">
                        <div class="page-container">
                            <div class="header">
                                <div style="height:25px">
                                </div>
                                <div class="company-section" style="background-color: #{{color}}">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="text-center company-name">Company Name</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile">
                                    <div class="row">
                                        <div class="col-12">
                                            <img src="../../../assets/img/aut-img.png" alt="User image" class="img-fluid" style="border-radius: 100px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12" style="margin-top: 70px;">
                                    <p class="text-center m-0" style="color:#000; font-size: 20px; font-weight: 700;">Contact Person</p>
                                    <p class="text-center" style="font-weight: 500; color: #{{color}}">Designation</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <p class="m-0  contact-info" style="background-color: #{{color}}">
                                        <span>
                                        <i class="fa fa-phone mr-1" aria-hidden="true"></i>
                                        Call
                                        </span>
                                    </p>
                                </div>
                                <div class="col-6">
                                    <p class="m-0 contact-info" style="background-color: #{{color}}">
                                        <span>
                                        <i class="fa fa-whatsapp mr-1" aria-hidden="true"></i>
                                        Whatsapp
                                        </span>
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p class="m-0 mt-2 contact-info" style="background-color: #{{color}}">
                                        <span>
                                        <i class="fa fa-envelope mr-1" aria-hidden="true"></i>
                                        Email
                                        </span>
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p class="m-0 mt-2 contact-info" style="background-color: #{{color}}">
                                        <span>
                                        <i class="fa fa-globe mr-1" aria-hidden="true"></i>
                                        Website
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mt-5">
                                    <div class="address-section">
                                        <div class="address-section-inner">
                                            <i class="fa fa-map-marker" style="color: #{{color}}; font-size: 25px; margin-right: 5px;" aria-hidden="true"></i> 
                                            <b>Address</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <input type="submit" (click)="chooseCard(color)" value="Select">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="cardUrl">
                <img [src]="cardUrl" alt="Visitor Card" class="img-fluid">
            </div>
            <div class="row justify-content-center" *ngIf="cardUrl">
                <div class="col-12 text-center mt-3">
                    <input type="submit" (click)="changeCard()" value="Change" class="mr-3" style="background: linear-gradient(90deg, rgba(166,199,108,1) 0%, rgba(82,107,140,1) 100%);">
                    <a [href]="cardUrl" target="_blank" download>
                        <input type="submit" value="Download" style="background: linear-gradient(90deg, rgba(166,199,108,1) 0%, rgba(82,107,140,1) 100%);">
                    </a>
                 
                    <br/><br/>
                    
                    Share via &nbsp;
                    <a [href]="whatsapp" target="_blank" data-action="share/whatsapp/share">
                        <i class="fa fa-whatsapp fa-2x" aria-hidden="true"></i> 
                    </a>&nbsp;&nbsp;
                    <a [href]="facebook" target="_blank" >
                        <i class="fa fa-facebook fa-2x" aria-hidden="true"></i>   
                    </a>&nbsp;&nbsp;
                    <a [href]="twitter" target="_blank" >
                        <i class="fa fa-twitter fa-2x" aria-hidden="true"></i>   
                    </a>
                    


                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>