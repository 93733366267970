import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from "../../../services/analytics.service";
import { UserService } from "../../../services/user.service";
import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
	selector: 'app-analytics',
	templateUrl: './analytics.component.html',
	styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
	pieChartOptions: ChartOptions = {
		responsive: true,
		legend: {
			position: 'right',
		},
		tooltips: {
			enabled: true,
			mode: 'single',
			callbacks: {
				label: function (tooltipItems, data) {
					return data.datasets[0].data[tooltipItems.index]+'';
				}
			}
		},
	};
	pieChartLabels: Label[] = [
		'INQUIRIES',
		'CALL',
		'WHATSAPP',
		'LOCATION',
		'MAIL',
		'SOCIAL MEDIA',
		'PRODUCT GALLERY',
		'COMPANY PHOTOS',
		'CATALOGUE',
		'PROFILE VIEW',
		'WEBSITE CLICK',
	];
	pieChartData: number[] = [];
	pieChartType: ChartType = 'pie';
	pieChartLegend = true;
	pieChartPlugins = [];
	pieChartColors = [
		{
			backgroundColor: [
				'rgba(255,0,0,0.3)',
				'rgba(0,255,0,0.3)',
				'rgba(0,0,255,0.3)',
				'rgba(140, 135, 65, 0.3)',
				'rgba(76, 140, 65, 0.3)',
				'rgba(60, 60, 92, 0.3)',
				'rgba(92, 60, 88, 0.3)',
				'rgba(187, 196, 134, 0.3)',
				'rgba(69, 117, 100, 0.3)',
				'rgba(87, 143, 171, 0.3)',
				'rgba(158, 73, 73, 0.3)',
			],
		},
	];
	data: any = {};
	constructor(
		private analyticsService: AnalyticsService,
		private userService: UserService,
	) { }

	ngOnInit(): void {
		this.analyticsService.getCount().subscribe(res => {
			if (res.success) {
				this.pieChartData = [
					res.data.inquiry,
					res.data.call_click,
					res.data.whatsapp_click,
					res.data.location_click,
					res.data.mail_click,
					res.data.social_media_click,
					res.data.product_gallery_click,
					res.data.company_photos_click,
					res.data.catalogue_click,
					res.data.profile_view,
					res.data.website_click,
				];
				this.data = res.data;
			} else {
				this.userService.errorMessage(res.message);
			}
		})
	}

}
