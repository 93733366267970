<main>
    <app-breadcrumb [pageName]="'Chat'"></app-breadcrumb>
    <!-- design area start -->
    <div class="chat-area-main-part">
        <div class="container">
            <div class="row ">
                <div class="app">
                    <div class="wrapper">
                        <div class="conversation-area">
                            <div class="main-heading chat-area-header">
                                <input type="text" (input)="onSearch($event)" [(ngModel)]="search" class="chat-search-input"  placeholder="Search here..">
                            </div>
                            <div class="msg online" *ngFor="let chat of chatList" (click)="activeUser(chat.userid)" [ngClass]="{'active' : activeUserId == chat.userid}">
                                <div class="msg-number" *ngIf="chat?.unread_count > 0">{{chat?.unread_count}}</div>
                                <div class="online-status" *ngIf="chat?.is_online"></div>
                                <img alt="{{chat?.name}}" class="msg-profile" [src]="chat?.image" />
                                <div class="msg-detail">
                                    <div class="msg-username">{{chat?.name}}</div>
                                    <div class="msg-content">
                                        <span class="msg-message" *ngIf="!chat?.is_typing">{{chat?.message}}</span>
                                        <span class="msg-message" *ngIf="chat?.is_typing"><i>typing...</i></span>
                                        <span class="msg-date">{{chat?.time}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="chat-area" #scrollMe>
                            <div class="chat-area-header">
                                <div class="chat-area-title">
                                    CHAT
                                </div>
                                <div class="chat-area-group">
                                    <!-- <ul>
                                        <li class="fa fa-phone">
                                        </li>
                                        <li class="fa fa-video-camera">
                                        </li>
                                    </ul> -->
                                </div>
                            </div>
                            <div class="chat-area-main" id="test" #scrollMe>
                                <div *ngFor="let msg of conversation" >
                                    <div class="chat-msg owner" *ngIf="user.company_id == msg?.fromUserId">
                                        <div class="chat-msg-profile">
                                            <img alt="User Image" class="chat-msg-img"  [src]="msg?.from_profile" />
                                        </div>
                                        <div class="chat-msg-content">
                                            <div class="chat-msg-text">
                                                {{msg?.message}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chat-msg" *ngIf="user.company_id != msg?.fromUserId">
                                        <div class="chat-msg-profile">
                                            <img alt="User Image" class="chat-msg-img" [src]="msg?.from_profile" />
                                        </div>
                                        <div class="chat-msg-content">
                                            <div class="chat-msg-text">
                                                {{msg?.message}}
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="chat-area-footer" *ngIf="activeUserId != 0">
                                <input placeholder="Type something here..." (input)="onInput()" (keyup.enter)="keytab($event)"  (focus)="focusInput()" (focusout)="focusOutInput()"  [(ngModel)]="message" type="text" />
                                <button class="view-btn" (click)="sendMessage()">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- design area end -->
</main>