import { Component, OnInit } from '@angular/core';
import { UserService } from  '../../services/user.service'

@Component({
	selector: 'app-digital-card',
	templateUrl: './digital-card.component.html',
	styleUrls: ['./digital-card.component.css']
})
export class DigitalCardComponent implements OnInit {
	cardUrl:string = '';
	whatsapp:string = '';
	facebook:string = '';
	twitter:string = '';
	constructor(
		private userService : UserService
	) { }

	colors:any = ['f05310', 'fcb934','671762', '17a43e','0b5989','187ac6', '2d99af', '4e2454'];

	ngOnInit(): void {
		let user = this.userService.getUser();
		if(user?.digital_card){
			this.cardUrl =  "https://gujarattilesinfo.com/backend" + user.digital_card;
			this.whatsapp = "https://web.whatsapp.com/send?text=https://gujarattilesinfo.com/backend" + user.digital_card;
			this.facebook = "https://www.facebook.com/sharer/sharer.php?u=https://gujarattilesinfo.com/backend" + user.digital_card;
			this.twitter = "http://twitter.com/share?text=My Visiting Card&url=https://gujarattilesinfo.com/backend/" + user.digital_card;
		}
	}
	chooseCard(color):void{
		
		this.userService.digitalCardGenerate({color: color}).subscribe(res => {
			if(res.success){
				//console.log(res.data)
				localStorage.setItem('digital_card', res.data.image);
				this.cardUrl = res.data.image;
				
			this.whatsapp = "https://web.whatsapp.com/send?text=" + this.cardUrl;
			this.facebook = "https://www.facebook.com/sharer/sharer.php?u=" + this.cardUrl;
			this.twitter = "http://twitter.com/share?text=My Visiting Card&url=" + this.cardUrl;

				this.userService.sucessMessage(res.message);
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}
	changeCard():void{
		this.cardUrl = '';
	}
}
