import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import { UserService } from './user.service';
import { catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	constructor(
		private router: Router,
		private userService: UserService,
		private spinner: NgxSpinnerService
	) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!request.headers.has('X-Skip-Interceptor')) {
			this.spinner.show();
		}
		const authToken = this.userService.getToken();
		let setHeaders = { Accept: 'application/json' };

		if (authToken) {
			setHeaders = { ...setHeaders, ...{ Authorization: `Bearer ${authToken}` } }
		}
		return next.handle(request.clone({ setHeaders })).pipe(
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === 0) {
						this.userService.errorMessage('Invalid request');
					}
					else if (error.status === 401) {
						this.userService.errorMessage('Unauthorized!');
						this.userService.logout();
						this.userService.setAuthLogoutListener();
						this.router.navigate(['/login']);
					}
					else if (error.status === 500) {
						this.userService.errorMessage('Something went wrong. Please try again!');
					}
				}
				return of(error);
			}),
			finalize(() => {
				this.spinner.hide();
			}),
		);
	}
}
