<main>
	<app-breadcrumb [pageName]="'Help & Support'"></app-breadcrumb>
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>Help & Support</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
				</div>
			</div>
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="form-box">
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <label for="image" class="label">Message</label>
                                <textarea placeholder="Write message..." [(ngModel)]="message" class="form-control" rows="6"></textarea>
                            </div>
                        </div>
                        <div style="text-align: center;">
                            <input type="submit" name="" value="Submit" (click)="submit()">
                        </div>
                    </div>
                </div>
                <div class="col-md-8 pt-5 text-center">
                    <h2>Contact</h2>
                    <p><b>Email : </b>gti.directory@gmail.com</p>
                    <p><b>Mobile : </b>+91 909 908 907 1</p>
                </div>
            </div>
		</div>
	</section>
	<!-- design area end -->
</main>