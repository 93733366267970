<footer class="footer-area" id="contact" style="background: #f8f8f8;">
	<div class="position-relative">
		<div class="header-top pt-120 pb-80">
			<div class="container">
				<div class="row">
					<div class="col-xl-4 col-lg-4 col-sm-6">
						<div class="widget footer-sidebar">
							<div class="site-logo footer-site-info">
								<a href="index.html">
									<img src="assets/img/logo.png" alt="">
								</a>
								<p class="mt-10">For more than Two years, the "Gujarat Tiles Info" label has been
									promoting the image and content of the Indian ceramic industry – tiles, sanitaryware
									and Others – in domestic markets. It stands for tradition, quality, innovation and
									creativity as well as for a guarantee of Made in India production.</p>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-2 col-sm-6">
						<div class="widget footer-sidebar">
							<h3 class="mb-25">Quick Links</h3>
							<ul>
								<li><a routerLink="about-us">About GTI </a></li>
								<li><a routerLink="blogs">Blog & News</a></li>
								<li><a href="https://play.google.com/store/apps/details?id=com.essence.gujarattilesinfo">Rating & Review </a></li>
								<li><a routerLink="feedback">Feedback & Complain </a></li>
								<li><a routerLink="term-condition">Terms & Condition </a></li>
								<li><a routerLink="privacy-policy">Privacy Policy </a></li>
							</ul>
						</div>
					</div>
					<div class="col-xl-3 col-lg-3 col-sm-6">
						<div class="widget footer-sidebar">
							<h3 class="mb-25">Quick Links</h3>
							<ul>
								<li><a routerLink="membership">Subscribe Membership Plan</a></li>
								<li><a routerLink="/jobs/list">Job Junction</a></li>
								<li><a href="#">Tools</a></li>
								<li><a routerLink="directory">Directory</a></li>
								<li><a routerLink="contact-us">Contact Us</a></li>
							</ul>
						</div>
					</div>
					<div class="col-xl-3 col-lg-3 col-sm-6">
						<div class="widget footer-sidebar app-side">
							<h3 class="mb-25">Newsletter</h3>
							<form [formGroup]="form" (ngSubmit)="onSubmit()">
								<div class="search-box1">
									<input type="text" formControlName="email" class="search-input" placeholder="Enter Email Id">
									<div *ngIf="form.get('email').touched && form.get('email').invalid">
										<label class="invalid-feedback font-medium is-invalid" *ngIf="form.get('email').errors && form.get('email').hasError('required')">Please enter email</label>
										<label class="invalid-feedback font-medium is-invalid"*ngIf="form.get('email').errors && form.get('email').hasError('email')">Invalid email</label>
									</div>
									<input type="submit" name="" value="Subscribe">
								</div>
							</form>
							<h3 class="mb-25 follow-txt">Follow Us</h3>
							<div class="social-list footer-social-list ">
								<a href="https://www.facebook.com/gujarattilesinfo" class="fb" target="_blank"><i class="fa fa-facebook"></i></a>
								<a href="https://www.linkedin.com/in/gujarattilesinfo/" class="twi" target="_blank"><i class="fa fa-linkedin"></i></a>
								<a href="https://twitter.com/gtiindia/" class="twi" target="_blank"><i class="fa fa-twitter"></i></a>
								<a href="https://www.instagram.com/gujarattilesinfo/" class="ins" target="_blank"><i class="fa fa-instagram"></i></a>
								<a href="https://www.youtube.com/c/GujaratTilesInfo" class="yb" target="_blank"><i class="fa fa-youtube"></i></a>
							</div>

							<!--
	<button type="button" class="view-btn view-new"  data-toggle="modal" data-target="#myModal">&nbsp;&nbsp;Inquiry&nbsp;&nbsp;</button>
						-->
	
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="header-bootom">
			<div class="container">
				<div class="row">
					<div class="col-xl-8 col-lg-8 col-xs-12">
						<div class="copyright-content">
							<p>2023 © GTI All Rights Reserved.</p>
						</div>
					</div>
					<div class="col-xl-4 col-lg-4 col-xs-12 text-right">
					
						<a href="https://api.whatsapp.com/send?phone=919099089071&text=Hello%20gujarattiles%2C%20%0AI%20want%20to%20ask%20you%20a%20question%20about."><img src="assets/img/help-button.jpg" class="img-fluid gti-wtap-button">	</a>
 
	<img src="assets/img/gti-help-logo.png" alt="" class="img-fluid inquirybottom" data-toggle="modal" data-target="#myModal">
					</div>
				</div>
			</div>
		</div>
	</div>   


	 <!-- Modal start -->
	 <div class="modal fade" id="myModal" role="dialog">
		<div class="modal-dialog modal-lg" role="document">
	
		  <!-- Modal content-->
		  <div class="modal-content ">
			<div class="modal-header">
			  <button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="container">
			  
				<app-seller-form  [tiles]="extraseller?.tiles" [sanitary]="extraseller?.sanitary" [various]="extraseller?.various"></app-seller-form>

			</div>
			<div class="modal-footer">
			  <button type="button"   class="btn btn-default" data-dismiss="modal">Close</button>
			</div>
		  </div>
	
		</div>
	  </div>
	  
	 <!-- Modal end -->
</footer>