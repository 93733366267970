<div class="details content-details">
    <h2>Service Area & Service Type</h2>
    <div style="overflow-x:auto;">
        <table>
            <tr>
                <td>Type of Transporter</td>
                <td>{{type}}</td>
            </tr>
            <tr>
                <td>Service area</td>
                <td><p *ngFor="let a of area">{{a}}</p></td>
                
            </tr>
        </table>
        <h6>Service Description</h6>
        <p>{{description}}</p>
    </div>
</div>