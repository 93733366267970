import { FormValidatorServiceService } from './../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators,AbstractControl,ValidatorFn,ValidationErrors } from '@angular/forms';
import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {

	resetPasswordForm: FormGroup;
	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private userService : UserService,
		private formValidationService: FormValidatorServiceService
	) {}
	
	ngOnInit(): void {
		this.resetPasswordForm = this.formBuilder.group({
			password:['', [Validators.required]],
			confirmPassword:['', [Validators.required]]
		}, { validators: this.checkPasswords });
	}

	checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
		console.log(group.value);
 	  	return group.get('password').value === group.get('confirmPassword').value ? null : { notSame: true }
	}

	onSubmit(): void {
		if (this.resetPasswordForm.valid) {
			this.userService.reset_password({password:this.resetPasswordForm.value.password,id:this.userService.getUserId()}).subscribe(response => {
				if(response.success) {
					this.userService.sucessMessage(response.message);
					this.userService.removeUserId();
					this.router.navigate(['/']);
				}
				else {
					this.userService.errorMessage(response.message);
				}
			});	
		}
		else {
			this.formValidationService.validateAllFormFields(this.resetPasswordForm);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
}


