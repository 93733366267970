import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-cab-service',
	templateUrl: './cab-service.component.html',
	styleUrls: ['./cab-service.component.css']
})
export class CabServiceComponent implements OnInit {
	@Input('services') services = [];
	@Input('cars') cars = '';
	constructor() { }

	ngOnInit(): void {
	}

}
