import { TokenInterceptor } from './services/token.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './modules/common/navigation/navigation.component';
import { HeaderComponent } from './modules/common/header/header.component';
import { FooterComponent } from './modules/common/footer/footer.component';
import { BreadcrumbComponent } from './modules/common/breadcrumb/breadcrumb.component';
import { AboutusComponent } from './modules/components/aboutus/aboutus.component';
import { HomeComponent } from './modules/components/home/home.component';
import { DirectoryComponent } from './modules/components/directory/directory.component';
import { SignupFormComponent } from './modules/components/signup-form/signup-form.component';
import { SellerPostsListComponent } from './modules/seller/list/seller-posts-list.component';
import { BuyerPostsListComponent } from './modules/buyer/list/buyer-posts-list.component';
import { BuyerPostDetailComponent } from './modules/buyer/detail/buyer-post-detail.component';
import { SellerPostDetailComponent } from './modules/seller/detail/seller-post-detail.component';
import { NotificationComponent } from './modules/common/notification/notification.component';
import { SidebarComponent } from './modules/common/sidebar/sidebar.component';
import { AnalyticsComponent } from './modules/components/analytics/analytics.component';
import { JobsListComponent } from './modules/components/jobs/list/jobs-list.component';
import { SellerFormComponent } from './modules/seller/form/form.component';
import { BuyerFormComponent } from './modules/buyer/form/form.component';
import { CompanyDetailComponent } from './modules/company/company-detail/company-detail.component';
import { HotelComponent } from './modules/company/hotel/hotel.component';
import { CabComponent } from './modules/company/cab/cab.component';
import { AdminComponent } from './modules/company/admin/admin.component';
import { DealerComponent } from './modules/company/dealer/dealer.component';
import { RawMaterialComponent } from './modules/company/raw-material/raw-material.component';
import { SanitaryComponent } from './modules/company/sanitary/sanitary.component';
import { TradersComponent } from './modules/company/traders/traders.component';
import { VariousComponent } from './modules/company/various/various.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TilesManufactureListComponent } from './modules/company/tiles-manufacture-list/tiles-manufacture-list.component';
import { ProductGalleryComponent } from './modules/company/product-gallery/product-gallery.component';
import { CompanyPhotosComponent } from './modules/company/company-photos/company-photos.component';
import { SellerPostFormComponent } from './modules/seller-post-form/seller-post-form.component';
import { BuyerPostFormComponent } from './modules/buyer-post-form/buyer-post-form.component';
import { InboxComponent } from './modules/inbox/inbox.component';
import { ChatComponent } from './modules/chat/chat.component';
import { MySalePostComponent } from './modules/my-sale-post/my-sale-post.component';
import { MyBuyPostComponent } from './modules/my-buy-post/my-buy-post.component';
import { SpecificationComponent } from './modules/specification/specification.component';
import { PackingDetailsComponent } from './modules/packing-details/packing-details.component';
import { TilesCalculatorComponent } from './modules/tiles-calculator/tiles-calculator.component';
import { DefaulterComponent } from './modules/defaulter/defaulter.component';
import { DefaulterDetailsComponent } from './modules/defaulter-details/defaulter-details.component';
import { FindIdentityComponent } from './modules/find-identity/find-identity.component';
import { FindReferenceComponent } from './modules/find-reference/find-reference.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TilesProductSizeComponent } from './modules/company/company-component/tiles-product-size/tiles-product-size.component';
import { CompanyDetailsComponent } from './modules/company/company-details/company-details.component';
import { CompanyAboutComponent } from './modules/company/company-about/company-about.component';
import { SanitaryProductComponent } from './modules/company/company-component/sanitary-product/sanitary-product.component';
import { VariousProductComponent } from './modules/company/company-component/various-product/various-product.component';
import { SupplierComponent } from './modules/company/supplier/supplier.component';
import { SupplierCategoryComponent } from './modules/company/company-component/supplier-category/supplier-category.component';
import { ServiceProviderComponent } from './modules/company/service-provider/service-provider.component';
import { ServiceProviderServiceComponent } from './modules/company/company-component/service-provider-service/service-provider-service.component';
import { CabServiceComponent } from './modules/company/company-component/cab-service/cab-service.component';
import { TransporterComponent } from './modules/company/transporter/transporter.component';
import { TransportAreaServiceComponent } from './modules/company/company-component/transport-area-service/transport-area-service.component';
import { HotelRoomAmenitiesComponent } from './modules/company/company-component/hotel-room-amenities/hotel-room-amenities.component';
import { TraderDealerCategoryComponent } from './modules/company/company-component/trader-dealer-category/trader-dealer-category.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { ProfileEditComponent } from './modules/profile-edit/profile-edit.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { TermConditionComponent } from './modules/term-condition/term-condition.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from '../environments/environment';
import { SearchComponent } from './modules/search/search.component';
import { FollowingsComponent } from './modules/company/followings/followings.component';
import { FollowersComponent } from './modules/company/followers/followers.component';
import { BlogComponent } from './modules/blog/blog.component';
import { DefaulterFormComponent } from './modules/defaulter-form/defaulter-form.component';
import { HelpSupportComponent } from './modules/help-support/help-support.component';
import { DigitalCardComponent } from './modules/digital-card/digital-card.component';
import { SlugPipe } from './pipes/slug.pipe';
import { BlogDetailComponent } from './modules/blog-detail/blog-detail.component';
import { ForgotPasswordComponent } from './modules/components/forgot-password/forgot-password.component';
import { VerifyOtpComponent } from './modules/components/verify-otp/verify-otp.component';
import { ResetPasswordComponent } from './modules/components/reset-password/reset-password.component';
import { ContactComponent } from './modules/contact/contact.component';
import { MembershipComponent } from './modules/membership/membership.component';
import { FeedbackComponent } from './modules/feedback/feedback.component';


import { VariousMoreComponent } from './modules/company/variousmore/variousmore.component';

@NgModule({
	declarations: [
		AppComponent,
		NavigationComponent,
		HeaderComponent,
		FooterComponent,
		BreadcrumbComponent,
		AboutusComponent,
		HomeComponent,
		DirectoryComponent,
		JobsListComponent,
		SellerPostsListComponent,
		BuyerPostsListComponent,
		SignupFormComponent,
		BuyerPostDetailComponent,
		SellerPostDetailComponent,
		NotificationComponent,
		SidebarComponent,
		AnalyticsComponent,
		BuyerFormComponent,
		SellerFormComponent,
		CompanyDetailComponent,
		HotelComponent,
		CabComponent,
		AdminComponent,
		DealerComponent,
		RawMaterialComponent,
		SanitaryComponent,
		TradersComponent,
		VariousComponent,
		TilesManufactureListComponent,
		ProductGalleryComponent,
		CompanyPhotosComponent,
		SellerPostFormComponent,
		BuyerPostFormComponent,
		InboxComponent,
		ChatComponent,
		MySalePostComponent,
		MyBuyPostComponent,
		SpecificationComponent,
		PackingDetailsComponent,
		TilesCalculatorComponent,
		DefaulterComponent,
		DefaulterDetailsComponent,
		FindIdentityComponent,
		FindReferenceComponent,
		TilesProductSizeComponent,
		CompanyDetailsComponent,
		CompanyAboutComponent,
		SanitaryProductComponent,
		VariousProductComponent,
		SupplierComponent,
		SupplierCategoryComponent,
		ServiceProviderComponent,
		ServiceProviderServiceComponent,
		CabServiceComponent,
		TransporterComponent,
		TransportAreaServiceComponent,
		HotelRoomAmenitiesComponent,
		TraderDealerCategoryComponent,
		ProfileComponent,
		ProfileEditComponent,
		PrivacyPolicyComponent,
		TermConditionComponent,
		SearchComponent,
		FollowingsComponent,
		FollowersComponent,
		BlogComponent,
		DefaulterFormComponent,
		HelpSupportComponent,
		DigitalCardComponent,
		SlugPipe,
		BlogDetailComponent,
		ForgotPasswordComponent,
		VerifyOtpComponent,
		ResetPasswordComponent,
		ContactComponent,
		MembershipComponent,
		FeedbackComponent,
		VariousMoreComponent
	],
	imports: [
		BrowserModule,
		// CommonModule,
		AppRoutingModule,
		CarouselModule,
		BrowserAnimationsModule,
		NgxSpinnerModule,
		HttpClientModule,
		ToastrModule.forRoot(),
		FormsModule,
		ReactiveFormsModule,
		CKEditorModule,
		NgMultiSelectDropDownModule.forRoot(),
		NgSelectModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireMessagingModule,
		ChartsModule,
		NgxIntlTelInputModule,
		PaginationModule,
		AutocompleteLibModule
	],
	providers: [
		ToastrService,
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
