import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError, Observable, Subject } from 'rxjs';
import { apiConstants } from '../constants/api-constants';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APIResponse } from '../Modal/apiresponse';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SellerPostService {
	constructor(private http: HttpClient) { }

	getFields(): Observable<APIResponse> {
		return this.http.get(apiConstants.sellerpost.fields).pipe(catchError(this.handleError));
	}
	savePost(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.sellerpost.save, form).pipe(catchError(this.handleError));
	}
	getPostList(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.sellerpost.list, form).pipe(catchError(this.handleError));
	}
	getPostDetails(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.sellerpost.details, form).pipe(catchError(this.handleError));
	}
	getMyPostList(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.sellerpost.mypost, form).pipe(catchError(this.handleError));
	}
	getPost(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.sellerpost.getpost, form).pipe(catchError(this.handleError));
	}
	updatePost(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.sellerpost.update, form).pipe(catchError(this.handleError));
	}

	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		return throwError('Something bad happened; please try again later.');
	}
}
