<div class="container">
	<div class="row mb-3" *ngFor="let blogs of blogList">
		<div class="col-sm-4 " *ngFor="let blog of blogs">
			<div class="box_bb p-2">
				<div class="img_bb">
					<img [src]="blog.image" class="blog-image" />
				</div>
				<div class="other_in_b">
					<div class="meta_in">
						<p class="by-author mt-2">{{blog.created_at | date}}</p>
					</div>
					<h4>
						<a [routerLink]="['/blog-detail', blog.id,slugify(blog.title)]" [state]="{id: blog.id}">{{blog.title}}</a>
					</h4>
					<div class="excepp">
						<p [innerHTML]="descriptionLimit(blog.content)" class="mb-0"></p>
					</div>
					<div class="read_mmm_b mb-2">
					<!--	<a [routerLink]="['/blog-detail']" [state]="{id: blog.id}" class="sel-btn">Read More</a> -->
						
						<a [routerLink]="['/blog-detail', blog.id,slugify(blog.title)]" [state]="{id: blog.id}" class="sel-btn">Read More</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">

			<pagination class="mt-4" [itemsPerPage]="itemPerPage"   pageBtnClass="btn" [totalItems]="totalPage"  
			[(ngModel)]="currentPage" (pageChanged)="pageChanged($event)">
		</pagination>
		<!--
			<pagination class="mt-4" pageBtnClass="btn" [totalItems]="totalPage"   *ngIf="totalPage"
                [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)">
            </pagination>
		-->
		</div>
	</div>
</div>