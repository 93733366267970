<main>
    <app-breadcrumb [pageName]="'Defaulter'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Defaulter</h1>
                        <img src="assets/img/line.svg" width="120">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-md-8 col-sm-8 col-xs-12">
                    <div class="form-box">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" name="company_name" formControlName="company_name" placeholder="Company name">
                                    <div *ngIf="form.get('company_name').touched && form.get('company_name').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('company_name').errors && form.get('company_name').hasError('required')">Please enter company name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="address" formControlName="address" placeholder="Address">
                                    <div *ngIf="form.get('address').touched && form.get('address').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('address').errors && form.get('address').hasError('required')">Please enter address</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="city" formControlName="city" placeholder="City">
                                    <div *ngIf="form.get('city').touched && form.get('city').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('city').errors && form.get('city').hasError('required')">Please enter city</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="district" formControlName="district" placeholder="District">
                                    <div *ngIf="form.get('district').touched && form.get('district').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('district').errors && form.get('district').hasError('required')">Please enter district</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="state" formControlName="state" placeholder="District">
                                    <div *ngIf="form.get('state').touched && form.get('state').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('state').errors && form.get('state').hasError('required')">Please enter state</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="country" formControlName="country" placeholder="Country">
                                    <div *ngIf="form.get('country').touched && form.get('country').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('country').errors && form.get('country').hasError('required')">Please enter country</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="cell" formControlName="cell" placeholder="Cell No.">
                                    <div *ngIf="form.get('cell').touched && form.get('cell').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('cell').errors && form.get('cell').hasError('required')">Please enter cell</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="cell_alt" formControlName="cell_alt" placeholder="Another cell no.">
                                    <div *ngIf="form.get('cell_alt').touched && form.get('cell_alt').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('cell_alt').errors && form.get('cell_alt').hasError('required')">Please enter cell</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="gst" formControlName="gst" placeholder="GSTIN">
                                    <div *ngIf="form.get('gst').touched && form.get('gst').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('gst').errors && form.get('gst').hasError('required')">Please enter GSTIN</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="amount_due" formControlName="amount_due" placeholder="Amount Due">
                                    <div *ngIf="form.get('amount_due').touched && form.get('amount_due').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('amount_due').errors && form.get('amount_due').hasError('required')">Please enter amount due</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="date" name="built_date" formControlName="built_date" placeholder="Built Date">
                                    <div *ngIf="form.get('built_date').touched && form.get('built_date').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('built_date').errors && form.get('built_date').hasError('required')">Please select built date</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="file" accept="image/*" #image (change)="onSelectImage(image.files)" />
                                    <div *ngIf="form.get('image').touched && form.get('image').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('image').errors && form.get('image').hasError('required')">Please select image</label>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <textarea name="desc" formControlName="desc" placeholder="Description"></textarea>
                                    <div *ngIf="form.get('desc').touched && form.get('desc').invalid">
                                        <label class="invalid-feedback font-medium is-invalid" *ngIf="form.get('desc').errors && form.get('desc').hasError('required')">Please enter description</label>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: center;">
                                <input type="submit" value="Submit">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>