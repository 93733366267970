<main>
	<!-- design area start -->
	<section class="design-area new-page-area" id="service">
		<div class="container">

			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="profile-data" style="background:url({{data?.banner}});">
                        <button class="edit-btn-profile" *ngIf="user?.designation == 1" (click)="edit()">Edit</button>
						<div class="pro-data">
							<div class="profile-img">
								<img [src]="data?.logo" class="profile" alt="Company Logo">

								<img src="assets/img/level.png" class="level" alt="Package" *ngIf="data?.package == 1">
								<img src="assets/img/standard.png" class="level" alt="Package"
									*ngIf="data?.package == 2">
								<img src="assets/img/classic.png" class="level" alt="Package"
									*ngIf="data?.package == 3">
								<img src="assets/img/rank.png" class="rank">
								<span class="rank-text">{{data?.level}}</span>
							</div>
							<div class="profile-details">
								<h4>{{data?.name}}</h4>
								<p *ngIf="type == 1 || type == 2 || type == 3 || type == 9">( {{data?.brand}} )</p>
								<p *ngIf="type == 7 || type == 4 || type == 5">( {{data?.role}} )</p>
							</div>
							<ul class="profile-ul">
								<li routerLink="/followers" [state]="{follow_cp_id: data?.id}">{{data?.followers}} Followers</li>
								<li *ngIf="data?.is_verified" style="background: unset; padding: 0;"><img title="Verified" src="../../../assets/img/verify.png" class="img-fluid" style="width: 30px;" alt="verified"></li>
								<li routerLink="/followings" [state]="{follow_cp_id: data?.id}">{{data?.following}} Following</li>
							</ul>

							<div class="follow-btn">
								<!-- <a><button class="view-btn" *ngIf="!data?.is_following" (click)="followUnfollow(1)">Follow &nbsp;<i class="fa fa-user"></i></button></a>
								<a><button class="view-btn" *ngIf="data?.is_following" (click)="followUnfollow(0)">Unfollow &nbsp;<i class="fa fa-user"></i></button></a>
								<a> <button class="view-btn"  routerLink="/chat" [state]="{cIdChatid: data.id, name: data?.name,image: data.logo}">Message &nbsp;<i class="fa fa-envelope"></i></button></a> -->
							</div>
						</div>
					</div>
				</div>
			</div>



			

			
			<div class="row justify-content-center" style="margin-top:10px;margin-bottom:10px">
                <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-box">
						<div><h2 style="padding-left:5px">Update Status</h2></div>
                        <form [formGroup]="statusForm" (ngSubmit)="updateMessagestatus()">
                            <div class="row">
                                <div class="col-md-12">
                                    <input type="text" formControlName="statusmessage" name="" placeholder="Status Message" maxlength="254">
                                    <div *ngIf="statusForm.get('statusmessage').touched && statusForm.get('statusmessage').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="statusForm.get('statusmessage').errors && statusForm.get('statusmessage').hasError('required')">Please Message</label>
                                    </div>
                                </div>
                            </div>

						

							
							<div class="row">
                                <div class="col-md-8">
                                    <label for="company" class="label mt-3">Status Image</label>
                                    <input type="file" name="statuspic" id="statuspic"
                                            class="file-input mt-0" accept="image/*" #statuspic
                                            (change)="onSelectImage(statuspic.files)" />
                                    <div *ngIf="statusForm.get('statuspic').touched && statusForm.get('statuspic').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="statusForm.get('statuspic').errors && statusForm.get('statuspic').hasError('required')">Please enter image</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <img [src]="statusForm.value.statuspicImg"
                                        class="img-thumbnail statuspic"
                                        [ngClass]="{'hide':statusForm.value.statuspicImg == ''}">
                                </div>                               
                            </div>

							<div class="row">
                                <div class="col-md-12 text-center">
                                    <br>
                                    <input type="submit" name="" value="Save Status">
                                </div>
                            </div>

							</form>
							</div></div>
						</div>


					
			<div class="container" *ngIf="myMessageList.data?.length > 0 ">
				<div class="row">
					<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 ">
						<div class="details con-person">
							<div class="row">
								
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 contact-data"  *ngFor="let myMessageList of myMessageList.data">
									<div><h2 style="padding-left:5px">My Status</h2></div>
									<div class=" text-left">
										<div style="float:left">
											
											
											<img [src]="'https://www.gujarattilesinfo.com/backend/' + myMessageList.statuspic" alt="" (error)="handleMissingImage($event)">
										</div>
										<div style="float: left;padding-left:10px;">
											
											<p style="text-transform: capitalize;">{{myMessageList.statusmessage}}</p>	
										</div>

									
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>	
					


			<!---
			<div class="container">
			<owl-carousel-o [options]="customOptions" >
				<ng-container *ngFor="let messageList of dataList.data">
				<ng-template carouselSlide  >

					<img [src]="messageList.statuspic" alt=""  (error)="handleMissingImage($event)">

					<div style=" font-weight: bold;">{{messageList.name}}</div>
					<p style="text-transform: capitalize;">{{messageList.statusmessage}}</p>	
						
				
				</ng-template>  </ng-container>
			  </owl-carousel-o>
			  </div>
			-->

			<div class="container">
				<div class="row">
				
					
					</div> </div>
					

			<div class="row box-h mt-30">
				<div class="col-xl-6 col-md-12 col-sm-12 col-xs-12 col-h">
					<div class="details">
						<h2>{{data?.name}}</h2>
						<div class="address">
							<h4><i class="fa fa-map-marker"></i> Address</h4>
							<p>{{data?.address}}</p>
							<div class="row">
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<div class="add-data">
										<p><b>City:</b> {{data?.city}} </p>
										<p><b>State :</b> {{data?.state}} </p>
									</div>
								</div>
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<div class="add-data">
										<p><b>District :</b> {{data?.district}} </p>
										<p><b>Country :</b> {{data?.country}} </p>
									</div>
								</div>
							</div>
						</div>
						<div class="all-btn">
							<a href="https://api.whatsapp.com/send?phone={{data?.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website."
								target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
							<a href="tel:{{data?.contact}}"><button class="call-btn">Call</button> </a>
							<a href="mailto:{{data?.email}}"><button class="chat-btn">mail</button> </a>
							<a href="{{data?.website}}" target="_blank"><button class="call-btn">Website</button> </a>
						</div>
						<div class="social">
							<h4><i class="fa fa-thumbs-up"></i> Social Media</h4>
							<div class="social-list footer-social-list ">
								<a href="{{data?.facebook}}" class="fb" target="_blank"><i class="fa fa-facebook"></i></a>
								<a href="{{data?.twitter}}" class="twi" target="_blank"><i class="fa fa-twitter"></i></a>
								<a href="{{data?.instagram}}" class="ins" target="_blank"><i class="fa fa-instagram"></i></a>
								<a href="{{data?.youtube}}" class="yb" target="_blank"><i class="fa fa-youtube"></i></a>
							</div>
						</div>
						<div class="year1">
							<h4> Established Year : {{data?.year}}</h4>
						</div>
					</div>
				</div>
                
				<div class="col-xl-6 col-md-12 col-sm-12 col-xs-12 col-h top-50">
                    <app-tiles-product-size *ngIf="type == 1" [category]="data?.categories" [description]="data?.product_description"></app-tiles-product-size>
                    <app-sanitary-product *ngIf="type == 2" [mainProducts]="data?.main_product" [subProducts]="data?.sub_product" [description]="data?.product_description"></app-sanitary-product>
                    <app-various-product *ngIf="type == 3" [products]="data?.primary_product" [description]="data?.product_description"></app-various-product>
                    <app-supplier-category *ngIf="type == 7" [products]="data?.products" [description]="data?.product_description"></app-supplier-category>
                    <app-service-provider-service *ngIf="type == 9" [services]="data?.services" [description]="data?.product_description"></app-service-provider-service>
                    <app-cab-service *ngIf="type == 10" [services]="data?.services" [cars]="data?.cars"></app-cab-service>
                    <app-transport-area-service *ngIf="type == 6" [type]="data?.type_of_transport" [area]="data?.service_area"></app-transport-area-service>
                    <app-hotel-room-amenities *ngIf="type == 8" [rooms]="data?.rooms" [description]="data?.product_description" [amenities]="data?.amenities"></app-hotel-room-amenities>
                    <app-trader-dealer-category *ngIf="type == 4 || type == 5" [category]="data?.categories?.category" [sub_category]="data?.categories?.sub_category" [description]="data?.product_description"></app-trader-dealer-category>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
	<!-- design area start -->
	<section class="conta-sec new-page-area">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 ">
					<div class="details con-person">
						<h2 *ngIf="type == 4 || type == 5 || type == 7 || type == 9">Deal of Companies</h2>
						<h2 *ngIf="type != 4 && type != 5 && type != 7 && type != 9">Group of companies</h2>
						<div class="row" *ngIf="data?.goc?.length">
							<div class="col-xl-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let goc of data.goc">
								<div class="group-data  text-center">
									<img [src]="goc.logo" alt="Company Logo">
									<h4>{{goc.name}}</h4>
								</div>
							</div>
						</div>
                        <div class="row" *ngIf="!data?.goc?.length">
							<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
								<div class="group-data  text-center">
									No Companies found
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="conta-sec" *ngIf="data?.users">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 ">
					<div class="details con-person">
						<h2>Contact Person</h2>
						<div class="row">
							<div class="col-xl-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let u of data?.users">
								<div class="contact-data  text-center">
									<img [src]="u.profile" alt="User Profile">
									<h4>{{u.name}}</h4>
									<p>{{u.post}}</p>
									<div class="bottom-con text-center">
										<ul>
											<li><a href="tel:{{u?.contact}}" class="call-btn"> Call</a></li>
											<li><a href="https://api.whatsapp.com/send?phone={{u?.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." class="whatsapp-btn" target="_blank"> Whatsapp</a></li>
											<li><a href="# " class="chat-btn">Chat</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="bottom-sec bg-grey">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="bottom-link text-center" *ngIf="type != 10 && type != 6 && type != 8">
						<ul>
							<li><a class="view-btn">Inquiry <i class="fa fa-search"></i></a></li>
							<li><a routerLink="/product-gallery" [state]="{photos : data?.product_gallery}" class="view-btn">Product Gallery <i class="fa fa-picture-o"></i></a></li>
							<li><a routerLink="/company-photos" [state]="{photos : data?.comapany_photos}" class="view-btn">Company Photo <i class="fa fa-file-image-o "></i></a></li>
							<li><a routerLink="/company/about" [state]="{about : data?.about_content}" class="view-btn">About Company <i class="fa fa-building-o "></i></a></li>
							<li><a href="{{data?.catalog_url}}" target="_blank" class="view-btn">Download categouge <i class="fa fa-file-pdf-o"></i></a>
							</li>
						</ul>
					</div>
					<div class="bottom-link text-center" *ngIf="type == 10">
						<ul>
							<li><a class="view-btn">Inquiry <i class="fa fa-search"></i></a></li>
							<li><a routerLink="/company-photos" [state]="{photos : data?.cab_photos}" class="view-btn">Cab Photo <i class="fa fa-file-image-o "></i></a></li>
							<li><a routerLink="/company/about" [state]="{about : data?.about_content}" class="view-btn">About Company <i class="fa fa-building-o "></i></a></li>
						</ul>
					</div>
					<div class="bottom-link text-center" *ngIf="type == 6 || type == 8">
						<ul>
							<li><a class="view-btn">Inquiry <i class="fa fa-search"></i></a></li>
							<li><a routerLink="/company-photos" [state]="{photos : data?.comapany_photos}" class="view-btn">Company Photo <i class="fa fa-file-image-o "></i></a></li>
							<li><a routerLink="/company/about" [state]="{about : data?.about_content}" class="view-btn">About Company <i class="fa fa-building-o "></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>