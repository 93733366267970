import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { apiConstants } from '../constants/api-constants';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { APIResponse } from '../Modal/apiresponse';
import { catchError } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class InquiryService {
	constructor(private http: HttpClient) { }

	getList(): Observable<APIResponse> {
		return this.http.get(apiConstants.inquiry.list).pipe(catchError(this.handleError));
	}
	save(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.inquiry.save, form).pipe(catchError(this.handleError));
	}
	readInquiry(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.inquiry.readInquiry, form).pipe(catchError(this.handleError));
	}
	general(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.inquiry.general, form).pipe(catchError(this.handleError));
	}

	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		return throwError('Something bad happened; please try again later.');
	}
}
