<section class="design-area">
    <div class="container">
    <div class="row">
    <div class="col-xl-12 text-center">
    <div class="section-title ">
    <h1>Contact Us</h1>
    <img src="https://gujarattilesinfo.com/assets/img/line.svg" width="120"></div>
    </div>
    </div>
    
    <div class="row">
    <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 mb-3">
    <h5>Gujarat Tiles Info (GTI)</h5>
    </div>
    
    <div class="col-xl-6 col-md-4 col-sm-4 col-xs-12"><img src="https://gujarattilesinfo.com/assets/img/about/1.png"></div>
    
    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
    <div class="about-p">
    <p> GTI INDIA SERVICE PROVIDER LLP
        Address : Shop No.13 to 25 Uper Ni Chhat Ni Shop, Maruti Park, Jetpar Road, Pipali Gam Na Surve No.145 Paiki 5,Vanijya Plot No.1, Morbi, Morbi, Gujarat, 363642
        <br/><br/>
        
        Email id : info@gujarattilesinfo.com
        <br/>
        Mobile : +919099089071<br/>
        </p><p>
        Gujarat Tiles Info is ceramic industry directory. We provide community for Tiles Manufacturers, Tiles Dealers, Sanitary Manufacturers, Importer, Exporter, Raw Material Suppliers, Transporters, Ceramic industry related manufacturer all information at one place.</p>
    </div>
    </div>
    </div>
    </div>
    </section>