import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-identity',
  templateUrl: './find-identity.component.html',
  styleUrls: ['./find-identity.component.css']
})
export class FindIdentityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
