import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-packing-details',
  templateUrl: './packing-details.component.html',
  styleUrls: ['./packing-details.component.css']
})
export class PackingDetailsComponent implements OnInit {
  type:number = 1;
  constructor() { }

  ngOnInit(): void {
  }
  typeChnage(type):void{
    this.type = parseInt(type)
  }

}
