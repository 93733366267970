import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ChatService {
	public getChatListener = new Subject<boolean>();
	public getConversationListener = new Subject<boolean>();
	public sentMessageListener = new Subject<boolean>();
	public typingStatusListener = new Subject<boolean>();
	public logoutListener = new Subject<boolean>();


	public chatListener = new Subject<boolean>();
	public conversationListener = new Subject<boolean>();
	public messageRecieveListener = new Subject<boolean>();
	public onlineStatusListener = new Subject<boolean>();
	public onChangeTypingStatusListener = new Subject<boolean>();

	constructor() { }

	setGetChatListener() {
		this.getChatListener.next(true);
	}
	setGetConversationListener(data) {
		this.getConversationListener.next(data);
	}
	setSentMessageListenerListener(data) {
		this.sentMessageListener.next(data);
	}
	setTypingStatusListener(data) {
		this.typingStatusListener.next(data);
	}
	setLogoutListener() {
		this.logoutListener.next(true);
	}


	setChatListener(chat) {
		this.chatListener.next(chat);
	}
	setConversationListener(conversation) {
		this.conversationListener.next(conversation);
	}
	setMessageReciveListener(message) {
		this.messageRecieveListener.next(message);
	}
	setOnlineStatusListener(status) {
		this.onlineStatusListener.next(status);
	}
	setOnChangeTypingStatusListenerListener(status) {
		this.onChangeTypingStatusListener.next(status);
	}
}
