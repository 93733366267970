import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { DirectoryService } from '../../../services/directory.service';
import { DirectoryMoreService } from '../../../services/directorymore.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-directory',
	templateUrl: './directory.component.html',
	styleUrls: ['./directory.component.css']
})
export class DirectoryComponent implements OnInit {
	@Input('isCompactView') isCompactView = false;
	@Input('list') list = [];
	@Input('listdirectorymore') listdirectorymore = [];

	constructor(
		private directoryMoreService: DirectoryMoreService,
		private directoryService: DirectoryService,
		private userService : UserService,
		private router : Router
	) { }

	ngOnInit(): void {
		if(!this.isCompactView){
			this.directoryService.getDirectories().subscribe(response => {
				if(response.success){
					//console.log(response.data)
					this.list = response.data;
				}else{
					this.userService.errorMessage(response.message)
				}
			})


			this.directoryMoreService.getDirectories().subscribe(response => {
				if(response.success){
					console.log(response.data)
					this.listdirectorymore = response.data;
				}else{
				//	this.userService.errorMessage(response.message)
				}
			})

		}
	}
	onClickDirectyory(id,type,name):void{
		if(type == 1){
			//this.router.navigate(['company/tiles'],{state: {id: id, name: name}});
			this.router.navigate(['manufacturers/' + id + '/' + name.split(" ").join("-").toLowerCase()],{state: {id: id, name: name}});
		}else if(type == 2){
			this.router.navigate(['sanitaryware']);
		}else if(type == 3){
			this.router.navigate(['various-manufacturer']);
		}else if(type == 6){
			this.router.navigate(['transporter']);
		}else if(type == 7){
			this.router.navigate(['suppliers']);
		}else if(type == 8){
			//this.router.navigate(['company/hotel']);
			this.router.navigate(['hotel']);
		}else if(type == 9){
			this.router.navigate(['service-provider']);
		}else if(type == 10){
			//this.router.navigate(['company/cab']);
			this.router.navigate(['cab-service']);
		}else{
			this.router.navigate(['/']);
		}
	}
}
