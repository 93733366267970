<div class="details content-details">
    <h2>Main Products </h2>
    <div style="overflow-x:auto;">
        <table>
            <tr>
                <td *ngFor="let mp of mainProducts" >{{mp}}</td>
            </tr>
        </table>
    </div>
</div>
<div class="details content-details">
    <h2>Sub Products</h2>
    <div style="overflow-x:auto;">
        <table>
            <tr>
                <td *ngFor="let sp of subProducts" >{{sp}}</td>
            </tr>
        </table>
        <h6>Product Description</h6>
        <p>{{description}}</p>
    </div>
</div>