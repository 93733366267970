import { Component, OnInit } from '@angular/core';
import { InformativePagesService } from '../../../services/informative-pages.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-aboutus',
	templateUrl: './aboutus.component.html',
	styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
	data:any = '';
	constructor(
		private pageService : InformativePagesService,
		private userService : UserService,
	) { }

	ngOnInit(): void {
		this.pageService.about().subscribe(res => {
			if(res.success){
				this.data = res.data; 
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}
}
