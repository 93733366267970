<div class="details content-details">
    <h2>Hotel</h2>
    <div style="overflow-x:auto;">
        <table>
            <tr *ngFor="let r of rooms">
                <td>{{r.room_name}}</td>
                <td>{{r.price}}</td>
            </tr>
        </table>
    </div>
</div>
<div class="details content-details">
    <h2>Amenities</h2>
    <div style="overflow-x:auto;">
        <table>
            <tr *ngFor="let a of amenities">
                <td>{{a}}</td>
            </tr>
        </table>
        <h6>Product Description</h6>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever...</p>
    </div>
</div>