import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-various-product',
	templateUrl: './various-product.component.html',
	styleUrls: ['./various-product.component.css']
})
export class VariousProductComponent implements OnInit {
	@Input('products') products = [];
	@Input('description') description = '';
	constructor() { }

	ngOnInit(): void {
	}

}
