import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FollowService } from 'src/app/services/follow.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-followers',
	templateUrl: './followers.component.html',
	styleUrls: ['./followers.component.css']
})
export class FollowersComponent implements OnInit {
	company_id:number = 0; 
	is_buttons_enable:boolean = false; 
	list:any = [];
	constructor(
		private router: Router,
		private userService: UserService,
		private followService: FollowService,
		private location: Location,
	) { }

	ngOnInit(): void {
		if(history.state.follow_cp_id){
			this.is_buttons_enable = history.state.button ? history.state.button : false;
			this.company_id = history.state.follow_cp_id;
			this.followService.followers({company_id: this.company_id}).subscribe(response => {
				if(response.success){
					this.list = response.data;
				}else{
					this.userService.errorMessage(response.message);
				}
			})
		}else{
			this.location.back();
			this.router.navigate(['/']);
		}
	}
	followUnfollow(id, status):void{
		this.followService.followUnfolllowCompany({company_id : id, status: status}).subscribe(response => {
			if(response.success){
				this.userService.sucessMessage(response.message)
				this.ngOnInit();
			}else{
				this.userService.errorMessage(response.message)
			}
		})
	}
}
