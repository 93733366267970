<main>
    <app-breadcrumb [pageName]="'Tiles Calculator'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Tiles Calculator</h1>
                        <img src="assets/img/line.svg" width="120">
                    </div>
                </div>
            </div>
            <div class="row box-h mt-30 justify-content-center">
                <div class="col-xl-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="form-box">
                        <form [formGroup]="calculationForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                 <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                                    <input type="number" id="width" name="width" placeholder="Enter Width in Foot *" formControlName="width" />
                                    <label [ngClass]="{'is-invalid':calculationForm.get('width').touched && calculationForm.get('width').invalid}" class="invalid-feedback font-medium">Please enter width</label>
                                </div>
                                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                                         <input type="number" id="length" name="length" placeholder="Enter Length in Foot *" formControlName="length" />
                                        <label [ngClass]="{'is-invalid':calculationForm.get('length').touched && calculationForm.get('length').invalid}" class="invalid-feedback font-medium">Please enter length</label>
                                </div>
                            </div>   
                            <div class="row">
                                <div class="col-xl-4 col-md-4 col-sm-4 col-xs-4">
                                    <select id="category" name="category" placeholder="Select Category" title="Select Category" formControlName="category" (change)="getSubCategory()">
                                        <option value="" selected disabled>Select Category</option>
                                        <option *ngFor="let category of categories" [value]="category.id">  
                                            {{category.category}}
                                        </option>
                                    </select>
                                    <label [ngClass]="{'is-invalid':calculationForm.get('category').touched && calculationForm.get('category').invalid}" class="invalid-feedback font-medium">Please select category</label>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-4 col-xs-4">
                                    <select id="sub_category" name="sub_category" placeholder="Select Sub Category" title="Select Sub Category" formControlName="sub_category" (change)="getSize()">
                                        <option value="" selected disabled>Select Sub Category</option>
                                        <option *ngFor="let category of subCategories" [value]="category.id">  
                                            {{category.sub_category}}
                                        </option>
                                    </select>
                                    <label [ngClass]="{'is-invalid':calculationForm.get('sub_category').touched && calculationForm.get('sub_category').invalid}" class="invalid-feedback font-medium">Please select sub category</label>
                                </div>
                          
                                <div class="col-xl-4 col-md-4 col-sm-4 col-xs-4">
                                      <select id="size" name="size" placeholder="Select Size" title="Select Size" formControlName="size">
                                        <option value="" selected disabled>Select Size</option>
                                        <option *ngFor="let size of sizes" [value]="size.id">  
                                            {{size.size}}
                                        </option>
                                    </select>
                                    <label [ngClass]="{'is-invalid':calculationForm.get('size').touched && calculationForm.get('size').invalid}" class="invalid-feedback font-medium">Please select size</label>
                                </div>
                            </div>
                            <div style="text-align: center;">
                                <input type="submit" name="submit" value="Submit" id="submit" />
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <!--<div class="row box-h mt-30 justify-content-center">
                <div class="col-xl-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="form-box">
                        <div class="row">
                            <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label for="totalSquareMeter" class="mb-0">Total Sq. Meter</label>
                                <input type="text" id="totalSquareMeter" name="totalSquareMeter" placeholder="totalSquareMeter" value="{{result.totalSquareMeter}}"  class="mt-0" readonly />
                            </div>
                            <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label for="totalSquareFeet" class="mb-0">Total Sq. Feet</label>
                                <input type="text" id="totalSquareFeet" name="totalSquareFeet" placeholder="totalSquareFeet" value="{{result.totalSquareFeet}}" class="mt-0" readonly />
                            </div>
                        </div>  
                         <div class="row">
                            <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label for="requiredTiles" class="mb-0">Required Tiles</label>
                                <input type="text" id="requiredTiles" name="requiredTiles" placeholder="requiredTiles" value="{{result.requiredTiles}}"  class="mt-0" readonly />
                            </div>
                            <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label for="requiredBoxes" class="mb-0">Required Boxes</label>
                                <input type="text" id="requiredBoxes" name="requiredBoxes" placeholder="requiredBoxes" value="{{result.requiredBoxes}}"  class="mt-0" readonly />
                            </div>
                        </div>   
                    </div>
                </div>
            </div>-->
             <div class="row box-h mt-30 justify-content-center">
                <div class="col-xl-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="form-box">
                        <div class="row">
                            <div class="col-md-2">
                                <img src="assets/img/tiles.png" alt="Tiles" />
                            </div>
                            <div class="col-md-4">
                                <span style="display: block;margin-bottom: 5px;">
                                    <b>Total Sq. Meter :</b> {{result.totalSquareMeter}}
                                </span>
                                <span><b>Required Tiles :</b> {{result.requiredTiles}}</span>
                            </div>
                            <div class="col-md-2">
                                <img src="assets/img/box.png" alt="Boxes" />
                            </div>
                            <div class="col-md-4">
                                <span style="display: block;margin-bottom: 5px;">
                                    <b>Total Sq. Feet :</b> {{result.totalSquareFeet}}
                                </span>
                                <span><b>Required Boxes :</b> {{result.requiredBoxes}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </section>
    <!-- design area end -->

</main>