<div class="details content-details">
    <h2>Service</h2>
    <div style="overflow-x:auto;">
        <table>
            <tr *ngFor="let s of services">
                <td>{{s}}</td>
            </tr>
        </table>
    </div>
</div>
<div class="details content-details">
    <h2>Available Cars</h2>
    <div style="overflow-x:auto;">
        <table>
            <tr *ngFor="let c of cars">
                <td>{{c}}</td>
            </tr>
        </table>
    </div>
</div>