<main>
    <app-breadcrumb [pageName]="'Followings'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8  col-md-12">
                    <div class="single-buy" *ngFor="let company of list">
                        <div class="container-fluid">
                            <div class="row align-center">
                                <div class="col-xl-2 col-md-2" routerLink="/company/details" [state]="{id: company.id, type: company.type}">
                                    <div style="text-align: center;">
                                        <img [src]="company?.logo">
                                    </div>
                                </div>
                                <div class="col-xl-7 col-md-7" routerLink="/company/details" [state]="{id: company.id, type: company.type}">
                                    <div class="tiles-info">
                                        <h4>{{company?.name}}</h4>
                                        <h5>{{company?.city}}</h5>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-3 text-right" *ngIf="!company?.is_me">
                                    <div class="buy-btn" *ngIf="is_buttons_enable">
                                        <button class="view-btn" *ngIf="!company?.is_following" (click)="followUnfollow(company?.id ,1)">Follow &nbsp;<i class="fa fa-user"></i></button>
								        <button class="view-btn" *ngIf="company?.is_following" (click)="followUnfollow(company?.id ,0)">Unfollow &nbsp;<i class="fa fa-user"></i></button>
                                    </div>
                                    <div class="buy-btn" *ngIf="!is_buttons_enable">
                                        <button class="view-btn" [attr.disabled]="true" *ngIf="!company?.is_following">Follow &nbsp;<i class="fa fa-user"></i></button>
								        <button class="view-btn" [attr.disabled]="true" *ngIf="company?.is_following">Unfollow &nbsp;<i class="fa fa-user"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>