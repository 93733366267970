<div class="list_items_container">
	<p class="close_btn" (click)="hideSidebar()">X</p>
	<div class="list_box">
		<a routerLink="inbox" (click)="hideSidebar()">My Inbox <span class="sidebar-count" *ngIf="inboxCount">{{inboxCount}}</span> </a>
		<a routerLink="notifications" (click)="hideSidebar()">My Notification <span class="sidebar-count" *ngIf="notificationCount">{{notificationCount}}</span></a>
		<a routerLink="chat" (click)="hideSidebar()">My Chat <span class="sidebar-count" *ngIf="chatCount">{{chatCount}}</span></a>
		<a routerLink="my-sale-post" (click)="hideSidebar()">My Sale Post</a>
		<a routerLink="my-buy-post" (click)="hideSidebar()">My Buy Post</a>
		<a routerLink="digital-card" (click)="hideSidebar()">My Digital Visiting Card</a>
		<a routerLink="analytics" (click)="hideSidebar()">Analytics</a>
		<a routerLink="home" (click)="hideSidebar()">Multiple Whatsapp</a>
		<a routerLink="jobs/list" (click)="hideSidebar()">Job Junction</a>
		<a routerLink="identity-find" (click)="hideSidebar()">Identity Find </a>
		<a routerLink="reference-find" (click)="hideSidebar()">Refrence Find</a>
		<a routerLink="defaulter" (click)="hideSidebar()">Defaulter</a>

		<form [formGroup]="phoneForm">
			<h4>Whatsapp</h4>
			<div class="search-box4 whatsapp-search">
				<ngx-intl-tel-input [cssClass]="'search-input'" [preferredCountries]="preferredCountries"
					[enableAutoCountrySelect]="true" 
					[enablePlaceholder]="true"
					[searchCountryFlag]="true"
					[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
					[selectFirstCountry]="false"
					[selectedCountryISO]="CountryISO.India"
					[maxLength]="15"
					[phoneValidation]="true"
					[separateDialCode]="separateDialCode"
					[numberFormat]="PhoneNumberFormat.National" 
					name="phone" 
					customPlaceholder="90990 89071"
					formControlName="phone">
				</ngx-intl-tel-input>
				<!-- <input type="text" class="search-input" placeholder="Enter Contact No."> -->
				<button class="ser-btn" (click)="sendMessage()"><i class="fa fa-paper-plane"></i></button>
			</div>
		</form>
	</div>
</div> 	