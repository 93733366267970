<div class="details content-details">
    <h2>Primary Products </h2>
    <div style="overflow-x:auto;">
        <table>
            <tr  *ngFor="let p of products">
                <td>{{p}}</td>
            </tr>
        </table>
        <h6>Product Description</h6>
        <p>{{description}}</p>
    </div>
</div>