import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { UserService } from '../../services/user.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {
 
  	blog = {
   		image:"",
   		title:"",
      content:"",
   		meta_keyword:""
  	};	
  	notfound = false;
  	constructor(	
      private blogService: BlogService,
  		private metaService: Meta,
    private router : Router,
	private activatedRoute: ActivatedRoute,
	private titleService:Title,
		  private userService: UserService) { 
  	}

  	ngOnInit(): void {	
		  
		let params: any = this.activatedRoute.snapshot.params;
		//console.log(params.id);
  		//if(history.state.id) { 
			if(params.id) { 
	  		//this.blogService.getById({id:history.state.id}).subscribe(res => {
				this.blogService.getById({id:params.id}).subscribe(res => {
				if (res.success) {
					
					this.blog.image = res.data.image;
					this.blog.title = res.data.title;
          this.blog.content = res.data.content;
					this.blog.meta_keyword = res.data.meta_keyword;
					this.notfound = false;
				//	this.titleService.setTitle(res.data.title);
          //console.log(this.blog.meta_keyword);
		  this.setTitle(res.data.title);
           this.metaService.addTags([{name: 'keywords', content: this.blog.meta_keyword}]);
           setTimeout(() => {  
  this.metaService.updateTag(  
    { name: 'keywords', content: this.blog.meta_keyword},  
    'name=keywords'  
  ); 
  
  
  this.setTitle(res.data.title);
}, 1000);  
				} else {
					this.notfound = true;
					this.userService.errorMessage(res.message);
				}
			});
  		}
  		else{
      this.router.navigate(['/blogs']);
  			this.notfound = true;
  		}
  	}

	  public setTitle(newTitle: string) {
		this.titleService.setTitle(newTitle);
	  }
	  
}
