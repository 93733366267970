import { DigitalCardComponent } from './modules/digital-card/digital-card.component';
import { HelpSupportComponent } from './modules/help-support/help-support.component';
import { DefaulterFormComponent } from './modules/defaulter-form/defaulter-form.component';
import { FollowingsComponent } from './modules/company/followings/followings.component';
import { FollowersComponent } from './modules/company/followers/followers.component';
import { SearchComponent } from './modules/search/search.component';
import { TermConditionComponent } from './modules/term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { ProfileEditComponent } from './modules/profile-edit/profile-edit.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { TransporterComponent } from './modules/company/transporter/transporter.component';
import { ServiceProviderComponent } from './modules/company/service-provider/service-provider.component';
import { SupplierComponent } from './modules/company/supplier/supplier.component';
import { CompanyAboutComponent } from './modules/company/company-about/company-about.component';
import { CompanyDetailsComponent } from './modules/company/company-details/company-details.component';
import { FindReferenceComponent } from './modules/find-reference/find-reference.component';
import { FindIdentityComponent } from './modules/find-identity/find-identity.component';
import { DefaulterDetailsComponent } from './modules/defaulter-details/defaulter-details.component';
import { DefaulterComponent } from './modules/defaulter/defaulter.component';
import { TilesCalculatorComponent } from './modules/tiles-calculator/tiles-calculator.component';
import { PackingDetailsComponent } from './modules/packing-details/packing-details.component';
import { SpecificationComponent } from './modules/specification/specification.component';
import { MyBuyPostComponent } from './modules/my-buy-post/my-buy-post.component';
import { MySalePostComponent } from './modules/my-sale-post/my-sale-post.component';
import { ChatComponent } from './modules/chat/chat.component';
import { InboxComponent } from './modules/inbox/inbox.component';
import { BuyerPostFormComponent } from './modules/buyer-post-form/buyer-post-form.component';
import { SellerPostFormComponent } from './modules/seller-post-form/seller-post-form.component';
import { CompanyPhotosComponent } from './modules/company/company-photos/company-photos.component';
import { ProductGalleryComponent } from './modules/company/product-gallery/product-gallery.component';
import { TilesManufactureListComponent } from './modules/company/tiles-manufacture-list/tiles-manufacture-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuyerPostDetailComponent } from './modules/buyer/detail/buyer-post-detail.component';
import { BuyerPostsListComponent } from './modules/buyer/list/buyer-posts-list.component';
import { NotificationComponent } from './modules/common/notification/notification.component';
import { AdminComponent } from './modules/company/admin/admin.component';
import { CabComponent } from './modules/company/cab/cab.component';
import { CompanyDetailComponent } from './modules/company/company-detail/company-detail.component';
import { DealerComponent } from './modules/company/dealer/dealer.component';
import { HotelComponent } from './modules/company/hotel/hotel.component';
import { RawMaterialComponent } from './modules/company/raw-material/raw-material.component';
import { SanitaryComponent } from './modules/company/sanitary/sanitary.component';
import { TradersComponent } from './modules/company/traders/traders.component';
import { VariousComponent } from './modules/company/various/various.component';
import { AboutusComponent } from './modules/components/aboutus/aboutus.component';
import { AnalyticsComponent } from './modules/components/analytics/analytics.component';
import { DirectoryComponent } from './modules/components/directory/directory.component';
import { HomeComponent } from './modules/components/home/home.component';
import { JobsListComponent } from './modules/components/jobs/list/jobs-list.component';
import { SignupFormComponent } from './modules/components/signup-form/signup-form.component';
import { SellerPostDetailComponent } from './modules/seller/detail/seller-post-detail.component';
import { SellerPostsListComponent } from './modules/seller/list/seller-posts-list.component';
import { BlogDetailComponent } from './modules/blog-detail/blog-detail.component';

import { AuthGuard } from './guard/auth.guard';
import { AccessRoleGuard } from './guard/access-role.guard';
import { BlogComponent } from './modules/blog/blog.component';
import { ForgotPasswordComponent } from './modules/components/forgot-password/forgot-password.component';
import { VerifyOtpComponent } from './modules/components/verify-otp/verify-otp.component';
import { ResetPasswordComponent } from './modules/components/reset-password/reset-password.component';


import { ContactComponent } from './modules/contact/contact.component';
import { MembershipComponent } from './modules/membership/membership.component';


import { FeedbackComponent } from './modules/feedback/feedback.component';


import { VariousMoreComponent } from './modules/company/variousmore/variousmore.component';


const routes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'verify-otp', component: VerifyOtpComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'blog-detail', component: BlogDetailComponent },
	{ path: 'blog-detail/:id/:name', component: BlogDetailComponent }, 
	{ path: 'profile', component: ProfileComponent, canActivate:[AuthGuard] },
	{ path: 'profile/edit', component: ProfileEditComponent, canActivate:[AuthGuard] },
	{ path: 'home', component: HomeComponent },
	{ path: 'about-us', component: AboutusComponent },
	{ path: 'contact-us', component: ContactComponent },
	{ path: 'membership', component: MembershipComponent },
	{ path: 'directory', component: DirectoryComponent },
	/*{ path: 'jobs/list', component: JobsListComponent, canActivate:[AccessRoleGuard] },*/
	{ path: 'jobs/list', component: JobsListComponent },
	{ path: 'seller-posts', component: SellerPostsListComponent, canActivate:[AccessRoleGuard] },
	{ path: 'seller-posts-detail', component: SellerPostDetailComponent, canActivate:[AccessRoleGuard] },
	{ path: 'buyer-posts', component: BuyerPostsListComponent, canActivate:[AccessRoleGuard] },
	{ path: 'buyer-posts-detail', component: BuyerPostDetailComponent, canActivate:[AccessRoleGuard] },
	{ path: 'register', component: SignupFormComponent },
	{ path: 'login', component: SignupFormComponent },
	{ path: 'notifications', component: NotificationComponent, canActivate:[AuthGuard] },
	{ path: 'analytics', component: AnalyticsComponent, canActivate:[AuthGuard] },
	{ path: 'privacy-policy', component: PrivacyPolicyComponent },
	{ path: 'term-condition', component: TermConditionComponent },
	{ path: 'help-support', component: HelpSupportComponent , canActivate:[AuthGuard]},
/*added */
	{ path: 'feedback', component: FeedbackComponent },
	
	{ path: 'digital-card', component: DigitalCardComponent , canActivate:[AuthGuard]},

	{ path: 'company', component: CompanyDetailComponent, },
	{ path: 'followers', component: FollowersComponent },
	{ path: 'followings', component: FollowingsComponent },
	{ path: 'product-gallery', component: ProductGalleryComponent },
	{ path: 'company-photos', component: CompanyPhotosComponent },
	{ path: 'company/about', component: CompanyAboutComponent },
	{ path: 'company/details/:name', component: CompanyDetailsComponent },
	{ path: 'company/details', component: CompanyDetailsComponent },
	{ path: 'company/tiles', component: TilesManufactureListComponent },
	/*{ path: 'tiles/:tilescategoryid/:tilescategoryname', component: TilesManufactureListComponent },*/
	{ path: 'manufacturers/:tilescategoryid/:tilescategoryname', component: TilesManufactureListComponent },
	/*{ path: 'tiles/:tilescategoryid/:tilescategoryname/:id/:type/:name', component: CompanyDetailsComponent },*/
	{ path: 'manufacturers/:tilescategoryid/:tilescategoryname/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'sanitaryware', component: SanitaryComponent },
	{ path: 'sanitaryware/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'various-manufacturer', component: VariousComponent },
	{ path: 'various-manufacturer/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'other-manufacturer/:variousmanufacturecategory/:variousid', component: VariousMoreComponent },
	{ path: 'other-manufacturer/:variousmanufacturecategory/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'suppliers', component: SupplierComponent},
	{ path: 'suppliers/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'service-provider', component: ServiceProviderComponent},
	{ path: 'service-provider/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'cab-service', component: CabComponent},
	{ path: 'cab-service/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'transporter', component: TransporterComponent},
	{ path: 'transporter/:id/:type/:name', component: CompanyDetailsComponent },
	/*{ path: 'company/hotel', component: HotelComponent},*/
	{ path: 'hotel', component: HotelComponent},
	{ path: 'hotel/:id/:type/:name', component: CompanyDetailsComponent },
	/*{ path: 'company/dealer', component: DealerComponent },
	{ path: 'company/traders', component: TradersComponent },*/
	
	{ path: 'company/:id/:type/:name', component: CompanyDetailsComponent },
	{path: 'company/traders', component: TradersComponent },
	{ path: 'company/dealer', component: DealerComponent },
	{ path: 'dealer', component: DealerComponent },
	{ path: 'dealer/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'traders', component: TradersComponent },
	{ path: 'traders/:id/:type/:name', component: CompanyDetailsComponent },
	{ path: 'seller-post', component: SellerPostFormComponent, canActivate:[AuthGuard, AccessRoleGuard] },
	{ path: 'seller-post/edit', component: SellerPostFormComponent, canActivate:[AuthGuard, AccessRoleGuard] },
	{ path: 'buyer-post', component: BuyerPostFormComponent, canActivate:[AuthGuard, AccessRoleGuard] },
	{ path: 'buyer-post/edit', component: BuyerPostFormComponent, canActivate:[AuthGuard, AccessRoleGuard] },
	{ path: 'inbox', component: InboxComponent, canActivate:[AuthGuard] },
	{ path: 'chat', component: ChatComponent, canActivate:[AuthGuard] },
	{ path: 'my-sale-post', component: MySalePostComponent, canActivate:[AuthGuard] },
	{ path: 'my-buy-post', component: MyBuyPostComponent, canActivate:[AuthGuard] },
	{ path: 'specification', component: SpecificationComponent, canActivate:[AccessRoleGuard] },
	{ path: 'packing-details', component: PackingDetailsComponent, canActivate:[AccessRoleGuard] },
	{ path: 'tiles-calculator', component: TilesCalculatorComponent, canActivate:[AccessRoleGuard] },
	{ path: 'defaulter/add', component: DefaulterFormComponent, canActivate:[AccessRoleGuard] },
	{ path: 'defaulter', component: DefaulterComponent, canActivate:[AccessRoleGuard] },
	{ path: 'defaulter-details', component: DefaulterDetailsComponent, canActivate:[AccessRoleGuard] },
	{ path: 'identity-find', component: FindIdentityComponent, canActivate:[AccessRoleGuard] },
	{ path: 'reference-find', component: FindReferenceComponent, canActivate:[AccessRoleGuard] },
	{ path: 'search', component: SearchComponent},
	{ path: 'blogs', component: BlogComponent},
	{ path: '**', component: HomeComponent },
	/*{ path: 'blog-detail/:id/:name/', component: BlogDetailComponent }, */
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
