<section class="design-area">
    <div class="container">
    <div class="row">
    <div class="col-xl-12 text-center">
    <div class="section-title ">
    <h1>MEMBERSHIP</h1>
    <img src="https://gujarattilesinfo.com/assets/img/line.svg" width="120"></div>
    </div>
    </div>
    
    <div class="row">

    <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
    <div class="about-p">
   
        <h1 class="smp-table-heading">Subscribe Membership Plan</h1>
		<!-- <img src="https://gujarattilesinfo.com/assets/img/line.svg" width="120"class="gtitable-title-images"> -->
		<table>
			<thead>
			<tr>
				<th>User</th>
				<th>Service</th>
				<th>Postion</th>
				<th>Exhibition Banner</th>
				<th>Morbi Visited </th>
				<th>Notification </th>
				<th>Dealers Directory</th>
				<th>Video pramotion</th>
				<th>Charges</th>
				</tr>
			</thead>
			<tbody>
				<tr class="smp-table-sub-heading">
					<td></td>
					<td></td>
					<td>OnDirectory</td>
					<td>(Month)</td>
					<td>Dealers info</td>
					<td>(Category wise)</td>
					<td>Access</td>
					<td>(Edit by GTI)</td>
					<td>(Rs.)</td>
					
				</tr>
				<tr >
					<td rowspan="3">Manufacturers <br>(Tiles)</td>
					<td>Royal</td>
					<td>1</td>
					<td>3</td>
					<td>150</td>
					<td>3</td>
					<td>Yes</td>
					<td>3</td>
					<td>79900</td>
				</tr>
				<tr>
					<td>Exclusive</td>
					<td>2</td>
					<td>2</td>
					<td>100</td>
					<td>2</td>
					<td>Yes</td>
					<td>2</td>
					<td>69900</td>
				</tr>
				<tr>
					<td>Classic	</td>
					<td>3</td>
					<td>1</td>
					<td>50</td>
					<td>1</td>
					<td>Yes</td>
					<td>1</td>
					<td>49900</td>
				</tr>
				<tr >
					<td rowspan="3">Manufacturers <br>(Sanitary & Various)</td>
					<td>Royal</td>
					<td>1</td>
					<td>3</td>
					<td>150</td>
					<td>3</td>
					<td>Yes</td>
					<td>3</td>
					<td>49900</td>
				</tr>
				<tr>
					<td>Exclusive</td>
					<td>2</td>
					<td>2</td>
					<td>100</td>
					<td>2</td>
					<td>Yes</td>
					<td>2</td>
					<td>39900</td>
				</tr>
				<tr>
					<td>Classic	</td>
					<td>3</td>
					<td>1</td>
					<td>50</td>
					<td>1</td>
					<td>Yes</td>
					<td>1</td>
					<td>29900</td>
				</tr>
				<tr >
					<td rowspan="3">Traders</td>
					<td>Royal</td>
					<td>1</td>
					<td>No</td>
					<td>150</td>
					<td>3</td>
					<td>Yes</td>
					<td>3</td>
					<td>39900</td>
				</tr>
				<tr>
					<td>Exclusive</td>
					<td>2</td>
					<td>No</td>
					<td>100</td>
					<td>2</td>
					<td>Yes</td>
					<td>2</td>
					<td>34900</td>
				</tr>
				<tr>
					<td>Classic	</td>
					<td>3</td>
					<td>No</td>
					<td>50</td>
					<td>1</td>
					<td>Yes</td>
					<td>1</td>
					<td>29900</td>
				</tr>
				<tr >
					<td rowspan="3">Suppliers & <br>Service proveders</td>
					<td>Royal</td>
					<td>1</td>
					<td>3</td>
					<td>No</td>
					<td>No</td>
					<td>No</td>
					<td>3</td>
					<td>29900</td>
				</tr>
				<tr>
					<td>Exclusive</td>
					<td>2</td>
					<td>2</td>
					<td>No</td>
					<td>No</td>
					<td>No</td>
					<td>2</td>
					<td>24900</td>
				</tr>
				<tr>
					<td>Classic	</td>
					<td>3</td>
					<td>1</td>
					<td>No</td>
					<td>No</td>
					<td>No</td>
					<td>1</td>
					<td>19900</td>
				</tr>
				<tr >
					<td rowspan="3">Transporter, <br>Hotel & Cab</td>
					<td>Royal</td>
					<td>1</td>
					<td>No</td>
					<td>No</td>
					<td>3</td>
					<td>No</td>
					<td>3</td>
					<td>24900</td>
				</tr>
				<tr>
					<td>Exclusive</td>
					<td>2</td>
					<td>No</td>
					<td>No</td>
					<td>2</td>
					<td>No</td>
					<td>2</td>
					<td>19900</td>
				</tr>
				<tr>
					<td>Classic	</td>
					<td>3</td>
					<td>No</td>
					<td>No</td>
					<td>1</td>
					<td>No</td>
					<td>1</td>
					<td>14900</td>
				</tr>
				<tr >
					<td rowspan="3">Dealers</td>
					<td>Royal</td>
					<td>1</td>
					<td>No</td>
					<td>No</td>
					<td>3</td>
					<td>No</td>
					<td>3</td>
					<td>19900</td>
				</tr>
				<tr>
					<td>Exclusive</td>
					<td>2</td>
					<td>No</td>
					<td>No</td>
					<td>2</td>
					<td>No</td>
					<td>2</td>
					<td>14900</td>
				</tr>
				<tr>
					<td>Classic	</td>
					<td>3</td>
					<td>No</td>
					<td>No</td>
					<td>1</td>
					<td>No</td>
					<td>1</td>
					<td>9900</td>
				</tr>
			</tbody>
		</table>
			
			
			
			
			
			<h1 class="smp-table-heading">GTI Right Caller Service Plan</h1>
			<!-- <img src="https://gujarattilesinfo.com/assets/img/line.svg" width="120"class="gtitable-title-images"> -->
			<table>
				<tbody>
					<tr >
						<td>Service	</td>
						<td>10000 Dealers</td>
						<td>25000 Dealer</td>
						<td>50000 Dealers</td>
						<td>100000 Dealers</td>
					</tr>
					<tr>
						<td>Auto Whatsapp Service</td>
						<td>No</td>
						<td>49900</td>
						<td>89900</td>
						<td>159900</td>
					</tr>
					<tr>
						<td>Auto Calling Service</td>
						<td>44900</td>
						<td>89900</td>
						<td>159900</td>
						<td>259900</td>
					</tr>
				</tbody>
			</table>
			<h1 class="smp-table-heading">Extra Service</h1>
			<!-- <img src="https://gujarattilesinfo.com/assets/img/line.svg" width="120"class="gtitable-title-images"> -->
			
			<table>
				<tbody>
					
					<tr >
						<td>Sr.No.</td>
						<td  colspan="2">Service</td>
						<td>Charges (Rs.)</td>
						<td>Terms</td>
						<!-- <td>100000 Dealers</td> -->
					</tr>
					<tr>
						<td>1</td>
						<td>Free Cab Service  Physical visit buyers</td>
						<td class="blind-tt"></td>
						<td>500</td>
						<td>Per Visit Only</td>
					</tr>
					<tr>
						<td>2</td>
						<td>Home Page Extra Banner (branding)</td>
						<td class="blind-tt"></td>
						<td>5000</td>
						<td>Per month </td>
					</tr>
					<tr>
						<td>3</td>
						<td>Morbi Visit buyers information</td>
						<td class="blind-tt"></td>
						<td>5000</td>
						<td>50 buyer info</td>
					</tr>
					
					
				</tbody>
				
			</table>

    </div>
    </div>
    </div>
    </div>
    </section>
    