<main>
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>Product Gallery</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
				</div>
			</div>
			<div class="row" *ngIf="photos">
				<div class="col-xl-3 col-md-4 col-sm-4 col-xs-12" *ngFor="let p of photos">
					<a>
						<div class="single-dir">
							<img [src]="p.image" alt="Product image">
							<h2>{{p.title}}</h2>
						</div>
					</a>
				</div>
			</div>
		</div>
	</section>
</main>