<main>
    <app-breadcrumb [pageName]="'Saller Post'"></app-breadcrumb>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Saller Post </h1>
                        <img src="assets/img/line.svg" width="120">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-md-8 col-sm-8 col-xs-12">
                    <div class="form-box">
                        <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-6 pr-3">
                                    &nbsp;&nbsp;<input type="radio" name="type" formControlName="type" [value]="1" (change)="typeChnage('1')" > &nbsp;&nbsp;Tiles
                                </div>
                                <div class="col-md-6">
                                    <input type="radio" name="type" [value]="2" formControlName="type" (change)="typeChnage('2')">&nbsp;&nbsp; Sanitary & Various
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <input type="text" formControlName="rate" name="" placeholder="Rate">
                                    <div *ngIf="postForm.get('rate').touched && postForm.get('rate').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('rate').errors && postForm.get('rate').hasError('required')">Please enter rate</label>
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('rate').errors && postForm.get('rate').hasError('pattern')">Rate must be number</label>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <select formControlName="rate_unit">
                                        <option value="" selected hidden>Select unit</option>
                                        <option *ngFor="let u of rateUnit" [value]="u">{{u}}</option>
                                    </select>
                                    <div *ngIf="postForm.get('rate_unit').touched && postForm.get('rate_unit').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('rate_unit').errors && postForm.get('rate_unit').hasError('required')">Please select rate unit</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <select formControlName="tax">
                                        <option value="" selected hidden>Select Tax</option>
                                        <option *ngFor="let t of tax" [value]="t">{{t}}</option>
                                    </select>
                                    <div *ngIf="postForm.get('tax').touched && postForm.get('tax').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('tax').errors && postForm.get('tax').hasError('required')">Please select tax</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <select (change)="onChangeProduct($event.target.value)" formControlName="product_id">
                                        <option value="" selected hidden>Select Product Name</option>
                                        <option *ngFor="let p of products" [value]="p.id">{{p.product}}</option>
                                    </select>
                                    <div *ngIf="postForm.get('product_id').touched && postForm.get('product_id').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('product_id').errors && postForm.get('product_id').hasError('required')">Please select product</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="type == 1">
                                <div class="col-md-12">
                                    <select formControlName="size_id">
                                        <option  value="" selected hidden>Select Product Size</option>
                                        <option *ngFor="let size of productSize" [value]="size.id">{{size.size}}</option>
                                    </select>
                                    <div *ngIf="postForm.get('size_id').touched && postForm.get('size_id').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('size_id').errors && postForm.get('size_id').hasError('required')">Please select product size</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <select formControlName="grade">
                                        <option value="" selected hidden>Select Product Grade</option>
                                        <option *ngFor="let grade of productGrade" [value]="grade">{{grade}}</option>
                                    </select>
                                    <div *ngIf="postForm.get('grade').touched && postForm.get('grade').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('grade').errors && postForm.get('grade').hasError('required')">Please select product grade</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <select formControlName="requirement_type" (change)="onChangeSaleType($event.target.value)">
                                        <option value="" selected hidden>Select Sale Type</option>
                                        <option *ngFor="let sType of productSaleType" [value]="sType">{{sType}}</option>
                                    </select>
                                    <div *ngIf="postForm.get('requirement_type').touched && postForm.get('requirement_type').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('requirement_type').errors && postForm.get('requirement_type').hasError('required')">Please select product sale type</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="postForm.value.requirement_type != 'Regular'">
                                <div class="col-md-4">
                                    <input type="text" name="quantity" formControlName="quantity" placeholder="Quantity">
                                    <div *ngIf="postForm.get('quantity').touched && postForm.get('quantity').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('quantity').errors && postForm.get('quantity').hasError('required')">Please enter quantity</label>
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('quantity').errors && postForm.get('quantity').hasError('pattern')">Quantity must be number</label>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <select formControlName="quantity_unit">
                                        <option value="" selected hidden>Select Unit Type</option>
                                        <option *ngFor="let sType of qtyUnit" [value]="sType">{{sType}}</option>
                                    </select>
                                    <div *ngIf="postForm.get('quantity_unit').touched && postForm.get('quantity_unit').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('quantity_unit').errors && postForm.get('quantity_unit').hasError('required')">Please select quantity unit</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 text-center" *ngFor="let feat of feature">
                                    <input type="checkbox" (change)="onFeatureChange($event, feat.id)" [value]="feat.id" *ngIf="selectedFeatures.includes(feat.id)" checked/>
                                    <input type="checkbox" (change)="onFeatureChange($event, feat.id)" [value]="feat.id" *ngIf="!selectedFeatures.includes(feat.id)"/>&nbsp; {{feat.feature}}
                                </div>
                                <div class="col-md-12" *ngIf="postForm.get('product_feature_id').touched && postForm.get('product_feature_id').invalid">
                                    <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('product_feature_id').errors && postForm.get('product_feature_id').hasError('required')">Please select features</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <input type="text" name="requirement_in_day" formControlName="requirement_in_day" placeholder="Product Offer (In Days)">
                                    <div *ngIf="postForm.get('requirement_in_day').touched && postForm.get('requirement_in_day').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('requirement_in_day').errors && postForm.get('requirement_in_day').hasError('required')">Please enter requirement days</label>
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('requirement_in_day').errors && postForm.get('requirement_in_day').hasError('pattern')">Days must be number</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="selectedImages.length || existingImages.length">
                                <div class="col-md-3 mt-2" *ngFor="let eig of existingImages; let i = index">
                                    <i class="fa fa-times remove-image-seller-buyer" (click)="removeExistingImage(i)"></i>
                                    <img [src]="eig.uri" alt="Post Image" class="img-thumbnail"/>
                                </div>
                                <div class="col-md-3 mt-2" *ngFor="let ig of selectedImages; let i = index">
                                    <i class="fa fa-times remove-image-seller-buyer" (click)="removeSelectedImage(i)"></i>
                                    <img [src]="ig.imgUri" alt="Post Image" class="img-thumbnail"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <input type="file" accept="image/*" #image (change)="onSelectImage(image.files)" />
                                    <div *ngIf="postForm.get('images').touched && postForm.get('images').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('images').errors && postForm.get('images').hasError('required')">Please select image(s)</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <textarea name="product_description" formControlName="product_description" id="" cols="30" rows="3" placeholder="Product Description"></textarea>
                                    <div *ngIf="postForm.get('product_description').touched && postForm.get('product_description').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="postForm.get('product_description').errors && postForm.get('product_description').hasError('required')">Please enter product description</label>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: center;">
                                <input type="submit" value="Submit">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>