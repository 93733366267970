import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-trader-dealer-category',
	templateUrl: './trader-dealer-category.component.html',
	styleUrls: ['./trader-dealer-category.component.css']
})
export class TraderDealerCategoryComponent implements OnInit {
	@Input('category') category = [];
	@Input('sub_category') sub_category = [];
	@Input('description') description = '';
	constructor() { }

	ngOnInit(): void {
	}

}
