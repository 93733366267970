import { Subscription } from 'rxjs';
import { ViewChild,Component, OnInit, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as io from 'socket.io-client';
import { UserService } from '../../services/user.service';
import { ChatService } from '../../services/chat.service';

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
	user;
	socket;
	activeUserId:number = 0;
	chatList:any = [];
	allChatList:any = [];
	conversation:any = [];
	newChatAdded:boolean = false;
	lastMessageId:number = 0;
	search:string = '';
	@ViewChild('scrollMe', { static: false }) messagesContainer: ElementRef<HTMLDivElement>;
	message:string = '';

	constructor(
		private userService : UserService,
		private chatService : ChatService,
	) { }
	ngOnInit(): void {
		this.userService.setSidebarCountListener({type:'chat', count: 0, curruntUrl:"/chat"});
		this.chatService.setGetChatListener();
		this.user = this.userService.getUser();
		this.userService.getAuthStatusListener().subscribe((response) => {				
			this.user = this.userService.getUser();
		});
		this.chatService.chatListener.subscribe((response) => {
			let temp:any = response;
			this.chatList = [];
			if(history.state.cIdChatid && !this.newChatAdded){
				this.activeUserId = history.state.cIdChatid;
				let cidsTemp = [];
				temp.forEach(element => {
					cidsTemp.push(element.userid);
				});
				if(!cidsTemp.includes(history.state.cIdChatid))
				this.chatList.push({
					image : history.state.image,
					is_online : 0,
					is_typing : 0,
					last_id : 0,
					message : '',
					name : history.state.name,
					time:'',
					userid: history.state.cIdChatid
				})
			}
			
			temp.forEach(element => {
				this.chatList.push(element);
			});
			this.allChatList = this.chatList;
			this.search = '';
		});
		this.chatService.conversationListener.subscribe((response) => {
			this.conversation = response;
			this.scrollChat();
		});
		this.chatService.messageRecieveListener.subscribe((response) => {
			let msg:any = response;
			if(this.activeUserId && this.lastMessageId != msg.last_id){
				this.lastMessageId = msg.last_id;
				this.conversation.push(response);
				this.scrollChat();
			}
			this.chatList.forEach((element, index) => {
				if(element.userid == msg.toUserId || element.userid == msg.fromUserId){
					this.chatList[index].message = msg.message;
				}
			});
			this.allChatList.forEach((element, index) => {
				if(element.userid == msg.toUserId || element.userid == msg.fromUserId){
					this.allChatList[index].message = msg.message;
				}
			});
		});
		this.chatService.onlineStatusListener.subscribe((response) => {
			let status:any = response;
			this.chatList.forEach((element, index) => {
				if(element.userid == status.userId){
					this.chatList[index].is_online = status.status;
				}
			});
			this.allChatList.forEach((element, index) => {
				if(element.userid == status.userId){
					this.allChatList[index].is_online = status.status;
				}
			});
		});
		this.chatService.onChangeTypingStatusListener.subscribe((response) => {
			let status:any = response;
			this.chatList.forEach((element, index) => {
				if(element.userid == status.userid){
					this.chatList[index].is_typing = status.status;
				}
			});
			this.allChatList.forEach((element, index) => {
				if(element.userid == status.userid){
					this.allChatList[index].is_typing = status.status;
				}
			});
		});
	}
	activeUser(id):void{
		this.activeUserId = id;
		this.chatList.forEach((element, index) => {
			if(element.userid == id){
				this.chatList[index].unread_count = 0;
			}
		});
		this.chatService.setGetConversationListener({fromId: this.user.company_id, toId: id, userId: this.user.id});
	}
	sendMessage():void{
		if(this.message !=''){
			this.chatService.setSentMessageListenerListener({fromId: this.user.company_id, toId: this.activeUserId, message: this.message});
			this.message = '';
			this.newChatAdded = true;
			this.chatService.setGetChatListener();
		}
	}

	scrollChat():void {
		if (this.messagesContainer) {
			const { nativeElement } = this.messagesContainer;
			setTimeout(()=>{
				nativeElement.scrollTop = nativeElement.scrollHeight;
			},50)
	  	}
	}
	focusInput():void{
		this.chatService.setTypingStatusListener({toId: this.activeUserId, fromId: this.user.company_id, status: 1 });
	}
	focusOutInput():void{
		this.chatService.setTypingStatusListener({toId: this.activeUserId, fromId: this.user.company_id, status: 0 });
	}
	onInput():void{
		if(this.message != ''){
			this.chatService.setTypingStatusListener({toId: this.activeUserId, fromId: this.user.company_id, status: 1 });
		}else{
			this.chatService.setTypingStatusListener({toId: this.activeUserId, fromId: this.user.company_id, status: 0 });
		}
	}
	keytab(event):void{
		this.sendMessage();
	}
	onSearch(event):void{
		this.chatList = [];
		if(this.search != ''){
			this.allChatList.forEach(element => {
				if(element.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1){
					this.chatList.push(element)
				}
			});
		}else{
			this.chatList = this.allChatList;
		}
	}
}
