<main>
	<app-breadcrumb [pageName]="isLogin ? 'Login' : 'Register'"></app-breadcrumb>
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>{{isLogin ? 'Login' : 'Register'}}</h1>
						<img src="assets/img/line.svg" width="120">
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div
					[ngClass]="isLogin ? 'col-xl-6 col-md-6 col-sm-12 col-xs-12' : 'col-xl-8 col-md-8 col-sm-8 col-xs-12'">
					<div class="form-box">
						<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="isLogin && !isMobile">

							<div class="row">
								<div class="col-md-12">
								<a type="button"  title="Login with Mobile" (click)="onChangeToMobile()" style="color:#007bff">Login With Mobile</a>
								</div>
							</div>

							<div class="row">
								<div class="col-md-12">
									<input type="email" name="" formControlName="email" placeholder="Email">
									<label
										[ngClass]="{'is-invalid':loginForm.get('email').touched && loginForm.get('email').invalid}"
										class="invalid-feedback font-medium">Please enter email</label>
								</div>
								<div class="col-md-12">
									<input type="password" name="" formControlName="password" placeholder="Password">
									<label
										[ngClass]="{'is-invalid':loginForm.get('password').touched && loginForm.get('password').invalid}"
										class="invalid-feedback font-medium">Please enter password</label>
								</div>
							</div>
							<div class="text-center">
								<input type="submit" name="" value="Submit">
							</div>
							<div class="text-right">
								<a type="button" routerLink="/forgot-password" title="Forgot Password">Forgot password</a>
							</div>
						</form>


						<form [formGroup]="loginFormMobile" (ngSubmit)="onSubmitMobile()" *ngIf="isLogin && isMobile">

							<div class="row">
								<div class="col-md-12">
								<a type="button"  title="Login with Email" (click)="onChangeToEmail()" style="color:#007bff">Login With Email</a>
								</div>
							</div>

							<div class="row">
								<div class="col-md-12">
									<input type="text" name="" formControlName="mobile" placeholder="Mobile">
									<label
										[ngClass]="{'is-invalid':loginFormMobile.get('mobile').touched && loginFormMobile.get('mobile').invalid}"
										class="invalid-feedback font-medium">Please enter mobile</label>
								</div>
								<div class="col-md-12">
									<input type="password" name="" formControlName="password" placeholder="Password">
									<label
										[ngClass]="{'is-invalid':loginFormMobile.get('password').touched && loginFormMobile.get('password').invalid}"
										class="invalid-feedback font-medium">Please enter password</label>
								</div>
							</div>
							<div class="text-center">
								<input type="submit" name="" value="Submit">
							</div>
							<div class="text-right">
								<a type="button" routerLink="/forgot-password" title="Forgot Password">Forgot password</a>
							</div>
						</form>

						<form [formGroup]="registerForm" (ngSubmit)="onSubmitRegister()" *ngIf="!isLogin">
							<select formControlName="role">
								<option value="">Select Roll</option>
								<option *ngFor="let role of roles" [value]="role?.id">{{role?.role}}</option>
							</select>
							<label
								[ngClass]="{'is-invalid':registerForm.get('role').touched && registerForm.get('role').invalid}"
								class="invalid-feedback font-medium">Please select role</label>

							<div class="row">
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<input type="text" formControlName="firm_name" name="firm_name"
										placeholder="Firm Name">
									<label
										[ngClass]="{'is-invalid':registerForm.get('firm_name').touched && registerForm.get('firm_name').invalid}"
										class="invalid-feedback font-medium">Please enter firm name</label>
								</div>
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<input type="tel" formControlName="whats_app" name="whats_app"
										placeholder="Whatsapp No.">
									<label
										[ngClass]="{'is-invalid':registerForm.get('whats_app').touched && registerForm.get('whats_app').invalid}"
										class="invalid-feedback font-medium">Please enter whatsapp no.</label>
								</div>
							</div>
							<div class="row ">
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<input type="tel" formControlName="mobile" name="" placeholder="Cell No.">
									<label
										[ngClass]="{'is-invalid':registerForm.get('mobile').touched && registerForm.get('mobile').invalid}"
										class="invalid-feedback font-medium">Please enter cell no.</label>
								</div>
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<input type="email" formControlName="email" name="" placeholder="Email">
									<label
										[ngClass]="{'is-invalid':registerForm.get('email').touched && registerForm.get('email').invalid}"
										class="invalid-feedback font-medium">Please enter email</label>
								</div>
							</div>
							<div class="row ">
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<select formControlName="country" (change)="onChangeCountry($event.target.value)">
										<option value="" disabled hidden>Select Country</option>
										<option *ngFor="let country of countries" [value]="country?.id">
											{{country?.country}}</option>
									</select>
									<label
										[ngClass]="{'is-invalid':registerForm.get('country').touched && registerForm.get('country').invalid}"
										class="invalid-feedback font-medium">Please select country</label>
								</div>
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<select formControlName="state" (change)="onChangeState($event.target.value)">
										<option value="" disabled hidden>Select State</option>
										<option *ngFor="let state of states" [value]="state?.id">{{state?.state}}
										</option>
									</select>
									<label
										[ngClass]="{'is-invalid':registerForm.get('state').touched && registerForm.get('state').invalid}"
										class="invalid-feedback font-medium">Please select state</label>
								</div>
							</div>
							<div class="row">
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<select formControlName="district">
										<option value="" disabled hidden>Select District</option>
										<option *ngFor="let district of districts" [value]="district?.id">
											{{district?.district}}</option>
									</select>
									<label
										[ngClass]="{'is-invalid':registerForm.get('district').touched && registerForm.get('district').invalid}"
										class="invalid-feedback font-medium">Please select district</label>
								</div>
								<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
									<input type="text" formControlName="city" name="" placeholder="City Name">
									<label
										[ngClass]="{'is-invalid':registerForm.get('city').touched && registerForm.get('city').invalid}"
										class="invalid-feedback font-medium">Please enter city</label>
								</div>
							</div>
							<div style="text-align: center;">
								<input type="submit" name="" value="Submit">
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="otp-section" *ngIf="verifyOtp">
		<i class="fa fa-times close-otp" (click)="closeOtpWindow()" aria-hidden="true"></i>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="otp-div form-box text-center">
						<h2 class="text-center">OTP</h2>
						<hr>
						<h4 class="text-center">Enter OTP Sended to your register email id</h4>
						<div class="passcode-wrapper mt-4">
							<input id="codeBox1" [(ngModel)]="otpIndex1" type="number" maxlength="1" onkeyup="onKeyUpEvent(1, event)" onfocus="onFocusEvent(1)">
							<input id="codeBox2" [(ngModel)]="otpIndex2" type="number" maxlength="1" onkeyup="onKeyUpEvent(2, event)" onfocus="onFocusEvent(2)">
							<input id="codeBox3" [(ngModel)]="otpIndex3" type="number" maxlength="1" onkeyup="onKeyUpEvent(3, event)" onfocus="onFocusEvent(3)">
							<input id="codeBox4" [(ngModel)]="otpIndex4" type="number" maxlength="1" onkeyup="onKeyUpEvent(4, event)" onfocus="onFocusEvent(4)">
						</div>
						<input type="submit" (click)="onClickverifyOtp()" class="mt-5" name="" value="Verify">
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>